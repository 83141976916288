import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";

type TSelectFieldProps = {
  filterType: string;
  value?: boolean;
  id: string;
  label: string;
  onUpdate: (type: string, value: string, label: string) => void;
  onDelete: (type: string) => void;
};

const CheckboxField = ({
  id,
  filterType,
  value,
  label,
  onUpdate,
  onDelete,
}: TSelectFieldProps) => {
  const {t} =useTranslation("samples")
  const boolValue = Boolean(value) ? true : false;

  const handleChange = () => {
    if (boolValue) {
      onDelete(filterType);
    } else {
      onUpdate(filterType, "true", t("I18N_FILTER_YES"));
    }
  };
  return (
    <Stack direction="row" spacing={1}>
      <FormControl fullWidth size="small">
        <FormControlLabel
          control={
            <Checkbox id={id} checked={boolValue} onChange={handleChange} />
          }
          label={label}
        />
      </FormControl>
    </Stack>
  );
};

export default CheckboxField;
