import { useTranslation } from "react-i18next";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import FormSection from "../../../../components/FormSection";
import { IRoles } from "../../../../types/people";
import { useEffect } from "react";
import FieldsGenerator from "../../../../components/Inputs/FieldsGenerator";
import { useGetPermissionsListQuery } from "../../../../services/permissions";
import {
  useAddRolesMutation,
  useGetRolesByIdQuery,
  useUpdateRolesMutation,
  useBindPermissionToRoleMutation,
} from "../../../../services/roles";
import { IPermissions } from "../../../../types/people";

const formFields = [
  {
    name: "name",
    label: "I18N_ROLES_NAME",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "I18N_ROLES_DESCRIPTION",
    type: "text",
    multiline: true,
    required: true,
  },
  {
    name: "permissions",
    label: "I18N_ROLES_PERMISSIONS",
    type: "autocomplete",
    multiple: true,
    required: true,
  },
];

const RoleCreate = () => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const { data: permissions } = useGetPermissionsListQuery({
    searchBy: "name",
    search: "",
  });
  const { data: roles } = useGetRolesByIdQuery(roleId, {
    skip: !roleId,
    refetchOnMountOrArgChange: true,
  });

  const [postRole] = useAddRolesMutation();
  const [updateRole, { isSuccess: isUpdateSuccess }] = useUpdateRolesMutation();
  const [bindPermissionToRole, { isSuccess: isBindSuccess }] =
    useBindPermissionToRoleMutation();

  const { t } = useTranslation("roles");
  const method = useForm<IRoles>({
    defaultValues: {
      name: "",
      description: "",
      permissions: [],
    },
  });
  const { setValue, getValues } = method;
  const bindToRole = async (
    list: IPermissions[],
    id: string,
    unbind: boolean
  ) => {
    const rolePromises: Promise<unknown>[] = [];
    list.forEach((elem) => {
      const bindData = {
        roleId: id,
        permissionId: elem.id,
        unbind: unbind,
      };
      rolePromises.push(bindPermissionToRole(bindData));
    });
    await Promise.all(rolePromises);
  };

  useEffect(() => {
    if (roles && permissions?.length) {
      setValue("name", roles.name);
      setValue("description", roles.description);
      const filterPermissions = permissions.filter((permission) =>
        roles.permissions?.includes(permission.name)
      );
      setValue("permissions", filterPermissions);
    }
  }, [roles, permissions]);

  useEffect(() => {
    if (isBindSuccess) navigate("/roles");
  }, [isBindSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      const permData = getValues("permissions");
      bindToRole(permData, roles.id, false);
      const filteredRolePerm = roles.permissions.filter((elem) => {
        return !permData.map((elem2) => elem2.name).includes(elem);
      });
      const filteredPermissions = permissions.filter((elem) =>
        filteredRolePerm.includes(elem.name)
      );
      bindToRole(filteredPermissions, roles.id, true);
    }
  }, [isUpdateSuccess]);

  const onSubmit: SubmitHandler<IRoles> = async (data) => {
    const permissionNames = data.permissions.map(
      (permission) => permission.name
    );
    if (roles) {
      updateRole({
        ...data,
        permissions: permissionNames,
        id: roles.id,
      });
    } else {
      const { data: roleData } = await postRole({
        ...data,
        permissions: permissionNames,
      });
      bindToRole(data.permissions, roleData.id, false);
    }
  };
  const headerLabel = roleId
    ? t("I18N_ROLES_EDIT_ROLE")
    : t("I18N_ROLES_ADD_ROLE");
  return (
    <Page>
      <Header label={headerLabel} permissions={"create_sample"} />
      <FormProvider {...method}>
        <FormSection
          isLoading={false}
          onSubmit={onSubmit}
          formId="create-permission-form"
          btnText={roleId ? "I18N_EDIT" : "I18N_ADD_CREATE"}
          isEditPage={Boolean(roleId)}>
          <FieldsGenerator
            fieldList={formFields}
            fullWidth
            locales="roles"
            selectOptions={{
              permissions: permissions || [],
            }}
          />
        </FormSection>
      </FormProvider>
    </Page>
  );
};
export default RoleCreate;
