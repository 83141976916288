import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";

const TableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#EFF6FC",
  padding: `${theme.spacing(2)}`,
  borderBottom: "1px solid #D1E4F6",

  '&.sortable': {
    cursor: "pointer",

    '.MuiSvgIcon-root': {
      opacity: 0,
      marginLeft: theme.spacing(1),
    },

    '&.right .MuiSvgIcon-root': {
      marginLeft: 0,
      marginRight: theme.spacing(1),
    },

    '&.sorted .MuiSvgIcon-root': {
      opacity: 0.6,
    },

    '&.descending .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },

    '&:hover': {
      backgroundColor: "#dbebf6",
    },
  }
}));

export const TableResearchHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: 'rgb(243, 165, 30, 0.08)',
  padding: `${theme.spacing(2)}`,
  borderBottom: "1px solid #D1E4F6",
}));

export default TableHeadCell;
