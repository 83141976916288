import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import RestrictedFeature from "./RestrictedFeature";
import Breadcrumbs from "./Breadcrumbs";

interface IHeaderProps {
  label: string | null;
  disabled?: boolean;
  hideCloseBtn?: boolean;
  createLabel?: string | null;
  permissions?: string | string[];
  renderComponent?: React.ReactNode;
  createIcon?: React.ReactNode;
  onCreate?: () => void;
  onClose?: () => void;
}

const HeaderTypography = styled(Typography)(() => ({
  margin: 0,
  flexGrow: 1,
  flexShrink: 1,
  lineHeight: 2,
  whiteSpace: "nowrap",
  overflow: "ellipsis",
}));

const Header = ({
  label,
  disabled = false,
  hideCloseBtn = false,
  createLabel,
  permissions = [],
  renderComponent,
  children,
  createIcon,
  onCreate,
  onClose,
}: PropsWithChildren<IHeaderProps>) => {
  const { t } = useTranslation("components");
  const navigate = useNavigate();
  const subtitle = label?.includes(":")
    ? label.substring(label.lastIndexOf(":") + 1, label.length)
    : "";
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Stack
      spacing={3}
      direction="row"
      alignItems="center"
      sx={{
        m: -3,
        p: 1.5,
        position: "sticky",
        top: "-24px",
        zIndex: 2,
        backgroundColor: "white",
      }}>
      <Stack direction="row" spacing={0} flexGrow={1} flexShrink={1}>
        <Breadcrumbs />
        <Stack flexDirection="row">
          <HeaderTypography variant="h6" color="text.primary">
            {label?.replace(subtitle, "")}
          </HeaderTypography>
          {subtitle && (
            <HeaderTypography variant="h6" color="primary.main" sx={{ ml: 1 }}>
              {subtitle}
            </HeaderTypography>
          )}
        </Stack>
      </Stack>
      {renderComponent && renderComponent}
      {children}
      {onCreate && (
        <RestrictedFeature permissions={permissions}>
          <Button
            disabled={disabled}
            variant="contained"
            startIcon={createIcon || <AddIcon />}
            onClick={onCreate}>
            {createLabel || t("I18N_HEADER_ADD")}
          </Button>
        </RestrictedFeature>
      )}
      {!onCreate && !hideCloseBtn && (
        <IconButton color="error" onClick={onClose || handleClose}>
          <CloseIcon color="error" />
        </IconButton>
      )}
    </Stack>
  );
};

export default Header;
