import { createApi } from '@reduxjs/toolkit/query/react';
import baseAppQuery from './baseAppQuery';
import { IDictionary, TStaticSourceData } from '../types/dictionaries';

const ENDPOINT = 'dict';

export const dictApi: any = createApi({
  reducerPath: 'dictApi',
  tagTypes: ['dictionaries'],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getDict: builder.query<{ [key: string]: number[] }, { field: string }>({
      query: ({ field }) => `${ENDPOINT}/?field=${field}`,
      providesTags: ['dictionaries'],
    }),
    getDictListForType: builder.query<IDictionary, string>({
      query: (dictType) => `${ENDPOINT}/directory-list/${dictType}`,
      providesTags: ['dictionaries'],
    }),
    getDictList: builder.query<IDictionary, string>({
      query: () => `${ENDPOINT}/directory/list`,
      transformResponse(response: IDictionary[]): any {
        const formattedDict = response?.map((dict) => {
          const clone = (({ name, nameKz, nameEn, ...other }) => ({
            ...other,
            nameRU: name,
            nameKK: nameKz,
            nameEN: nameEn,
          }))(dict);
          return clone;
        });
        return formattedDict;
      },
      providesTags: ['dictionaries'],
    }),
    getStaticSourcesList: builder.query<TStaticSourceData[], unknown>({
      query: () => `${ENDPOINT}/?field=orgName`,
      transformResponse(): Promise<TStaticSourceData[]> | TStaticSourceData[] {
        return [
          {
            code: 0,
            name: 'UNKNOWN_SAMPLE',
            productTypes: [{ code: 0, name: 'ХКПУ' }],
          },
          {
            code: 1,
            name: 'ТОО «АТБАСАР»',
            productTypes: [{ code: 0, name: 'ХКПУ' }],
          },
          {
            code: 2,
            name: 'ТОО «ИНКАЙ»',
            productTypes: [{ code: 1, name: 'ЗОУ' }],
          },
          {
            code: 3,
            name: 'ТОО «Семизбай-U»',
            productTypes: [{ code: 0, name: 'ХКПУ' }],
          },
        ];
      },
    }),
    getSourceList: builder.query<IDictionary, string>({
      query: () => `${ENDPOINT}/source`,
    }),
    getDictById: builder.query<IDictionary, string>({
      query: (id) => `${ENDPOINT}/${id}`,
      providesTags: ['dictionaries'],
    }),
    getDictByName: builder.query<IDictionary, { name: string }>({
      query: ({ name }) => `${ENDPOINT}/directory/${name}`,
      providesTags: ['dictionaries'],
    }),
    getDictTypeById: builder.query<IDictionary, { id: string; name: string }>({
      query: ({ id, name }) => {
        const nameQuery = `?name=${name}`;
        return `${ENDPOINT}/directory/type/${id}${name ? nameQuery : ''}`;
      },
      transformResponse(response: IDictionary[]): any {
        const formattedDict = response?.map((dict) => {
          const clone = (({ name, nameKz, nameEn, ...other }) => ({
            ...other,
            nameRU: name,
            nameKK: nameKz,
            nameEN: nameEn,
            productTypes: other.productTypes.map((pt) => ({
              id: pt.id,
              code: pt.code,
              nameRU: pt.name,
              nameKK: pt.nameKz,
              nameEN: pt.nameEn,
            })),
          }))(dict);
          return clone;
        });
        return formattedDict;
      },
      providesTags: ['dictionaries'],
    }),
    addDict: builder.mutation<IDictionary, IDictionary>({
      query: (data) => ({
        url: ENDPOINT,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['dictionaries'],
    }),
    updateDict: builder.mutation<IDictionary, IDictionary>({
      query: (data) => ({
        url: ENDPOINT,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['dictionaries'],
    }),
    deleteDict: builder.mutation<IDictionary, string>({
      query: (id) => ({
        url: `${ENDPOINT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['dictionaries'],
    }),
  }),
});

export const {
  useGetStaticSourcesListQuery,
  useGetSourceListQuery,
  useGetDictQuery,
  useGetDictListQuery,
  useGetDictListForTypeQuery,
  useGetDictByIdQuery,
  useGetDictByNameQuery,
  useLazyGetDictByNameQuery,
  useGetDictTypeByIdQuery,
  useAddDictMutation,
  useUpdateDictMutation,
  useDeleteDictMutation,
} = dictApi;
export default dictApi;
