import React, { Suspense } from "react";
const ResearchView = React.lazy(() => import("./ResearchView"));

const LazyResearchView = () => {
  return (
    <Suspense fallback={<div />}>
      <ResearchView />
    </Suspense>
  );
};
export default LazyResearchView;
