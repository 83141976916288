import { t } from "i18next";

export const emailValidator = (value: string): undefined | string => {
  if (!value) return "Введите адрес электронной почты";
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(value)) return "Неверный электронный адрес";
  return undefined;
};

export const passwordSymbolsValidator = (value: string): undefined | string => {
  const lowerCaseLetterRegex = /^(?=.*[a-z])/g;
  const capitalLetterRegex = /^(?=.*[A-Z])/g;
  const noLowerCaseLetter = !lowerCaseLetterRegex.test(value);
  const noCapitalLetter = !capitalLetterRegex.test(value);

  const numberRegex = /^(?=.*[0-9])/g;
  const noNumber = !numberRegex.test(value);

  const specialRegex = /^(?=.*[`~!@#$%^&*()\-_=+[{}\]|\\,.<>/?])/g;
  const noSpecial = !specialRegex.test(value);

  if (!noLowerCaseLetter && !noCapitalLetter && !noNumber && !noSpecial)
    return undefined;

  const errorsList: string[] = [];
  if (noLowerCaseLetter) {
    errorsList.push(
      t("I18N_LOGIN_FORM_VALIDATION_PASS_LOW_LTR", { ns: "login" })
    );
  }
  if (noCapitalLetter) {
    errorsList.push(
      t("I18N_LOGIN_FORM_VALIDATION_PASS_CAP_LTR", { ns: "login" })
    );
  }
  if (noNumber) {
    errorsList.push(t("I18N_LOGIN_FORM_VALIDATION_PASS_NUM", { ns: "login" }));
  }
  if (noSpecial) {
    errorsList.push(
      t("I18N_LOGIN_FORM_VALIDATION_PASS_SYMBOL", { ns: "login" })
    );
  }

  const text = t("I18N_LOGIN_FORM_VALIDATION_PASSWORD", {
    ns: "login",
    checker: errorsList.join(", "),
  });
  return text;
};

export const passwordLengthValidator = (value: string): undefined | string => {
  if (value.length < 8) {
    const text = t("I18N_LOGIN_FORM_VALIDATION_PASS_LENGTH", { ns: "login" });
    return text;
  }

  return undefined;
};
