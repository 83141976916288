import { AccordionProps } from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormContext, useWatch } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { IDetailAccordion } from './types';
import DetailField from './DetailField';
import DetailsContent from './DetailsContent';
import Table from '../../components/Table';

const colorDraft = {
  bg_grey_transparent: 'rgba(0, 0, 0, 0.04)',
  border_grey: '#E1E1E1',
};

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  position: 'relative',

  '&::after': {
    border: `1px solid ${theme.palette.primary.main}`,
    zIndex: 1,
    borderRadius: theme.spacing(0.5),
    content: '" "',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0,
    pointerEvents: 'none',
  },

  '&:before': {
    display: 'none',
  },

  '&:hover::after': {
    opacity: 1,
    // transition: 'opacity 0.3s ease-in-out',
  },

  '&:not(:first-of-type)': {
    // marginTop: `-1px`,
  },
}));

export const AccordionHeader = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: colorDraft.bg_grey_transparent,
  border: `1px solid ${colorDraft.border_grey}`,
  padding: `${theme.spacing(2)}`,
  '.MuiAccordionSummary-content': {
    color: theme.palette.text.primary,
    lineHeight: '1.25rem',
    margin: '0',
    fontSize: '0.875rem',
  },
}));

export const AccordionBody = styled(MuiAccordionDetails)(({ theme }) => ({
  border: `1px solid ${colorDraft.border_grey}`,
  borderTop: 'none',
  padding: `${theme.spacing(2)} 0`,
}));

const AccordionTypography = styled(Typography)(({}) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
}));

const DynamicTitle = ({
  title,
  undefinedTitle,
}: {
  title: string;
  undefinedTitle: string;
}) => {
  const { control } = useFormContext();
  const dynamicTitle = useWatch({
    name: title || 'unknown',
    control,
    defaultValue: null,
  });
  return (
    <AccordionTypography sx={[!dynamicTitle && { color: 'grey' }]}>
      {dynamicTitle || undefinedTitle}
    </AccordionTypography>
  );
};

interface IAccordionItemProps {
  data: IDetailAccordion;
  undefinedTitle: string;
  disabled: boolean;
}

const AccordionItem: React.FC<IAccordionItemProps> = ({
  data,
  undefinedTitle,
  disabled,
}) => {
  return (
    <Accordion disabled={disabled}>
      <AccordionHeader>
        <Grid container>
          <Grid item xs={12} md={data.header.render ? 3 : 6}>
            <AccordionTypography>{data.header?.title1}</AccordionTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            {data.header?.dynamicTitleSource ? (
              <DynamicTitle
                title={data.header?.dynamicTitleSource}
                undefinedTitle={undefinedTitle}
              />
            ) : (
              <AccordionTypography>{data.header?.title2}</AccordionTypography>
            )}
          </Grid>

          {data.header.render && (
            <Grid item xs={12} md={3}>
              {data.header.render()}
            </Grid>
          )}
        </Grid>
      </AccordionHeader>
      <AccordionBody>
        <DetailsContent>
          {data.description?.map((row, index) => (
            <DetailField key={`detail-row-${row.title}-${index}`} data={row} />
          ))}
        </DetailsContent>
        {data.table && (
          <Stack marginTop={2} paddingX={2}>
            <Table data={data.table} />
          </Stack>
        )}
      </AccordionBody>
    </Accordion>
  );
};
export default AccordionItem;
