import { useEffect, useState } from "react";
import { TFunction } from "i18next";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme, styled } from "@mui/material";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DetailsBlock from "../../../../components/DetailsView/DetailsBlock";
import DetailsViewWidget from "../../../../components/DetailsViewWidget";
import Timeline from "@mui/lab/Timeline";
import { TDetailsBlock } from "../../../../components/DetailsView";
import StateControllerTimeLine from "./StateControllerTimeLine";
import {
  useGetResearchPlanByIdQuery,
  useGetResearchPlanHistoryByMarkerIdQuery,
} from "../../../../services/researchPlans";
import { useGetResearchListQuery } from "../../../../services/researches";
import useTranslateSampleDict from "../../../../hooks/useTranslateDictionary/useTranslateSampleDict";
import useSourceMap from "../../../../hooks/usePortraitsTableData/useSourceMap";
import useProductOptions from "../../../../hooks/usePortraitFilters/useProductOptions";
import { useGetFullModelByIdQuery } from "../../../../services/models";
import {
  useGetSampleByIdQuery,
  useGetSampleHistoryByIdQuery,
} from "../../../../services/samples";
import {
  prepareModelData,
  prepareSampleData,
  prepareResearchData,
  preparePortraitData,
} from "./preparedData";
import { StatusColors, Icons, Labels } from "../../../../hooks/statuses/enums";
import {
  IResearchItem,
  IResearchPlanHistory,
} from "../../../../types/researches";
import {
  useGetPortraitByIdQuery,
  useGetPortraitsHistoryByIdQuery,
} from "../../../../services/portrait";
import { IStateHistory } from "../../../../types/states";
import { ISample } from "../../../../types/samples";
import { IModel } from "../../../../types/models";
import Status from "../../../../config/Status";
import { IPortrait } from "../../../../types/portraits";

const ContainerJson = styled(Timeline)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#F5F5F5",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 2px 10px 2px rgba(34, 60, 80, 0.08) inset",
}));
type TDetailsType = ISample & IModel & IResearchItem;
type TDetailsData = {
  details: TDetailsType;
  preparedData: (data: TDetailsType, t: TFunction) => TDetailsBlock[];
  locales: string;
};

const StateControllerView = () => {
  const theme = useTheme();
  const { t } = useTranslation(["states", "portraits"]);
  const { active, activeId1, activeId2 } = useParams();
  const [subtitle, setSubtitle] = useState("");
  const [markerId, setMarkerId] = useState("");
  const [researchData, setResearchData] = useState<IResearchItem>();
  const [researchHistory, setResearchHistory] = useState<IStateHistory[]>([]);
  const [preparedHistory, setPreparedHistory] = useState<IStateHistory[]>([]);
  const [detailsData, setDetailsData] = useState<TDetailsData>();

  const { data: model } = useGetFullModelByIdQuery(activeId2, {
    skip: active !== "MODEL",
  });
  const { data: sample } = useGetSampleByIdQuery(activeId2, {
    skip: active !== "SAMPLE",
  });
  const { newSample } = useTranslateSampleDict(sample);
  const { data: sampleHistory } = useGetSampleHistoryByIdQuery(activeId2, {
    skip: active !== "SAMPLE",
  });
  const { data: researchPlan } = useGetResearchPlanByIdQuery(activeId2, {
    skip: active !== "RESEARCH",
  });
  const { data: research } = useGetResearchListQuery(
    {
      modelId: researchPlan?.modelId,
      markerId: activeId1,
      sampleId: researchPlan?.sample.id,
      researchPlanId: researchPlan?.id,
    },
    {
      skip: !researchPlan,
    }
  );

  const { data: researchPlanHistory } =
    useGetResearchPlanHistoryByMarkerIdQuery(markerId, {
      skip: !markerId,
    });

  const { data: portrait } = useGetPortraitByIdQuery(activeId2, {
    skip: active !== "PORTRAIT",
  });
  const { data: portraitHistory } = useGetPortraitsHistoryByIdQuery(activeId2, {
    skip: !portrait,
  });
  const sourceMap = useSourceMap();
  // const modelsMap = useModelsMap([portrait?.model_id] || []);

  const productsMap = useProductOptions();

  const activeData = {
    MODEL: {
      title: `${model?.name} v${model?.version}`,
      data: {
        details: model,
        preparedData: prepareModelData,
        locales: "models",
      },
    },
    RESEARCH: {
      title: researchData?.markerName,
      data: {
        details: researchData,
        preparedData: prepareResearchData,
        locales: "researches",
      },
    },
    SAMPLE: {
      title: newSample?.orgName || newSample?.externalSampleName,
      data: {
        details: newSample,
        preparedData: prepareSampleData,
        locales: "samples",
      },
    },
    PORTRAIT: {
      title: portrait?.sample_code,
      data: {
        details: portrait,
        preparedData: (data: IPortrait, tr: TFunction) =>
          preparePortraitData(data, sourceMap, productsMap, tr),
        locales: "portrait",
      },
    },
  };
  const activeHistory = {
    MODEL: model?.history,
    RESEARCH: researchHistory,
    SAMPLE: sampleHistory,
    PORTRAIT: portraitHistory,
  };

  const getCurResearchByDate = (res: IResearchPlanHistory[]) => {
    let maxIdx = -1;
    let maxDate = "";
    res.forEach((re: IResearchPlanHistory, idx: number) => {
      if (re.when && re.when > maxDate) {
        maxDate = re.when;
        maxIdx = idx;
      }
    });
    return res[maxIdx];
  };

  //set research data in researchData
  useEffect(() => {
    if (researchPlan) {
      const filteredMarker = researchPlan.markerResearch.filter(
        (marker) => marker.markerId === activeId1
      )[0];
      setMarkerId(filteredMarker.id);
      const formattedResearch = {
        ...filteredMarker,
        researchState: filteredMarker.researchState,
        tool: {},
      };
      if (research?.length) {
        const curResearch = [...research].reverse()[0];
        const curState =
          filteredMarker.researchState === Status.ResearchPlan.CANCELED
            ? filteredMarker.researchState
            : curResearch.state;
        formattedResearch.researchState = curState;
        formattedResearch.meth_descr = curResearch?.marker?.meth_descr;
        formattedResearch.tool = curResearch.marker.tools.filter(
          (tool) => tool.id === filteredMarker.toolId
        )[0];
      }
      setResearchData(formattedResearch as IResearchItem);
    }
  }, [research, researchPlan]);

  //set research or research-plan history in researchHistory
  useEffect(() => {
    const checkResearch = research && research?.length;
    const checkResearchPlanHistory =
      researchPlanHistory && researchPlanHistory?.length;

    if (checkResearchPlanHistory) {
      const filteredRPHistory: IResearchPlanHistory =
        getCurResearchByDate(researchPlanHistory);
      const isRPStatusCanceled =
        filteredRPHistory?.state === Status.ResearchPlan.CANCELED;

      if (checkResearch) {
        const { state_history: stateHistory, state } = [
          ...research,
        ].reverse()[0];

        const isRStatusCanceled = state === Status.Research.CANCELED;

        if (stateHistory && !isRStatusCanceled && isRPStatusCanceled) {
          setResearchHistory(stateHistory.concat([filteredRPHistory]));
          return;
        }
        if (stateHistory) setResearchHistory(stateHistory);
      }
      if (!checkResearch && isRPStatusCanceled) {
        setResearchHistory([filteredRPHistory]);
      }
    }
  }, [researchPlanHistory, research]);

  useEffect(() => {
    if (active) {
      setSubtitle(activeData[active]?.title);
      setDetailsData(activeData[active]?.data);
    }
  }, [active, model, researchData, newSample, portrait]);

  useEffect(() => {
    if (active) {
      if (active === "SAMPLE") {
        const preparedData = activeHistory[active]?.map((elem) => {
          const isUtilized =
            elem.transition.nextState === Status.Sample.UTILIZED;
          const descr = isUtilized ? elem?.sample.descriptionForUtilized : null;
          const consumed = t("I18N_STATE_CONTROLLER_SAMPLE_CONSUMPTION", {
            sample: elem.consumed,
          });
          const quantityAfter = t("I18N_STATE_CONTROLLER_SAMPLE_REMINDER", {
            sample: elem.quantityAfter,
          });
          return {
            state: elem.transition.nextState,
            when: elem.when,
            user_id: elem.user_id,
            consumed,
            quantityAfter,
            descr,
          };
        });
        setPreparedHistory(preparedData);
        return;
      }
      if (active === "PORTRAIT") {
        const preparedData = activeHistory[active]?.map((elem) => {
          return {
            state: elem.to_state,
            when: elem.when,
            user_id: elem.requester_id,
            descr: elem.replymsg,
          };
        });
        setPreparedHistory(preparedData);
        return;
      }
      setPreparedHistory(activeHistory[active]);
    }
  }, [active, model, researchHistory, sampleHistory, portraitHistory]);

  return (
    <Page>
      <Header
        label={t(`I18N_STATE_HEADER_ACTIVE_${active}`, { subtitle: subtitle })}
      />
      {detailsData && (
        <>
          <DetailsViewWidget
            data={detailsData?.details}
            preparedData={detailsData?.preparedData}
            locales={detailsData?.locales}
          />
          <DetailsBlock title={t("I18N_STATE_CONTROLLER_HISTORY")}>
            <Stack padding={`0 ${theme.spacing(1)}`}>
              {!preparedHistory?.length ? (
                <Typography
                  fontSize="0.875rem"
                  color={theme.palette.text.secondary}>
                  {t("I18N_STATE_CONTROLLER_HISTORY_NOT_FOUND")}
                </Typography>
              ) : (
                <ContainerJson position="alternate">
                  {preparedHistory?.map((history) => {
                    const iconScope = active ? active?.toLocaleLowerCase() : "";
                    const Icon = Icons[iconScope]?.default[history.state];
                    const color =
                      StatusColors[iconScope]?.default[history.state];
                    const label = Labels[iconScope]?.default[history.state];
                    return (
                      <StateControllerTimeLine
                        color={color}
                        label={label}
                        icon={<Icon />}
                        history={history}
                      />
                    );
                  })}
                </ContainerJson>
              )}
            </Stack>
          </DetailsBlock>
        </>
      )}
    </Page>
  );
};
export default StateControllerView;
