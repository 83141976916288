import React, { Suspense } from "react";

const Models = React.lazy(() => import("./Models"));

const LazyModels = () => (
  <Suspense fallback={<div />}>
    <Models />
  </Suspense>
);

export default LazyModels;
