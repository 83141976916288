import { useEffect, useState } from 'react';
import { ISample } from '../../types/samples';
import { useGetDictListQuery } from '../../services/dictionaries';
import { useGetProductTypeListQuery } from '../../services/productTypes';
import { useTranslation } from 'react-i18next';

const convertToObj = (list, lng) => {
  if (!list) return {};
  const obj = list?.reduce(
    (acc, val) => ({
      ...acc,
      [val.code]: val[`name${lng}`],
    }),
    {}
  );
  return obj;
};

const useTranslateSampleDict = (samples: ISample[] | ISample) => {
  const [newSamples, setNewSamples] = useState<ISample[]>([]);
  const [newSample, setNewSample] = useState<ISample>(samples as ISample);
  const { data: dictList } = useGetDictListQuery();
  const { data: prodTypeList } = useGetProductTypeListQuery();
  const { i18n } = useTranslation();
  const lng = i18n.language.toLocaleUpperCase();
  const dictObj = convertToObj(dictList, lng);
  const pTypeObj = convertToObj(prodTypeList, lng);

  const translateDict = (sample: ISample) => {
    const sampleData = { ...sample };
    sampleData.laboratoryName = dictObj[sampleData.laboratoryNameCode];
    if (sample?.type === 'K') {
      sampleData.orgName = dictObj[sampleData.codeOrgName];
      sampleData.finishedProductType =
        pTypeObj[sampleData.finishedProductTypeCode];
    }
    return sampleData;
  };

  useEffect(() => {
    const checkApiData = dictList?.length && prodTypeList?.length;
    const checkSample = Array.isArray(samples);
    if (checkSample && checkApiData) {
      const translatedSamples = samples.map((sample) => translateDict(sample));
      setNewSamples(translatedSamples);
    }
    if (!checkSample && checkApiData) {
      const translatedSample = translateDict(samples);
      setNewSample(translatedSample);
    }
  }, [samples, dictList, prodTypeList, lng]);

  return { newSamples, newSample } || samples;
};

export default useTranslateSampleDict;
