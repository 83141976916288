import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const ButtonBox = styled(Stack)(({ theme }) => ({
  fontSize: "1rem",
  marginTop: `${theme.spacing(3)}`,
  gap: `${theme.spacing(3)}`,
}));

export default ButtonBox;
