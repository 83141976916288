import { createApi } from "@reduxjs/toolkit/query/react";
import baseAppQuery from "./baseAppQuery";
import { IDownloadFileInfo } from "../types/file";

const ENDPOINT = "files";

export const filesApi: any = createApi({
  reducerPath: "filesApi",
  tagTypes: ["files"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getDownloadFileInfo: builder.query<IDownloadFileInfo, string>({
      query: (fileId: string) => `${ENDPOINT}/${fileId}`,
    }),
  }),
});

export const { useGetDownloadFileInfoQuery } = filesApi;
export default filesApi;
