import FlagSvg from "./FlagSvg";

const FlagRU = () => (
  <FlagSvg
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
  >
    <path
      fill="#fdfefe"
      d="M226.5-.5h46c89.192 11.16 155.692 55.826 199.5 134a176.807 176.807 0 0 1 13.5 33h-472C39.941 94.224 88.941 43.724 160.5 15c21.543-7.721 43.543-12.888 66-15.5Z"
      style={{
        opacity: 0.995,
      }}
    />
    <path
      fill="#0338a5"
      d="M13.5 166.5h472a255.564 255.564 0 0 1 14 60v46c-2.355 20.759-7.021 41.093-14 61h-472c-6.979-19.907-11.645-40.241-14-61v-46a255.597 255.597 0 0 1 14-60Z"
      style={{
        opacity: 0.999,
      }}
    />
    <path
      fill="#d42b1d"
      d="M13.5 333.5h472c-26.875 71.709-75.875 121.876-147 150.5-21.543 7.721-43.543 12.888-66 15.5h-46c-89.192-11.16-155.692-55.826-199.5-134a223.743 223.743 0 0 1-13.5-32Z"
      style={{
        opacity: 0.995,
      }}
    />
  </FlagSvg>
);

export default FlagRU;
