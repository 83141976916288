import React, { Suspense } from "react";

const SampleView = React.lazy(() => import("./SampleView"));

const LazySampleView = () => (
  <Suspense fallback={<div />}>
    <SampleView />
  </Suspense>
);

export default LazySampleView;
