import Skeleton from "@mui/material/Skeleton";

import { useGetPeopleByIdQuery } from "../services/people";
import { ValueRow } from "./DetailsView/DetailField";

export type TModelCreatorProps = {
  creatorId: string;
};

const ModelCreator = ({ creatorId }: TModelCreatorProps) => {
  const { data: person } = useGetPeopleByIdQuery(creatorId);

  if (!person) {
    return <Skeleton variant="rectangular" width={210} height="1rem" />;
  }

  return <ValueRow>{person.name}</ValueRow>;
};

export default ModelCreator;
