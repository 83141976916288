import { useEffect, useState } from "react";
import type { TPortraitFilterOptions } from "../../types/portraits";
import { getOptionsTree } from "../../utils/selectOptions";
import { v4 } from "uuid";

const useOperationOptions = () => {
  const [options, setOptions] = useState<TPortraitFilterOptions>({
    list: [],
    tree: {},
    slug: v4(),
  });

  useEffect(() => {
    const newOptions = [
      {
        value: "GET",
        label: "METHOD GET",
      },
      {
        value: "POST",
        label: "METHOD POST",
      },
      {
        value: "PUT",
        label: "METHOD PUT",
      },
      {
        value: "PATCH",
        label: "METHOD PATCH",
      },
      {
        value: "DELETE",
        label: "METHOD DELETE",
      },
    ];
    setOptions({
      list: newOptions,
      tree: getOptionsTree(newOptions),
      slug: v4(),
    });
  }, []);
  return options;
};
export default useOperationOptions;
