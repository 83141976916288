import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useGetDictListForTypeQuery } from '../../services/dictionaries';
import { TDirectoryData } from '../../types/dict';

const useSourceMap = (): { [key: string]: string } => {
  const { i18n } = useTranslation('portrait');

  const [sourceMap, setSourceMap] = useState<{ [key: string]: string }>({});
  const { data: sources }: { data: TDirectoryData[] } = useGetDictListForTypeQuery('ДЗО');

  useEffect(() => {
    if (sources) {
      const newSourceMap: { [key: string]: string } = {};
      sources.forEach((source) => {
        const code = String(source.code).padStart(2, '0');
        newSourceMap[code] = source.name;
      });
      setSourceMap(newSourceMap);
    }
  }, [sources, i18n.language]);

  return sourceMap;
};

export default useSourceMap;
