import React, { Suspense } from "react";

const ModelsView = React.lazy(() => import("./ModelView"));

const LazyModelsView = () => (
  <Suspense fallback={<div />}>
    <ModelsView />
  </Suspense>
);

export default LazyModelsView;
