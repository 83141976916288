import { TTableData } from '../../../../components/Table';
import { EditButton } from '../../../../components/AdminPanelTable/CustomButtons';
import { ITool } from 'types/markers';

export const preparedData = (data: ITool[]) => {
  const tableData: TTableData = {
    header: {
      data: [
        { value: 'I18N_TABLE_NAME', name: 'name' },
        { value: 'I18N_TABLE_DESCRIPTION', name: 'description' },
        { value: '', name: 'edit', width: 80 },
        { value: '', name: 'delete', width: 80 },
      ],
    },
    rows: [],
  };
  data?.forEach((td) =>
    tableData.rows.push({
      id: td.id,
      isDeletable: td.is_deletable,
      data: [
        { value: td.name },
        { value: td.descr },
        {
          value: td.id,
          render: (value: string) => <EditButton id={value} />,
        },
      ],
    })
  );
  return tableData;
};
