import { MouseEvent, useState } from 'react';
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';
import { alpha, styled } from "@mui/material/styles";
import { TPortraitFilterValue} from "../../../../../types/portraits";
import EditView from './EditView';
import {useTranslation} from "react-i18next";

export const Chip = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  fontSize: '0.8125rem',
  fontWeight: 'bolder',
  marginBottom: theme.spacing(1),
  lineHeight: theme.spacing(2.75),
  borderRadius: theme.spacing(1.5),
  padding: `0 0 0 ${theme.spacing(1)}`,
  position: 'relative',
  cursor: 'pointer',

  label: {
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
  },

  '&:not(:last-child)': {
    marginRight: theme.spacing(1),
  },

  '.MuiButtonBase-root': {
    marginLeft: theme.spacing(0.5),
    padding: 0,
    color: theme.palette.grey[400],

    '&:hover': {
      color: theme.palette.error.main,
    }
  },

  '&:hover': {
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },
}));

type TDateFilter = {
  lable?: string;
  format?: string;
  valueFrom?: TPortraitFilterValue;
  valueTo?: TPortraitFilterValue;
  onChange: (type: string, value: string, label: string) => void;
  onRemove: (type: string) => void;
}

const DateFilter = ({
  lable,
  format,
  valueFrom,
  valueTo,
  onChange,
  onRemove,
}: TDateFilter) => {
  const { t } = useTranslation('portrait');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement|null>(null);

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove('date');
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <Chip onClick={handleClick}>
        <label>{lable ? t(lable) : t('I18N_PORTRAIT_FILTER_DATE_LABEL')}</label>
        {valueFrom && (
          <>
            <label>{t('I18N_PORTRAIT_FILTER_DATE_FROM_SHORT')}</label>
            <span>{valueFrom.label+"\xA0"}</span>
          </>
        )}
        {valueTo && (
          <>
            <label>{t('I18N_PORTRAIT_FILTER_DATE_TO')}</label>
            <span>{valueTo.label}</span>
          </>
        )}
        <IconButton size="small" onClick={handleRemove}>
          <CancelIcon />
        </IconButton>
      </Chip>
      <EditView
        anchorEl={anchorEl}
        valueFrom={valueFrom?.value}
        valueTo={valueTo?.value}
        format={format}
        onChange={onChange}
        onRemove={onRemove}
        onClose={() => setAnchorEl(null)}
      />
    </>
  )
}

export default DateFilter;
