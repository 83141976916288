import { Stack, styled } from '@mui/material';

export const TitleContainer = styled(Stack)(() => ({
  width: 'calc(20% + 8.0px)',
  padding: '16px',
  justifyContent: 'center',
  fontWeight: 500,
}));

export const AccordionTitle = styled(TitleContainer)(() => ({
  color: 'rgba(0, 0, 0, 0.6)',
}));
