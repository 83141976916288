import {DEFAULT, getAccessTokenData} from "./tokenState";
import { IAuthState } from "./types";

const getInitialState = (): IAuthState => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken && accessToken !== "undefined") {
    const accessData = getAccessTokenData(accessToken);
    if (accessData) return accessData;
  }

  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  return DEFAULT;
};

export default getInitialState();
