import { useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import BasicTabs from "../../../../components/Tabs/BasicTabs";
import CustomTabPanel from "../../../../components/Tabs/CustomTabPanel";
import LoggerTable from "./SystemLogTable/LoggerTable";
import AddFilter from "./filters/LoggerFilter/AddFilter";
import useLoggerFilter from "../../../../hooks/useLoggerFilter";
import LoggerFilter from "./filters/LoggerFilter";
import useTableSort from "../../../../hooks/useTableSort";

const activeList = [
  "AUTH",
  "SAMPLES",
  "RESEARCH-PLAN",
  "RESEARCHES",
  "MODELS",
  "PORTRAIT",
  "DICT",
];
const SystemLogs = () => {
  const { t } = useTranslation("loggers");
  const [tabValue, setTabValue] = useState(
    Number(sessionStorage.getItem("loggerTabIndex")) || 0
  );
  const tabLabels = activeList.map((name) => t(`I18N_LOGGER_${name}_TAB`));
  const { sort } = useTableSort("created", ["created"]);
  const {
    filters: filters,
    methods: methods,
    hasFilters: hasFilters,
    query: query,
  } = useLoggerFilter(sort);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    sessionStorage.setItem("loggerTabIndex", newValue.toString());
    setTabValue(newValue);
  };

  return (
    <Page>
      <Header label={t("I18N_LOGGER_HEADER")} hideCloseBtn={true}>
        <AddFilter filters={filters} methods={methods} />
      </Header>
      {filters && hasFilters && methods && (
        <LoggerFilter filters={filters} methods={methods} />
      )}
      <BasicTabs
        labels={tabLabels}
        locales="loggers"
        value={tabValue}
        onChange={handleChange}>
        {activeList.map((active, index) => (
          <CustomTabPanel value={tabValue} index={index} key={index}>
            <LoggerTable
              activeName={active.toLowerCase()}
              query={query}
              hasFilters={hasFilters}
            />
          </CustomTabPanel>
        ))}
      </BasicTabs>
    </Page>
  );
};
export default SystemLogs;
