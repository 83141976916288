import { PropsWithChildren, ReactNode } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "../DetailsView/AccordionItem";
import TitleTooltip from "./Titles/TooltipTitle";
import { AccordionTitle } from "./Titles/style";
import { TAccordionData } from "./types";
import { getShortDate } from "../../utils/dates";
import { styled } from "@mui/material/styles";

export const CustomAccordionHeader = styled(AccordionHeader)(({ theme }) => ({
  padding: "0px",
  paddingRight: "16px",
  margin: 0,
  borderColor: "rgb(180,212,238)",
  backgroundColor: "rgba(239,246,252,0.5)",
  fontSize: "0.875rem",
}));

type RPAccordionProps = {
  accordionData: TAccordionData;
  actionSlot?: ReactNode;
};
const ResearchPlanAccordion = ({
  accordionData,
  actionSlot,
  children,
}: PropsWithChildren<RPAccordionProps>) => {
  const { idTitle, type, modelName, orgName, selectionDate, creationDate } =
    accordionData;
  return (
    <Accordion>
      <CustomAccordionHeader>
        <TitleTooltip id={idTitle} type={type} />
        <AccordionTitle>{modelName}</AccordionTitle>
        <AccordionTitle>{orgName}</AccordionTitle>
        <AccordionTitle>{getShortDate(creationDate)}</AccordionTitle>
        <AccordionTitle>{getShortDate(selectionDate)}</AccordionTitle>

        {actionSlot}
      </CustomAccordionHeader>
      <AccordionBody sx={{ padding: "0" }}>{children}</AccordionBody>
    </Accordion>
  );
};
export default ResearchPlanAccordion;
