import React, { Suspense } from 'react';

const Permissions = React.lazy(() => import('./Permissions'));

const LazyPermissions = () => (
  <Suspense fallback={<div />}>
    <Permissions />
  </Suspense>
);

export default LazyPermissions;
