import { createApi } from "@reduxjs/toolkit/query/react";
import { IExecutor, ITool, IToolBindUser, IToolPayload } from "../types/markers";
import baseAppQuery from "./baseAppQuery";

const ENDPOINT = "model/api/v1/tools";
export const toolsApi: any = createApi({
  reducerPath: "toolsApi",
  tagTypes: ["tools"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getToolsList: builder.query<ITool[], { search: string; searchBy: string }>(
      {
        query: ({ search, searchBy }) => {
          const querySearch = `?search=${searchBy}=${search}`;
          return `${ENDPOINT}${search ? querySearch : ''}`;
        },
      }
    ),
    getToolUsersById: builder.query<IExecutor[], string>({
      query: (id: string) => `${ENDPOINT}/${id}/users`,
    }),
    getToolById: builder.query<ITool, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
    }),
    addTool: builder.mutation<string, IToolPayload>({
      query: (payload: IToolPayload) => ({
        url: `${ENDPOINT}`,
        method: "POST",
        body: payload,
      }),
    }),
    bindUserTool: builder.mutation<string, IToolBindUser>({
      query: ({toolId, userId, unBind}) => ({
        url: `${ENDPOINT}/${toolId}/users/${userId}?unbind=${unBind}`,
        method: "PATCH",
      }),
    }),
    updateTool: builder.mutation<string, ITool>({
      query: (payload) => ({
        url: `${ENDPOINT}/${payload.id}`,
        method: "PUT",
        body: payload
      }),
    }),
    deleteToolbyId: builder.mutation<string, {id: string}>({
      query: (id) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useGetToolsListQuery,
  useGetToolByIdQuery,
  useGetToolUsersByIdQuery,
  useAddToolMutation,
  useBindUserToolMutation,
  useUpdateToolMutation,
  useDeleteToolbyIdMutation,
} = toolsApi;
export default toolsApi;
