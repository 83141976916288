import { useTranslation } from "react-i18next";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import FormSection from "../../../../components/FormSection";
import FieldsGenerator from "../../../../components/Inputs/FieldsGenerator";
import { IPeople, IRoles } from "../../../../types/people";
import { useGetDictTypeByIdQuery } from "../../../../services/dictionaries";
import { useGetRolesListQuery } from "../../../../services/roles";
import {
  useAddPeopleMutation,
  useUpdatePeopleMutation,
  useBindPeopleToRoleMutation,
  useGetPeopleByIdQuery,
  useUnBunPeopleMutation,
  useBunPeopleMutation,
} from "../../../../services/people";
import { useGetDictTypeListQuery } from "../../../../services/dictType";
import { useEffect, useState } from "react";
import { useStartResetPasswordMutation } from "../../../../services/token";

const formFields = [
  {
    name: "first_name",
    label: "I18N_USER_FIRST_NAME",
    type: "text",
    required: true,
  },
  {
    name: "last_name",
    label: "I18N_USER_LAST_NAME",
    type: "text",
    required: true,
  },
  {
    name: "middle_name",
    label: "I18N_USER_MIDDLE_NAME",
    type: "text",
    required: false,
  },
  {
    name: "email",
    label: "I18N_USER_EMAIL",
    type: "email",
    required: true,
  },
  {
    name: "organization_type",
    label: "I18N_USER_ORGANIZATION_TYPES",
    type: "autocomplete",
    disableClearable: true,
    required: true,
  },
  {
    name: "organization",
    label: "I18N_USER_ORGANIZATIONS",
    type: "select",
    required: true,
  },
  {
    name: "roles",
    label: "I18N_USER_ROLES",
    type: "autocomplete",
    multiple: true,
    required: true,
  },
];

const BlockButton = ({ isBanned, id }: { isBanned: boolean; id: string }) => {
  const { t } = useTranslation("users");
  const [unBunUser] = useUnBunPeopleMutation();
  const [bunUser] = useBunPeopleMutation();
  return (
    <Button
      variant="outlined"
      color={isBanned ? "success" : "error"}
      size="medium"
      onClick={() => (isBanned ? unBunUser(id) : bunUser(id))}>
      {isBanned ? t("I18N_USER_UNBUN") : t("I18N_USER_BUN")}
    </Button>
  );
};
const UserCreate = () => {
  const { t, i18n } = useTranslation("users");
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data: roles } = useGetRolesListQuery({ search: "" });
  const { data: user } = useGetPeopleByIdQuery(userId, {
    skip: !userId,
    refetchOnMountOrArgChange: true,
  });
  const { data: orgTypes } = useGetDictTypeListQuery();
  const [orgType, setOrgType] = useState("");
  const [orgs, setOrgs] = useState<{ [key: string]: string }[]>([]);
  const { data: dicts } = useGetDictTypeByIdQuery(
    { id: orgType },
    { skip: !orgType }
  );

  const [postPeople] = useAddPeopleMutation();
  const [bindPeopleToRole, { isSuccess: isBindSuccess }] =
    useBindPeopleToRoleMutation();
  const [updatePeople, { isSuccess: isUpdateSuccess }] =
    useUpdatePeopleMutation();
  const [confirmEmail] = useStartResetPasswordMutation();

  const method = useForm<IPeople>({
    defaultValues: {
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      roles: [],
      organization_type: undefined,
      organization: undefined,
    },
  });

  const { setValue, getValues, watch } = method;

  const bindToRole = async (list: IRoles[], id: string, unbind: boolean) => {
    const rolePromises: Promise<unknown>[] = [];
    list.forEach((data) => {
      const bindData = {
        personId: id,
        roleId: data.id,
        unbind: unbind,
      };
      rolePromises.push(bindPeopleToRole(bindData));
    });
    await Promise.all(rolePromises);
  };

  useEffect(() => {
    if (watch("organization_type")) {
      setOrgType(getValues("organization_type").id);
    }
  }, [watch("organization_type")]);

  useEffect(() => {
    if (dicts && i18n) {
      const lang = i18n.language.toUpperCase();
      const formattedDicts = dicts.map((dict) => ({
        value: dict.code.toString(),
        title: dict[`name${lang}`],
      }));

      setOrgs(formattedDicts);
    }
  }, [dicts, i18n]);

  useEffect(() => {
    if (user && orgTypes && !orgType) {
      setValue("first_name", user.first_name);
      setValue("last_name", user.last_name);
      setValue("middle_name", user.middle_name || "");
      setValue("email", user.email);
      setValue("roles", user.roles);
      setValue(
        "organization_type",
        orgTypes.filter((type) => type.name === user.organization_type)[0]
      );
    }
    if (user && orgs.length) {
      setValue(
        "organization",
        orgs.filter((org) => org.value === user.organization)[0]?.value
      );
    }
  }, [user, orgTypes, orgType, orgs]);

  useEffect(() => {
    if (isBindSuccess) navigate("/users");
  }, [isBindSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      const roleData = getValues("roles");
      bindToRole(roleData, user.id, false);

      const filteredRole: IRoles[] = user.roles.filter((elem) => {
        return !roleData.map((elem2) => elem2.id).includes(elem.id);
      });
      bindToRole(filteredRole, user.id, true);
    }
  }, [isUpdateSuccess]);

  const onSubmit: SubmitHandler<IPeople> = async (data) => {
    const fullname = `${data.last_name} ${data.first_name} ${
      data.middle_name || ""
    }`;
    if (user) {
      updatePeople({
        ...data,
        id: user.id,
        name: fullname,
        organization_type: data.organization_type.name,
        organization: data.organization,
      });
    } else {
      const { data: userData } = await postPeople({
        ...data,
        name: fullname,
        organization_type: data.organization_type.name,
        organization: data.organization,
        is_banned: false,
      });
      confirmEmail(userData?.email);
      bindToRole(data.roles, userData?.id, false);
    }
  };
  const headerLabel = userId
    ? t("I18N_USER_EDIT_USER")
    : t("I18N_USER_ADD_USER");

  return (
    <Page>
      <Header label={headerLabel} permissions={"create_sample"} />
      <FormProvider {...method}>
        <FormSection
          isLoading={false}
          onSubmit={onSubmit}
          formId="create-user-form"
          btnText={userId ? "I18N_EDIT" : "I18N_ADD_CREATE"}
          isEditPage={Boolean(userId)}
          actionSlot={
            userId && <BlockButton isBanned={user?.is_banned} id={user?.id} />
          }>
          <FieldsGenerator
            fullWidth
            locales="users"
            fieldList={formFields}
            selectOptions={{
              roles: roles || [],
              organization_type: orgTypes || [],
              organization: orgs || [],
            }}
          />
        </FormSection>
      </FormProvider>
    </Page>
  );
};
export default UserCreate;
