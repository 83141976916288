import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const TextContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  flexShrink: 1,
}));

const DataNotFound = ({ height }: { height?: string }) => {
  const { t } = useTranslation("components");
  return (
    <TextContainer sx={{ height: height ? height : "70vh" }}>
      <Typography variant="body1" color="text.secondary">
        {t("I18N_DATA_NOT_FOUND")}
      </Typography>
    </TextContainer>
  );
};

export default DataNotFound;
