import { createApi } from '@reduxjs/toolkit/query/react';
import baseAppQuery from './baseAppQuery';
import { IDictType } from '../types/dictionaries';

const ENDPOINT = 'dict/type';

export const dictTypeApi: any = createApi({
  reducerPath: 'dictTypeApi',
  tagTypes: ['dictType'],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getDictTypeList: builder.query<IDictType, string>({
      query: () => ENDPOINT,
      providesTags: ['dictType'],
    }),
    getDictTypeById: builder.query<IDictType, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
      providesTags: ['dictType'],
    }),
    addDictType: builder.mutation<IDictType, IDictType>({
      query: (data) => ({
        url: ENDPOINT,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['dictType'],
    }),
    updateDictType: builder.mutation<IDictType, IDictType>({
      query: (data) => ({
        url: ENDPOINT,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['dictType'],
    }),
    deleteDictType: builder.mutation<IDictType, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['dictType'],
    }),
  }),
});

export const {
  useGetDictTypeListQuery,
  useGetDictTypeByIdQuery,
  useAddDictTypeMutation,
  useUpdateDictTypeMutation,
  useDeleteDictTypeMutation,
} = dictTypeApi;
export default dictTypeApi;
