import { useEffect, useState } from "react";
import type { TPortraitFilterOptions } from "../../types/portraits";
import { getOptionsTree } from "../../utils/selectOptions";
import { v4 } from "uuid";

const useStatusCodeOptions = () => {
  const [options, setOptions] = useState<TPortraitFilterOptions>({
    list: [],
    tree: {},
    slug: v4(),
  });
  useEffect(() => {
    const newOptions = [
      {
        value: "200",
        label: "OK 200",
      },
      {
        value: "201",
        label: "CREATED 201",
      },
      {
        value: "202",
        label: "ACCEPTED 202",
      },
      {
        value: "400",
        label: "BAD REQUEST 400",
      },
      {
        value: "401",
        label: "UNAUTHORIZED 401",
      },
      {
        value: "403",
        label: "FORBIDDEN 403",
      },
      {
        value: "404",
        label: "NOT FOUND 404",
      },
      {
        value: "405",
        label: "METHOD NOT ALLOWED 405",
      },
      {
        value: "500",
        label: "INTERNAL SERVER ERROR 500",
      },
      {
        value: "502",
        label: "BAD GATEWAY 502",
      },
      {
        value: "503",
        label: "SERVICE UNAVAILABLE 503",
      },
    ];
    setOptions({
      list: newOptions,
      tree: getOptionsTree(newOptions),
      slug: v4(),
    });
  }, []);
  return options;
};

export default useStatusCodeOptions;
