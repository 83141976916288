import cn from 'classnames';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

import { FileInfo, TFileAttachment } from '../../../../types/file';
import FileAttachment from './FileAttachment';

type FileAttachmentsProps = {
  attachments: TFileAttachment[];
  multiple: boolean;
  onFileLoaded: (id: string, loadedFile: FileInfo) => void;
  onFileRemove: (id: string) => void;
};

const FileAttachmentsContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} 0`,

  '&.multiple': {
    padding: `${theme.spacing(1)} 0`,
  },
}));

const FileAttachments = ({
  attachments,
  multiple,
  onFileLoaded,
  onFileRemove,
}: FileAttachmentsProps) => {
  if (attachments.length === 0) return null;
  const attachmentsToRender = multiple ? attachments : attachments.slice(0, 1);

  return (
    <FileAttachmentsContainer className={cn({ multiple })}>
      {attachmentsToRender.map((att) => (
        <FileAttachment
          key={att.id}
          attachment={att}
          onFileLoaded={onFileLoaded}
          onFileRemove={onFileRemove}
        />
      ))}
    </FileAttachmentsContainer>
  );
};

export default FileAttachments;
