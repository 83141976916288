import { createApi } from '@reduxjs/toolkit/query/react';
import { IStateMap, IState } from '../types/states';
import baseAppQuery from './baseAppQuery';

const ENDPOINT = 'state-control/api/v1';
const stateMapApi: any = createApi({
  reducerPath: 'stateMapApi',
  tagTypes: ['stateMap'],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getStateMapList: builder.query<IStateMap, IStateMap>({
      query: () => `${ENDPOINT}/state_maps`,
    }),
    getStateMapByActive: builder.query<IState, { active: string }>({
      query: ({ active }) => `${ENDPOINT}/state_maps/${active}`,
    }),
    getStateMapActivePlan: builder.query<IState, { active: string }>({
      query: ({ active }) => `${ENDPOINT}/actives/${active}/plan`,
    }),
    getStateActiveById: builder.query<
      IState,
      { activeName: string; activeId: string }
    >({
      query: ({ activeName, activeId }) =>
        `${ENDPOINT}/states/actives/${activeName}/${activeId}`,
    }),
    getStateActiveHistory: builder.query<
      IState,
      { activeName: string; activeId: string }
    >({
      query: ({ activeName, activeId }) =>
        `${ENDPOINT}/states/actives/${activeName}/${activeId}/history`,
    }),
  }),
});

export const {
  useGetStateMapListQuery,
  useGetStateMapByActiveQuery,
  useGetStateMapActivePlanQuery,
  useGetStateActiveByIdQuery,
  useGetStateActiveHistoryQuery,
} = stateMapApi;
export default stateMapApi;
