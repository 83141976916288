import { useCallback, useEffect, useState } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import useTokenData from "../../hooks/useTokenData";
import { clearTokenState } from "../../redux/slices/auth";
import Authorized from "./Authorized/LazyAuthorized";
import Unauthorized from "./Unauthorized/LazyUnauthorized";
import { useRefreshMutation } from "../../services/token";
import useTokenExpired from "../../hooks/useTokenExpired";
import { ETokenState } from "../../types/token";
import { useNavigate } from "react-router-dom";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sub } = useTokenData();
  const [tokenUpdatedAt, setTokenUpdatedAt] = useState<number>(0);
  const [refresh, { isError }] = useRefreshMutation();
  const accessState = useTokenExpired("accessToken", sub, tokenUpdatedAt);
  const refreshState = useTokenExpired("refreshToken", sub, tokenUpdatedAt);
  const areTokensExpired =
    accessState === ETokenState.EXPIRED && refreshState === ETokenState.EXPIRED;

  const refreshTokens = useCallback(async () => {
    await refresh(null);
    setTokenUpdatedAt(Date.now());
  }, []);

  useEffect(() => {
    if (areTokensExpired || isError) {
      dispatch(clearTokenState());
      navigate("/");
    } else if (accessState !== ETokenState.VALID) {
      refreshTokens();
    }
  }, [accessState, refreshState, isError]);

  if (areTokensExpired || isError) return <Unauthorized />;

  if (accessState === ETokenState.EXPIRED) return <div />; // Placeholder for time when accessToken is retrieved again
  return <Authorized />;
};

export default App;
