import cn from 'classnames';
import Stack from '@mui/material/Stack';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ReactNode } from 'react';
import { useHasPermissions } from '../../hooks/useHasPermissions';
import TableHeadCell, { TableResearchHeadCell } from './TableHeadCell';
import { TOptionalColumn, TTableCell, TTableRow } from './types';

export type TableHeaderProps = {
  row: TTableRow;
  sortField?: string;
  sortOrder?: string;
  onSort?: (field: string) => void;
  skipCols: TOptionalColumn[];
};

type THeaderColumnProps = {
  index: number;
  cell: TTableCell;
  skipCols: TOptionalColumn[];
  name: string;
  sortField?: string;
  sortOrder?: string;
  onSort?: (field: string) => void;
  tableHeader?: string;
};

const HeaderColumn = ({
  index,
  cell,
  skipCols,
  name,
  sortField,
  sortOrder,
  onSort,
  tableHeader,
}: THeaderColumnProps) => {
  const col = skipCols.filter((sk) => sk.columnIndex === index)[0];
  const perms: string | string[] = col ? col.permission : [];
  const hasPermission = useHasPermissions(perms);
  const { align = 'inherit', minWidth, width } = cell;
  let justifyContent: 'flex-start' | 'flex-end' | 'center' | 'space-between' =
    'flex-start';
  switch (align) {
    case 'center':
      justifyContent = 'center';
      break;
    case 'justify':
      justifyContent = 'space-between';
      break;
  }
  const direction: 'row' | 'row-reverse' =
    align === 'right' ? 'row-reverse' : 'row';

  const getValue = ({ value, transformValue, render }: TTableCell) => {
    let valueToRender: ReactNode = value;
    if (transformValue) valueToRender = transformValue(value);
    if (render) valueToRender = render(value);
    return valueToRender as ReactNode;
  };
  if (!hasPermission) return null;
  switch (tableHeader) {
    case 'research':
      return (
        <TableResearchHeadCell
          className={cn({
            sortable: Boolean(onSort),
            right: align === 'right',
            descending: sortOrder === 'desc',
            sorted: sortField === name,
          })}
          width={width ? width : 'auto'}
          sx={{ minWidth: minWidth || 'auto' }}
          onClick={onSort ? () => onSort(name) : undefined}>
          <Stack
            direction={direction}
            alignItems="center"
            justifyContent={justifyContent}
            width="100%">
            {getValue(cell)}
            {onSort && <ArrowUpwardIcon fontSize="small" />}
          </Stack>
        </TableResearchHeadCell>
      );
    default:
      return (
        <TableHeadCell
          className={cn({
            sortable: Boolean(onSort),
            right: align === 'right',
            descending: sortOrder === 'desc',
            sorted: sortField === name,
          })}
          width={width ? width : 'auto'}
          sx={{ minWidth: minWidth || 'auto' }}
          onClick={onSort ? () => onSort(name) : undefined}>
          <Stack
            direction={direction}
            alignItems="center"
            justifyContent={justifyContent}
            width="100%">
            {getValue(cell)}
            {onSort && <ArrowUpwardIcon fontSize="small" />}
          </Stack>
        </TableHeadCell>
      );
  }
};

const TableHeader = ({
  row,
  skipCols,
  sortField,
  sortOrder,
  onSort,
}: TableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        {row.data.map((cell, index) => (
          <HeaderColumn
            key={`col-${cell.value}-${index}`}
            index={index}
            cell={cell}
            skipCols={skipCols}
            name={cell.name || (cell.value as string)}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            tableHeader={row.tableHeaderType}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
