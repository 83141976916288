import { RouteObject } from "react-router-dom";
import PATHS, { TPathEntry } from "./paths";
import { getAccessTokenData } from "../redux/slices/auth/tokenState";
import Settings from "../pages/SettingsBlock/Settings";

const settingsPage: RouteEntriesObject = {
  path: "/settings",
  element: <Settings />,
  permissions: [],
};

export const getAvailableRoutes = (
  blockName: string,
  permissions: string[]
): TPathEntry[] => {
  const blocksForName = PATHS.filter((block) => block.name === blockName);
  if (blocksForName.length === 0) return [];
  return blocksForName[0].children.filter((pathEntry) => {
    if (pathEntry.allowedPermissions.length === 0) return true;
    for (let i = 0; i < permissions.length; i++) {
      const perm = permissions[i];
      if (pathEntry.allowedPermissions.includes(perm)) return true;
    }
    return false;
  });
};

type RouteEntriesObject = RouteObject & {
  permissions?: string[];
};

export const prepareRouteObjects = (
  pathEntry: TPathEntry,
  permissions: string[] | undefined
): RouteObject[] => {
  const Component = pathEntry.component;
  const entries: RouteEntriesObject[] = [
    {
      path: pathEntry.path,
      element: <Component />,
      permissions: pathEntry.allowedPermissions,
    },
  ];
  pathEntry.children?.forEach((childEntry) => {
    const permissionInclude = childEntry.allowedPermissions.every(
      (permission) => permissions?.includes(permission)
    );
    if (permissionInclude) {
      entries.push(...prepareRouteObjects(childEntry, permissions));
    }
  });
  return entries;
};

export const prepareAllRouteObjects = () => {
  const accessToken = localStorage.getItem("accessToken");
  const data = accessToken ? getAccessTokenData(accessToken) : undefined;
  const routeObjects: Array<RouteEntriesObject> = [];

  PATHS.forEach((pathBlock) => {
    pathBlock.children.forEach((pathEntry) => {
      const permissionInclude = pathEntry.allowedPermissions.every(
        (permission) => data?.permissions?.includes(permission)
      );
      if (permissionInclude) {
        routeObjects.push(...prepareRouteObjects(pathEntry, data?.permissions));
      }
    });
  });
  if (accessToken) routeObjects.push(settingsPage);

  return routeObjects;
};

export const getFirstPagePath = () => {
  const allRoutes = prepareAllRouteObjects();
  if (allRoutes.length > 0) {
    for (let i = 0; i < allRoutes.length; i++) {
      const route = allRoutes[i].path;
      if (route && !route.includes(":")) {
        return route;
      }
    }
  }
};
