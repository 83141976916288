import { IHighlightMap, IIcons, ILabels, IStatusColors } from "./types";
import DoneIcon from "@mui/icons-material/Done";
import BiotechIcon from "@mui/icons-material/Biotech";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckIcon from "@mui/icons-material/Check";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import VerifiedIcon from "@mui/icons-material/Verified";
import BlockIcon from "@mui/icons-material/Block";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PersonIcon from "@mui/icons-material/Person";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import LoopIcon from "@mui/icons-material/Loop";
import CancelIcon from "@mui/icons-material/Cancel";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import CreateIcon from "@mui/icons-material/Create";
import ArchiveIcon from "@mui/icons-material/Archive";
import SendAndArchiveIcon from "@mui/icons-material/SendAndArchive";

export const StatusColors: IStatusColors = {
  sample: {
    default: {
      REGISTERED: "success",
      IN_STORAGE: "default",
      UTILIZED: "secondary",
    },
  },
  research: {
    lab_head: {
      ASSIGNED: "secondary",
      REQUEST_FOR_APPROVAL: "warning",
    },
    expert: {
      ASSIGNED: "secondary",
      REQUEST_FOR_APPROVAL: "warning",
    },
    default: {
      CREATED: "info",
      ASSIGNED: "warning",
      UNASSIGNED: "warning",
      IN_WORK: "primary",
      REQUEST_FOR_APPROVAL: "secondary",
      DECLINED: "error",
      APPROVED: "success",
      CANCELED: "error",
      REWORK: "info",
      IN_PROGRESS: "primary",
      ACCEPTED: "success",
    },
  },
  model: {
    default: {
      CREATED: "warning",
      REQUEST_FOR_APPROVAL: "primary",
      RFA_CANCELLED: "error",
      APPROVED: "success",
      DISMISSED: "default",
      REQUEST_FOR_DISMISSAL: "secondary",
    },
  },
  portrait: {
    default: {
      CREATED: "primary",
      READY: "success",
      REQUEST_FOR_ARCHIVING: "warning",
      ARCHIVED: "default",
    },
  },
};

export const HighlightMap: IHighlightMap = {
  sample: {
    expert: {
      REGISTERED: true,
    },
    default: {
      REGISTERED: false,
      IN_STORAGE: false,
      UTILIZED: false,
    },
  },
  research: {
    lab_head: {
      REQUEST_FOR_APPROVAL: true,
    },
    expert: {
      REQUEST_FOR_APPROVAL: true,
    },
    specialist: {
      ASSIGNED: true,
      IN_WORK: true,
    },
    default: {
      CREATED: false,
      ASSIGNED: false,
      UNASSIGNED: false,
      IN_WORK: false,
      REQUEST_FOR_APPROVAL: false,
      DECLINED: false,
      APPROVED: false,
      CANCELED: false,
      REWORK: false,
      IN_PROGRESS: false,
      ACCEPTED: false,
    },
  },
  model: {
    default: {
      CREATED: false,
      REQUEST_FOR_APPROVAL: true,
      RFA_CANCELLED: false,
      APPROVED: false,
      DISMISSED: false,
      REQUEST_FOR_DISMISSAL: true,
    },
  },
  portrait: {
    default: {
      CREATED: false,
      READY: false,
      REQUEST_FOR_ARCHIVING: true,
      ARCHIVED: false,
    },
  },
};

export const Labels: ILabels = {
  sample: {
    default: {
      REGISTERED: "I18N_STATUS_SAMPLE_REGISTERED",
      IN_STORAGE: "I18N_STATUS_SAMPLE_IN_STORAGE",
      UTILIZED: "I18N_STATUS_SAMPLE_UTILIZED",
    },
  },
  research: {
    default: {
      CREATED: "I18N_STATUS_RESEARCH_CREATED",
      ASSIGNED: "I18N_STATUS_RESEARCH_ASSIGNED",
      UNASSIGNED: "I18N_STATUS_RESEARCH_UNASSIGNED",
      IN_WORK: "I18N_STATUS_RESEARCH_IN_WORK",
      REQUEST_FOR_APPROVAL: "I18N_STATUS_RESEARCH_REQ4APPROVAL",
      CANCELED: "I18N_STATUS_RESEARCH_CANCELLED",
      DECLINED: "I18N_STATUS_RESEARCH_DECLINED",
      APPROVED: "I18N_STATUS_RESEARCH_APPROVED",
      REWORK: "I18N_STATUS_RESEARCH_REWORK",
      IN_PROGRESS: "I18N_STATUS_RESEARCH_IN_PROGRESS",
      ACCEPTED: "I18N_STATUS_RESEARCH_ACCEPTED",
    },
  },
  model: {
    default: {
      CREATED: "I18N_STATUS_MODEL_CREATED",
      REQUEST_FOR_APPROVAL: "I18N_STATUS_MODEL_REQUEST_FOR_APPROVAL",
      RFA_CANCELLED: "I18N_STATUS_MODEL_RFA_CANCELLED",
      APPROVED: "I18N_STATUS_MODEL_APPROVED",
      DISMISSED: "I18N_STATUS_MODEL_DISMISSED",
      REQUEST_FOR_DISMISSAL: "I18N_STATUS_MODEL_REQUEST_FOR_DISMISSAL",
    },
  },
  portrait: {
    default: {
      CREATED: "I18N_STATUS_PORTRAIT_CREATED",
      READY: "I18N_STATUS_PORTRAIT_READY",
      REQUEST_FOR_ARCHIVING: "I18N_STATUS_PORTRAIT_REQUEST_FOR_ARCHIVING",
      ARCHIVED: "I18N_STATUS_PORTRAIT_ARCHIVED",
    },
  },
};

export const Icons: IIcons = {
  sample: {
    default: {
      REGISTERED: DoneIcon,
      IN_STORAGE: ArchiveOutlinedIcon,
      UTILIZED: DeleteOutlinedIcon,
    },
  },
  research: {
    expert: {
      ASSIGNED: CheckIcon,
      REQUEST_FOR_APPROVAL: PublishedWithChangesIcon,
    },
    default: {
      CREATED: DataSaverOnIcon,
      ASSIGNED: AnnouncementIcon,
      UNASSIGNED: PersonOffIcon,
      IN_WORK: BiotechIcon,
      REQUEST_FOR_APPROVAL: CheckIcon,
      DECLINED: DeleteOutlinedIcon,
      APPROVED: VerifiedIcon,
      CANCELED: BlockIcon,
      REWORK: RestartAltIcon,
      IN_PROGRESS: LoopIcon,
      ACCEPTED: CheckCircleIcon,
    },
  },
  model: {
    default: {
      CREATED: AnnouncementIcon,
      REQUEST_FOR_APPROVAL: PersonIcon,
      RFA_CANCELLED: CancelIcon,
      APPROVED: CheckIcon,
      DISMISSED: DeleteOutlinedIcon,
      REQUEST_FOR_DISMISSAL: AssistantDirectionIcon,
    },
  },
  portrait: {
    default: {
      CREATED: CreateIcon,
      READY: CheckIcon,
      REQUEST_FOR_ARCHIVING: SendAndArchiveIcon,
      ARCHIVED: ArchiveIcon,
    },
  },
};
