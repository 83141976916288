import { styled } from "@mui/material/styles";
import MuiTablePagination from "@mui/material/TablePagination";

const TablePagination = styled(MuiTablePagination)(({ theme }) => ({
  border: '0 none',
  minHeight: theme.spacing(5),
  flexGrow: 0,
  flexShrink: 0,

  '.MuiTablePagination-displayedRows': {
    fontSize: '0.875rem',
    lineHeight: theme.spacing(5),
    margin: 0,
  }
}));

export default TablePagination;
