import { createApi } from "@reduxjs/toolkit/query/react";
import {
  IBaseCreateMarker,
  IBaseCreateMarkerData,
  IBaseMarker,
  IMarker,
  ITool,
} from "../types/markers";
import baseAppQuery from "./baseAppQuery";

const ENDPOINT = "model/api/v1";
export const markersApi: any = createApi({
  reducerPath: "markersApi",
  tagTypes: ["markers"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getMarkersByModelId: builder.query<IMarker[], string>({
      query: (id: string) => `${ENDPOINT}/models/${id}/markers`,
    }),
    getMarkerById: builder.query<IMarker, { modelId: string; id: string }>({
      query: ({ id, modelId }: { modelId: string; id: string }) =>
        `${ENDPOINT}/models/${modelId}/markers/${id}`,
    }),
    addMarker: builder.mutation<
      IBaseMarker,
      { modelId: string; payload: IBaseCreateMarker }
    >({
      query: ({ modelId, payload }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers`,
        method: "POST",
        body: payload,
      }),
    }),
    updateMarker: builder.mutation<
      IBaseMarker,
      { modelId: string; markerId: string; payload: IBaseCreateMarker }
    >({
      query: ({ modelId, markerId, payload }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers/${markerId}`,
        method: "PUT",
        body: payload,
      }),
    }),
    addMarkerData: builder.mutation<
      IBaseCreateMarkerData,
      { modelId: string; markerId: string; payload: IBaseCreateMarkerData }
    >({
      query: ({ modelId, markerId, payload }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers/${markerId}/mdata`,
        method: "POST",
        body: payload,
      }),
    }),
    updateMarkerData: builder.mutation<
      IBaseCreateMarkerData,
      {
        modelId: string;
        markerId: string;
        markerDataId: string;
        payload: IBaseCreateMarkerData;
      }
    >({
      query: ({ modelId, markerId, markerDataId, payload }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers/${markerId}/mdata/${markerDataId}`,
        method: "PUT",
        body: payload,
      }),
    }),
    bindToolToMarkerData: builder.mutation<
      ITool,
      { modelId: string; markerId: string; toolId: string; unbind?: boolean }
    >({
      query: ({ modelId, markerId, toolId, unbind = false }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers/${markerId}/tools/${toolId}?unbind=${unbind}`,
        method: "PATCH",
      }),
    }),
    deleteMarker: builder.mutation<
      string,
      { modelId: string; markerId: string }
    >({
      query: ({ modelId, markerId }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers/${markerId}`,
        method: "DELETE",
      }),
    }),
    deleteMarkerData: builder.mutation<
      string,
      { modelId: string; markerId: string; markerDataId: string }
    >({
      query: ({ modelId, markerId, markerDataId }) => ({
        url: `${ENDPOINT}/models/${modelId}/markers/${markerId}/mdata/${markerDataId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetMarkersByModelIdQuery,
  useGetMarkerByIdQuery,
  useAddMarkerMutation,
  useAddMarkerDataMutation,
  useBindToolToMarkerDataMutation,
  useUpdateMarkerMutation,
  useUpdateMarkerDataMutation,
  useDeleteMarkerMutation,
  useDeleteMarkerDataMutation,
} = markersApi;
export default markersApi;
