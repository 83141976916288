import { IExecutor } from "../../../../types/markers";

export interface IAutocompleteData {
  id: string;
  name: string | number;
}

export const transformAutocomplete = (data: Array<IExecutor>) => {
  const rd: Array<IAutocompleteData> = [];
  data.forEach((row) => {
    rd.push({ id: row.person_id, name: row.person_name });
  });
  return rd;
};

export const compareTools = (
  initArr: IExecutor[],
  newArr: IAutocompleteData[]
) => {
  const initialMap = new Map(initArr.map((obj) => [obj.person_id, obj]));
  const newMap = new Map(newArr.map((obj) => [obj.id, obj]));
  const deletePerson: Array<string> = [];
  const addPerson: Array<string> = [];

  for (const obj of initArr) {
    if (!newMap.has(obj.person_id)) {
      deletePerson.push(obj.person_id);
    }
  }
  for (const obj of newArr) {
    if (!initialMap.has(obj.id)) {
      addPerson.push(obj.id);
    }
  }
  return {
    add: addPerson,
    delete: deletePerson,
  };
};
