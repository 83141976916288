import { ReactNode } from "react";
import { FieldProps } from "../../../types/fields";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import FileUpload from "./FileUpload";
import { styled } from "@mui/material/styles";

type FileUploadProps = FieldProps & {
  multiple?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: ReactNode;
};

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: "0 5px",
  transform: "translate(9px, -9px) scale(0.75)",
}));

const FileField = ({
  label,
  field,
  forEditableForm,
  multiple = false,
  required = false,
  error = false,
  helperText,
}: FileUploadProps) => {
  const formattedValue = {
    id: "1",
    file: { name: field.value.name, oldFile: true },
    loadedFile: { id: field.value.id },
  };
  const defaultValue = Array.isArray(field.value)
    ? field.value
    : [formattedValue];

  return (
    <FormControl required={required} fullWidth error={error} variant="outlined">
      <StyledLabel shrink htmlFor={`id-for-${field.name}`}>
        {label}
      </StyledLabel>
      <FileUpload
        error={error}
        multiple={multiple}
        onFileChange={field.onChange}
        defaultValue={forEditableForm ? defaultValue : []}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default FileField;
