import { useMemo } from "react";
import TableHeader, {
  TableHeaderProps,
} from "../../../../../components/Table/TableHeader";
import MuiTable from "@mui/material/Table";
import { useTranslation } from "react-i18next";

const prepareTableData = () => {
  const tableData: TableHeaderProps = {
    row: {
      data: [
        { value: "I18N_LOGGER_CREATED", name: "created", width: "14%" },
        { value: "I18N_LOGGER_USER", name: "userid", width: "14%" },
        { value: "I18N_LOGGER_METHOD", name: "operation", width: "14%" },
        { value: "I18N_LOGGER_STATUS_CODE", name: "status", width: "14%" },
        { value: "I18N_LOGGER_REMOTE_IP", name: "remoteip", width: "14%" },
        { value: "URI", name: "uri", width: "30%" },
      ],
    },
    skipCols: [],
  };
  return tableData;
};

const SystemLogTableHeader = ({
  sort,
  handleSort,
}: {
  sort?: { field: string; order: "asc" | "desc" };
  handleSort?: (field: string) => void;
}) => {
  const { t } = useTranslation("loggers");
  const tableData: TableHeaderProps = useMemo<TableHeaderProps>(() => {
    const td = prepareTableData();
    td.row.data.forEach((r) => (r.value = t(r.value as string) || ""));
    return td;
  }, [t]);

  return (
    <MuiTable>
      <TableHeader
        sortField={sort?.field}
        sortOrder={sort?.order}
        onSort={handleSort}
        row={tableData.row}
        skipCols={tableData.skipCols}
      />
    </MuiTable>
  );
};
export default SystemLogTableHeader;
