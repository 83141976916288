import { useTranslation } from "react-i18next";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import { useNavigate } from "react-router-dom";
import SearchField from "../../../../components/Inputs/SearchField";

import { useState } from "react";
import AdminPanelTable from "../../../../components/AdminPanelTable";
import { preparedData } from "./preparedData";
import Preloader from "../../../../components/Preloader";
import Permission from "../../../../config/Permission";
import {
  useDeleteToolbyIdMutation,
  useGetToolsListQuery,
} from "../../../../services/tools";
import { SelectChangeEvent } from "@mui/material";

const selectOptions = [
  {
    value: "name",
    title: "I18N_TABLE_NAME",
  },
  {
    value: "descr",
    title: "I18N_TABLE_DESCRIPTION",
  },
];

const Tools = () => {
  const { t } = useTranslation("tools");
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const { data, refetch } = useGetToolsListQuery(
    {
      search: filter,
      searchBy: searchBy,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteTool] = useDeleteToolbyIdMutation();

  const handleDelete = (id: string) => {
    deleteTool(id);
    refetch();
  };

  const handleSearchByChange = (event: SelectChangeEvent<unknown>) => {
    setSearchBy(event.target.value as string);
  };

  const searchSelectData = {
    value: searchBy,
    options: selectOptions,
    onChange: handleSearchByChange,
  };

  return (
    <Page fullHeight>
      <Header
        label={t("I18N_TOOLS_HEADER")}
        createLabel={t("I18N_TOOLS_ADD_TOOL")}
        permissions={Permission.Tool.CONTROL}
        onCreate={() => navigate("create")}
        renderComponent={
          <SearchField onSearch={setFilter} searchBy={searchSelectData} />
        }
      />
      {data ? (
        <AdminPanelTable
          data={data}
          prepareData={preparedData}
          onDelete={handleDelete}
        />
      ) : (
        <Preloader />
      )}
    </Page>
  );
};
export default Tools;
