export enum ESample {
  REGISTERED = 'REGISTERED', // зарегистрирована
  UTILIZED = 'UTILIZED', // утилизирована
  IN_STORAGE = 'IN_STORAGE', // на хранении
}

export enum EResearch {
  CREATED = 'CREATED', // создана
  ACCEPTED = 'ACCEPTED', // новое
  IN_PROGRESS = 'IN_PROGRESS', // в прогрессе
  REQUEST_FOR_APPROVAL = 'REQUEST_FOR_APPROVAL', // запрос на утверждение
  APPROVED = 'APPROVED', // утверждено
  CANCELED = 'CANCELED', // отменено
  REWORK = 'REWORK', // повторное исследования
}

export enum EResearchPlan {
  UNASSIGNED = 'UNASSIGNED', // не назначено
  ASSIGNED = 'ASSIGNED', // назначено
  IN_WORK = 'IN_WORK', // в работе
  APPROVED = 'APPROVED', // утверждено
  CANCELED = 'CANCELED', // отменено
}

export enum EModel {
  CREATED = 'CREATED', // создана (след REQUEST_FOR_APPROVAL)
  REQUEST_FOR_APPROVAL = 'REQUEST_FOR_APPROVAL', // запрос на утверждение (след APPROVED или RFA_CANCELLED)
  APPROVED = 'APPROVED', // утверждена (след REQUEST_FOR_DISMISSAL)
  RFA_CANCELLED = 'RFA_CANCELLED', // запрос на утверждение был отклонён (след REQUEST_FOR_APPROVAL)
  REQUEST_FOR_DISMISSAL = 'REQUEST_FOR_DISMISSAL', // запрос на вывод модели из эксплуатации (след APPROVED или DISMISSED)
  DISMISSED = 'DISMISSED', // выведена из эксплуатации
}

export type TStatus = {
  Sample: typeof ESample;
  Research: typeof EResearch;
  ResearchPlan: typeof EResearchPlan;
  Model: typeof EModel;
};

export default {
  Sample: ESample,
  Research: EResearch,
  ResearchPlan: EResearchPlan,
  Model: EModel,
} as TStatus;
