import { PropsWithChildren, ReactNode, useMemo } from "react";
import DetailsView, { TDetailsBlock } from "../DetailsView";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

type TDetailsViewWidgetProps = {
  data?: any;
  preparedData: (data: any, t: TFunction) => TDetailsBlock[];
  defaultCollapsed?: boolean;
  locales: string;
  slot?: ReactNode;
};

const DetailsViewWidget = ({
  data,
  preparedData,
  defaultCollapsed = false,
  locales,
  slot,
  children,
}: PropsWithChildren<TDetailsViewWidgetProps>) => {
  const { t } = useTranslation(locales);

  const prepareData: TDetailsBlock[] = useMemo(() => {
    const rv = data ? preparedData(data, t) : [];
    rv.forEach((block) => {
      block.title = t(block.title, { subTitle: block.subtitle }) || "";
      block.details.forEach((detail) => {
        detail.title = t(detail.title) || "";
        detail.title = t(detail.title) || "";
        detail.value =
          (!detail.transformValue && t(detail.value?.toString() || "")) ||
          (!detail.transformValue && t(detail.value?.toString() || "")) ||
          detail.value;
      });
    });
    return rv;
  }, [data, t]);

  return (
    <>
      {prepareData.map(({ title, details }, index) => (
        <DetailsView
          key={title}
          title={title}
          data={details}
          slot={index === 0 ? slot : undefined}
          defaultCollapsed={defaultCollapsed}>
          {children}
        </DetailsView>
      ))}
    </>
  );
};
export default DetailsViewWidget;
