import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Logo from "../Logo";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { getErrorMessage } from "./utils";

const StyledLogo = styled(Logo)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(8)})`,
  height: "auto",
  position: "fixed",
  transform: `translateY(-${theme.spacing(20)})`,
}));
const PageContainer = styled(Box)(() => ({
  backgroundColor: "rgb(239, 246, 252)",
  width: "100vw",
  height: "100vh",
}));

const PaperBox = styled(Paper)(({ theme }) => ({
  width: "80%",
  maxWidth: theme.spacing(64),
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(1),
}));

const LogoContainer = styled(Box)(({}) => ({
  position: "relative",
  width: "100%",
  boxSizing: "border-box",
}));

export type TError = {
  code: number;
  message: string;
};
type AuthFormWrapperProps = {
  error: FetchBaseQueryError | SerializedError | undefined;
};
const AuthFormWrapper = ({
  error,
  children,
}: PropsWithChildren<AuthFormWrapperProps>) => {
  const { t } = useTranslation("login");
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (error && "data" in error) {
      const { code } = error.data as TError;
      const message = getErrorMessage(code);
      setErrorMessage(message);
      setOpenAlert(Boolean(error));
    }
  }, [error]);

  return (
    <PageContainer>
      <PaperBox elevation={0}>
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        {children}
        <Snackbar
          sx={{ width: "inherit" }}
          open={openAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => setOpenAlert(false)}>
            {t(errorMessage)}
          </Alert>
        </Snackbar>
      </PaperBox>
    </PageContainer>
  );
};
export default AuthFormWrapper;
