import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack/";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FileProgressLoader from "./FileProgressLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import { TFileAttachment, FileInfo } from "../../../../types/file";
import { useFileUpload } from "../../../../services/file";

type FileAttachmentProps = {
  attachment: TFileAttachment;
  onFileRemove: (id: string) => void;
  onFileLoaded: (id: string, loadedFile: FileInfo) => void;
};

const FileAttachmentContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
}));

const FileName = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontSize: "0.875rem",
  lineHeight: theme.spacing(4),
  whiteSpace: "break-spaces",
  overflow: "hidden",
  textOverflow: "ellipsis",
  flexGrow: 1,
  flexShrink: 1,
  width: theme.spacing(1),

  "&.error": {
    color: theme.palette.text.secondary,
  },
}));

const FileAttachment = ({
  attachment,
  onFileRemove,
  onFileLoaded,
}: FileAttachmentProps) => {
  const { t } = useTranslation("components");
  const { file, progress, uploadFile, cancelLoading, error } = useFileUpload();
  const oldFile = Object.hasOwn(attachment.file, "oldFile");
  const [fileError, setFileError] = useState(false);

  const handleRemoveAttachment = () => {
    if (attachment?.id) onFileRemove(attachment.id);
    cancelLoading();
  };

  useEffect(() => {
    if (attachment?.file && !oldFile) uploadFile(attachment.file);
  }, []);

  useEffect(() => {
    if (progress === 100 && file && attachment?.id) {
      const isError = Object.hasOwn(file, "error");
      if (isError || error) {
        setFileError(isError);
        return;
      }
      onFileLoaded(attachment.id, file);
    }
  }, [progress]);

  return (
    <FileAttachmentContainer direction="row" spacing={0.5} alignItems="center">
      <FileProgressLoader
        isError={fileError}
        progress={oldFile ? 100 : progress}
      />
      <FileName className={cn({ error: fileError })}>
        {!fileError
          ? attachment?.file?.name || "UNKNOWN"
          : t("I18N_FILE_UPLOAD_ERROR")}
      </FileName>
      <IconButton color="error" onClick={handleRemoveAttachment}>
        <DeleteIcon />
      </IconButton>
    </FileAttachmentContainer>
  );
};
export default FileAttachment;
