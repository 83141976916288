import { createApi } from "@reduxjs/toolkit/query/react";
import {
  IModel,
  IModelClone,
  IModelCreate,
  IModelStateHistoryFull,
  IModelUpdate,
  IModelsApiResponse,
  TModelQueryParams,
} from "../types/models";
import { IPaginatedList, IPaginatedListParams } from "../types/paginated";
import baseAppQuery from "./baseAppQuery";
import { IState } from "../types/states";

const ENDPOINT = "model/api/v1/models";
export const modelsApi: any = createApi({
  reducerPath: "modelsApi",
  tagTypes: ["models"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getModelsList: builder.query<
      IPaginatedList<IModel>,
      TModelQueryParams & IPaginatedListParams
    >({
      query: (qp) => {
        const queryParams = new URLSearchParams();
        queryParams.set("page", qp.pageNo.toString());
        queryParams.set("page-size", qp.pageSize.toString());
        if (qp.sort) {
          queryParams.set("sort", qp.sort.field);
          queryParams.set("order", qp.sort.order);
        }
        if (qp.approved) queryParams.set("only_approved", qp.approved);

        if (qp.search) {
          const pieces = qp.search
            .filter(({ value }) => typeof value === "string" && value.trim())
            .map(({ field, value }) => `${field}=${value as string}`);
          if (pieces.length > 0) {
            queryParams.set("search", pieces.join(";;"));
          }
        }
        return `${ENDPOINT}?${queryParams.toString()}`;
      },
      transformResponse: (baseQueryReturnValue: IModelsApiResponse) => {
        return {
          pages: baseQueryReturnValue.pages_total,
          data: baseQueryReturnValue.Models,
        };
      },
    }),
    getModelById: builder.query<IModel, string>({
      query: (modelId: string) => `${ENDPOINT}/${modelId}`,
    }),
    getFullModelById: builder.query<IModel, string>({
      query: (modelId: string) => `${ENDPOINT}/${modelId}/full_model`,
    }),
    getModelStateHistory: builder.query<IModel, string>({
      query: (modelId: string) => `${ENDPOINT}/${modelId}/states`,
    }),
    getStates: builder.query({
      query: () => `/model/api/v1/states`,
    }),
    updateModelDescription: builder.mutation<
      string,
      { modelId: string; payload: IModelCreate }
    >({
      query: ({ modelId, payload }) => ({
        url: `${ENDPOINT}/${modelId}`,
        method: "PUT",
        body: payload,
      }),
    }),
    updateFullModel: builder.mutation<
      string,
      { modelId: string; payload: IModelUpdate }
    >({
      query: ({ modelId, payload }) => ({
        url: `${ENDPOINT}/full_model/${modelId}`,
        method: "PUT",
        body: payload,
      }),
    }),
    updateModelState: builder.mutation<
      IModelStateHistoryFull,
      { modelId: string; new_state: string; reason?: string }
    >({
      query: ({ modelId, new_state, reason = null }) => ({
        url: `${ENDPOINT}/${modelId}/states/${new_state}${
          reason ? `?descr=${reason}` : ""
        }`,
        method: "PUT",
      }),
    }),
    addModel: builder.mutation<IModel, IModelCreate>({
      query: (payload: IModelCreate) => ({
        url: `${ENDPOINT}`,
        method: "POST",
        body: payload,
      }),
    }),
    addModelClone: builder.mutation<IModel, IModelClone>({
      query: ({ id, new_name, new_version }) => ({
        url: `${ENDPOINT}/${id}/copy?new_name=${new_name}&new_version=${new_version}`,
        method: "POST",
      }),
    }),
    deleteModelById: builder.mutation<IModel, string>({
      query: (modelId: string) => ({
        url: `${ENDPOINT}/${modelId}`,
        method: "DELETE",
      }),
    }),
    getModelStateMap: builder.query<IState, IState>({
      query: () => `/model/api/v1//states/statemap`,
    }),
    updateModelStateMap: builder.mutation<IState, IState>({
      query: () => ({
        url: `/model/api/v1//states/statemap`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetModelsListQuery,
  useLazyGetModelsListQuery,
  useGetModelByIdQuery,
  useLazyGetModelByIdQuery,
  useGetFullModelByIdQuery,
  useLazyGetFullModelByIdQuery,
  useGetModelStateHistoryQuery,
  useGetStatesQuery,
  useUpdateModelDescriptionMutation,
  useUpdateFullModelMutation,
  useUpdateModelStateMutation,
  useAddModelMutation,
  useAddModelCloneMutation,
  useDeleteModelByIdMutation,
  useGetModelStateMapQuery,
  useUpdateModelStateMapMutation,
} = modelsApi;
export default modelsApi;
