import React, { Suspense } from "react";

const Researches = React.lazy(() => import("./Researches"));

const LazyResearches = () => (
  <Suspense fallback={<div />}>
    <Researches />
  </Suspense>
);

export default LazyResearches;
