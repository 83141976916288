import React, { Suspense } from "react";

const ModelsCreate = React.lazy(() => import("./ModelsCreate"));

const LazyModelsCreate = () => (
  <Suspense fallback={<div />}>
    <ModelsCreate />
  </Suspense>
);

export default LazyModelsCreate;
