import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useFileDownload, useFileDownloadMulti } from "../../services/file";
import Typography from "@mui/material/Typography";

const StyledButton = styled(Button)(() => ({
  padding: `6px`,
  lineHeight: "1.125rem",
  margin: "-3px 0",
}));

const ProgressNumber = styled(Typography)(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  width: "1.875rem",
  height: "1.875rem",
  lineHeight: "1.875rem",
  textAlign: "center",
  color: theme.palette.primary.main,
  fontSize: "0.75rem",
  fontWeight: 500,
}));

const ProgressContainer = styled(Stack)(() => ({
  margin: "-3px 0",
  alignItems: "center",
}));

type TDownloadButtonProps = {
  filePrefix?: string;
  fileName: string;
  links: string | string[];
};

const DownloadButton = ({
  filePrefix,
  fileName,
  links,
}: TDownloadButtonProps) => {
  const {
    downloadFile,
    progress: singleProgress,
    isLoading: singleLoading,
  } = useFileDownload();
  const {
    downloadFiles,
    progress: multiProgress,
    isLoading: multiLoading,
  } = useFileDownloadMulti();
  const { t } = useTranslation("components");

  const handleDownloadFiles = () => {
    const fileNameWithPrefix = [filePrefix, fileName]
      .filter((p) => Boolean(p))
      .join(".");

    if (Array.isArray(links)) {
      // Download multiple files
      downloadFiles(fileNameWithPrefix, links);
    } else {
      downloadFile(fileNameWithPrefix, links);
    }
  };

  if (singleLoading || multiLoading) {
    return (
      <ProgressContainer direction="row" spacing={1}>
        <Box sx={{ position: "relative", height: "1.875rem" }}>
          <CircularProgress
            variant="determinate"
            value={Math.max(singleProgress, multiProgress)}
            size={30}
          />
          <ProgressNumber>
            {Math.min(99, Math.max(singleProgress, multiProgress))}
          </ProgressNumber>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {Array.isArray(links)
            ? t("I18N_DOWNLOAD_PROGRESS_MULTI")
            : t("I18N_DOWNLOAD_PROGRESS")}
        </Typography>
      </ProgressContainer>
    );
  }

  return (
    <StyledButton
      startIcon={<SimCardDownloadIcon />}
      size="small"
      onClick={handleDownloadFiles}>
      {t("I18N_DOWNLOAD")}
    </StyledButton>
  );
};
export default DownloadButton;
