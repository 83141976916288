import { IPaginatedList, IPaginatedListParams } from "../types/paginated";

function transformPaginatedResponse<T>(
  baseQueryReturnValue: T[],
  meta: { request: Request; response: Response },
  arg: IPaginatedListParams
): IPaginatedList<T> {
  const allPages = meta.response.headers.get("X-Total-Pages");

  let availablePages: number;
  if (allPages) {
    const intAllPages = parseInt(allPages, 10);
    availablePages = Number.isNaN(intAllPages) ? 1 : intAllPages;
  } else {
    let totalItems = parseInt(
      meta.response.headers.get("Total-Page") || "0",
      10
    );
    if (Number.isNaN(totalItems)) totalItems = 0;
    availablePages = Math.max(Math.ceil(totalItems / arg.pageSize), 1);
  }

  return {
    pages: availablePages,
    data: Array.isArray(baseQueryReturnValue)
      ? [...baseQueryReturnValue]
      : [baseQueryReturnValue],
  };
}

export default transformPaginatedResponse;
