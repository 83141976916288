import React, { Suspense } from 'react';

const Roles = React.lazy(() => import('./Roles'));

const LazyRoles = () => (
  <Suspense fallback={<div />}>
    <Roles />
  </Suspense>
);

export default LazyRoles;
