import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import DateRangeField from "../../../../FilterFields/DateRangeField";

type TEditViewProps = {
  anchorEl: HTMLDivElement | null;
  valueFrom?: string;
  valueTo?: string;
  format?: string;
  onChange: (type: string, value: string, label: string) => void;
  onRemove: (type: string) => void;
  onClose: () => void;
}

const EditViewContainer = styled(Stack)(({ theme }) => ({
  width: theme.spacing(80),
  padding: theme.spacing(2),
}));

const EditView = ({
  format,
  anchorEl,
  valueFrom,
  valueTo,
  onChange,
  onRemove,
  onClose,
}: TEditViewProps) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    sx={{ mt: 2 }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <EditViewContainer>
      <DateRangeField
        format={format}
        valueFrom={valueFrom}
        valueTo={valueTo}
        onUpdate={onChange}
        onDelete={onRemove}
      />
    </EditViewContainer>
  </Popover>
);

export default EditView;
