import { MouseEvent, useState } from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { alpha, styled } from "@mui/material/styles";
import {
  TPortraitFilterOptions,
  TPortraitFilterValue,
} from "../../../../../types/portraits";
import EditView from "./EditView";

export const Chip = styled(Stack)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.success.main,
  color: theme.palette.success.main,
  flexDirection: "row",
  flexWrap: "nowrap",
  alignItems: "center",
  fontSize: "0.8125rem",
  fontWeight: "bolder",
  marginBottom: theme.spacing(1),
  lineHeight: theme.spacing(2.75),
  borderRadius: theme.spacing(1.5),
  padding: `0 0 0 ${theme.spacing(1)}`,
  position: "relative",
  cursor: "pointer",

  label: {
    fontWeight: "normal",
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
  },

  "&:not(:last-child)": {
    marginRight: theme.spacing(1),
  },

  ".MuiButtonBase-root": {
    marginLeft: theme.spacing(0.5),
    padding: 0,
    color: theme.palette.grey[400],

    "&:hover": {
      color: theme.palette.error.main,
    },
  },

  "&:hover": {
    backgroundColor: alpha(theme.palette.success.main, 0.2),
  },
}));

type TAppliedFilter = {
  filterType: string;
  label: string;
  value: TPortraitFilterValue;
  inputType: "select" | "autocomplete" | "date" | "text" | "toggle" | "multy";
  options?: TPortraitFilterOptions;
  onChange: (type: string, value: string, label: string) => void;
  onRemove: (type: string) => void;
};

const AppliedFilter = ({
  filterType,
  label,
  value,
  inputType,
  options,
  onChange,
  onRemove,
}: TAppliedFilter) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onRemove(filterType);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Chip onClick={handleClick}>
        <label>{label}</label>
        <span>{value.label}</span>
        <IconButton size="small" onClick={handleRemove}>
          <CancelIcon />
        </IconButton>
      </Chip>
      <EditView
        anchorEl={anchorEl}
        filterType={filterType}
        value={value.value}
        title={label}
        inputType={inputType}
        options={options}
        onChange={onChange}
        onRemove={onRemove}
        onClose={() => setAnchorEl(null)}
      />
    </>
  );
};

export default AppliedFilter;
