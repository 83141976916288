import { createApi } from "@reduxjs/toolkit/query/react";
import baseAppQuery from "./baseAppQuery";
import { IProductType } from "../types/dictionaries";

const ENDPOINT = "dict/product-type";
export const productTypeApi: any = createApi({
  reducerPath: "productTypeApi",
  tagTypes: ["productType"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getProductTypeList: builder.query<IProductType, string>({
      query: () => ENDPOINT,
      transformResponse(response: IProductType[]): any {
        const formattedPd = response?.map((dict) => ({
          id: dict.id,
          code: dict.code,
          nameRU: dict.name,
          nameKK: dict.nameKz,
          nameEN: dict.nameEn,
          descriptionRU: dict.descriptionRu,
          descriptionKK: dict.descriptionKz,
          descriptionEN: dict.descriptionEn,
        }));
        return formattedPd;
      },
      providesTags: ["productType"],
    }),
    getProductTypeById: builder.query<IProductType, string>({
      query: (id) => `${ENDPOINT}/${id}`,
      providesTags: ["productType"],
    }),
    getProductTypeByName: builder.query<IProductType, { name: string }>({
      query: ({ name }) => `${ENDPOINT}/name/${name}`,
      providesTags: ["productType"],
    }),
    addProductType: builder.mutation<IProductType, IProductType>({
      query: (data) => ({
        url: ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["productType"],
    }),
    updateProductType: builder.mutation<IProductType, IProductType>({
      query: (data) => ({
        url: `${ENDPOINT}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["productType"],
    }),
    deleteProductType: builder.mutation<IProductType, string>({
      query: (id) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["productType"],
    }),
  }),
});

export const {
  useGetProductTypeListQuery,
  useGetProductTypeByIdQuery,
  useGetProductTypeByNameQuery,
  useLazyGetProductTypeByNameQuery,
  useAddProductTypeMutation,
  useUpdateProductTypeMutation,
  useDeleteProductTypeMutation,
} = productTypeApi;
export default productTypeApi;
