import { useEffect, useState } from "react";
import { b64utoutf8, KJUR } from "jsrsasign";
import { ETokenState, TTokenData } from "../types/token";

const getTokenState = (
  tokenType: "accessToken" | "refreshToken"
): ETokenState => {
  const token = localStorage.getItem(tokenType);
  if (!token) return ETokenState.EXPIRED;
  const data = KJUR.jws.JWS.readSafeJSONString(
    b64utoutf8(token.split(".")[1])
  ) as TTokenData | null;
  if (!data) return ETokenState.EXPIRED;
  const now = Date.now();
  const exp = data.exp * 1000;
  if (now > exp) return ETokenState.EXPIRED;
  if (now + 60000 > exp) return ETokenState.WILL_EXPIRE;
  return ETokenState.VALID;
};

const checkTokenState = (
  tokenType: "accessToken" | "refreshToken",
  executeImmediately = false
): [Promise<[number, ETokenState]>, number] => {
  let timeout = 0;
  const promise: Promise<[number, ETokenState]> = new Promise((resolve) => {
    timeout = window.setTimeout(
      () => {
        const isExpired = getTokenState(tokenType);
        resolve([Date.now(), isExpired]);
      },
      executeImmediately ? 0 : 60000
    );
  });
  return [promise, timeout];
};

const useTokenExpired = (
  tokenType: "accessToken" | "refreshToken",
  sub: string,
  upd: number
): ETokenState => {
  const [expired, setExpired] = useState<ETokenState>(ETokenState.VALID);
  const [lastChecked, setLastChecked] = useState<number>(0);
  const [activeTimeout, setActiveTimeout] = useState<number>(0);
  const [activeSub, setActiveSub] = useState<string | null>(null);
  const [lastUpd, setLastUpd] = useState<number>(0);

  useEffect(() => {
    const check = async () => {
      const [promise, timeout] = checkTokenState(tokenType);
      setActiveTimeout(timeout);
      const [checkDate, isExpired] = await promise;
      if (checkDate - lastChecked >= 60000) {
        setExpired(isExpired);
        setLastChecked(checkDate);
      }
    };
    check();
  }, [lastChecked]);

  useEffect(() => {
    if (sub !== activeSub || upd !== lastUpd) {
      if (sub !== activeSub) setActiveSub(sub);
      if (upd !== lastUpd) setLastUpd(upd);
      if (activeTimeout) {
        window.clearTimeout(activeTimeout);
        setActiveTimeout(0);
      }
      setExpired(getTokenState(tokenType));
      setLastChecked(Date.now());
    }
  }, [sub, upd, activeSub, lastUpd, activeTimeout]);

  return expired;
};

export default useTokenExpired;
