import { createApi } from "@reduxjs/toolkit/query/react";

import {
  IResearchCreate,
  IResearchEntry,
  IResearchView,
  TResearchEntryStateName,
  IResearchEntryState,
} from "../types/researches";

import { IPaginatedListParams } from "../types/paginated";
import { IMarkerDataSubmit } from "../types/markers";
import baseAppQuery from "./baseAppQuery";
import { IState } from "../types/states";

const ENDPOINT = "research/api/v1";

type TGetResearchListArgs<T> = {
  modelId: string;
  markerId: string;
  sampleId: string;
  researchPlanId: string;
} & T;

export const researchApi: any = createApi({
  reducerPath: "researchApi",
  tagTypes: ["researches"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getResearchList: builder.query<
      IResearchEntry[],
      TGetResearchListArgs<IPaginatedListParams>
    >({
      query: ({
        markerId,
        modelId,
        pageNo,
        pageSize,
        sampleId,
        researchPlanId,
      }) => {
        const rawQueryParams: { [key: string]: any } = {
          model_id: modelId,
          marker_id: markerId,
          sample_id: sampleId,
          rplan_id: researchPlanId,
          page: Math.max(pageNo || 1, 1),
          page_size: pageSize || 1000,
          logic_operator: "AND",
        };
        const queryParams = Object.keys(rawQueryParams)
          .map(
            (k) =>
              `${encodeURIComponent(k)}=${encodeURIComponent(
                rawQueryParams[k]
              )}`
          )
          .join("&");
        return `${ENDPOINT}/researches?${queryParams}`;
      },
      transformResponse: (
        baseQueryReturnValue: IResearchEntry[],
        _: any,
        params: TGetResearchListArgs<IPaginatedListParams>
      ): IResearchEntry[] => {
        const { markerId, modelId, sampleId, researchPlanId } = params;
        return baseQueryReturnValue.filter(
          (bqrv) =>
            bqrv.model_id === modelId &&
            bqrv.marker_id === markerId &&
            bqrv.sample_id === sampleId &&
            bqrv.research_plan_id === researchPlanId
        );
      },
      providesTags: ["researches"],
    }),
    getResearchStates: builder.query<
      { [key: string]: IResearchEntryState },
      undefined
    >({
      query: () => `${ENDPOINT}/states`,
      transformResponse: (baseQueryReturnValue: IResearchEntryState[]) => {
        const rv: { [key: string]: IResearchEntryState } = {};
        baseQueryReturnValue.forEach((bqrv) => {
          rv[bqrv.name] = bqrv;
        });
        return rv;
      },
      providesTags: ["researches"],
    }),
    deleteResearch: builder.mutation<null, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["researches"],
    }),
    deleteResearchData: builder.mutation<null, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/researches/${id}/rdata`,
        method: "DELETE",
      }),
      invalidatesTags: ["researches"],
    }),
    postResearch: builder.mutation<IResearchView, IResearchCreate>({
      query: (payload: IResearchCreate) => ({
        url: `${ENDPOINT}/researches`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["researches"],
    }),
    postResearchData: builder.mutation<null, { id: string; data: object[] }>({
      query: ({ id, data }: { id: string; data: IMarkerDataSubmit[] }) => ({
        url: `${ENDPOINT}/researches/${id}/rdata`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["researches"],
    }),
    putResearchState: builder.mutation<
      null,
      {
        id: string;
        state: TResearchEntryStateName;
        body?: { sample_consumed: number };
      }
    >({
      query: ({
        id,
        state,
        body,
      }: {
        id: string;
        state: TResearchEntryStateName;
        body?: { sample_consumed: number };
      }) => ({
        url: `${ENDPOINT}/researches/${id}/state/${state}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["researches"],
    }),
    getResearchStateMap: builder.query<IState, IState>({
      query: () => `${ENDPOINT}/states/statemap`,
      providesTags: ["researches"],
    }),
    putResearchStateMap: builder.mutation<IState, IState>({
      query: () => ({
        url: `${ENDPOINT}/states/statemap`,
        method: "PUT",
      }),
      invalidatesTags: ["researches"],
    }),
  }),
});

export const {
  useGetResearchListQuery,
  useLazyGetResearchListQuery,
  useGetResearchStatesQuery,
  useDeleteResearchMutation,
  useDeleteResearchDataMutation,
  usePostResearchMutation,
  usePostResearchDataMutation,
  usePutResearchStateMutation,
  useGetResearchStateMapQuery,
  usePutResearchStateMapMutation,
} = researchApi;
export default researchApi;
