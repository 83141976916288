import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAlert } from "../../components/Alert";

const initialState: TAlert = {
  open: false,
  message: "",
  duration: 10000,
  variant: "standard",
  severity: "error",
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<TAlert>) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.duration = action.payload.duration || initialState.duration;
      state.variant = action.payload.variant || initialState.variant;
      state.severity = action.payload.severity || initialState.severity;
    },
    toggleAlert: (state) => {
      state.open = !state.open;
    },
  },
});

export const { setAlert, toggleAlert } = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
