import { useEffect, useState } from "react";
import type { TPortraitFilterOptions } from "../../types/portraits";
import { getOptionsTree } from "../../utils/selectOptions";
import { v4 } from "uuid";
import { useGetPeopleListQuery } from "../../services/people";

const useUserNameOptions = () => {
  const [options, setOptions] = useState<TPortraitFilterOptions>({
    list: [],
    tree: {},
    slug: v4(),
  });

  const { data: users } = useGetPeopleListQuery({
    search: "",
    searchBy: "name",
  });
  useEffect(() => {
    if (users && users.length) {
      const newOptions = users.map((user) => ({
        value: user.id,
        label: user.name,
      }));
      setOptions({
        list: newOptions,
        tree: getOptionsTree(newOptions),
        slug: v4(),
      });
    }
  }, [users]);
  return options;
};
export default useUserNameOptions;
