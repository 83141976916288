import baseAppQuery from "./baseAppQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { IPaginatedListParams } from "../types/paginated";
import { IEventLogger } from "../types/loggers";
import { TLoggerFilterParams } from "../hooks/useLoggerFilter/types";

type FilterParams = {
  qp: TLoggerFilterParams & IPaginatedListParams;
  page?: string;
  page_size?: string;
  date_begin?: string;
  date_end?: string;
};
const filterParams = ({
  qp,
  page = "page",
  page_size = "page_size",
}: FilterParams) => {
  const queryParams = new URLSearchParams();
  queryParams.set(page, qp.pageNo.toString());
  queryParams.set(page_size, qp.pageSize.toString());

  if (qp.userid) {
    queryParams.set("user_id", qp.userid);
  }
  if (qp.operation) {
    queryParams.set("method", qp.operation);
  }
  if (qp.status) {
    queryParams.set("status", qp.status);
  }
  if (qp.remote_ip) {
    queryParams.set("remote_ip", qp.remote_ip);
  }
  if (qp.from) {
    queryParams.set("date_begin", qp.from);
  }
  if (qp.to) {
    queryParams.set("date_end", qp.to);
  }
  return queryParams.toString();
};

const loggersApi = createApi({
  reducerPath: "loggersApi",
  tagTypes: ["loggers"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getAuthLogsInfo: builder.query<
      IEventLogger,
      TLoggerFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const qFilterParams = filterParams({ qp });
        return `/auth/api/v1/logs?${qFilterParams}`;
      },
    }),
    getSampleLogsInfo: builder.query<
      IEventLogger,
      TLoggerFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const qFilterParams = filterParams({
          qp,
          page: "pageNo",
          page_size: "pageSize",
        });
        return `samples/logging?${qFilterParams}`;
      },
    }),
    getDictLogsInfo: builder.query<
      IEventLogger,
      TLoggerFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const qFilterParams = filterParams({
          qp,
          page: "pageNo",
          page_size: "pageSize",
        });
        return `dict/logging?${qFilterParams}`;
      },
    }),
    getModelLogsInfo: builder.query<
      IEventLogger,
      TLoggerFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const qFilterParams = filterParams({ qp });
        return `model/api/v1/logs?${qFilterParams}`;
      },
    }),
    getResearchLogsInfo: builder.query<
      IEventLogger,
      TLoggerFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const qFilterParams = filterParams({ qp });
        return `research/api/v1/logs?${qFilterParams}`;
      },
    }),
    getPortraitLogsInfo: builder.query<
      IEventLogger,
      TLoggerFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const qFilterParams = filterParams({ qp });
        return `portrait/api/v1/logs?${qFilterParams}`;
      },
    }),
  }),
});

export const {
  useGetAuthLogsInfoQuery,
  useGetSampleLogsInfoQuery,
  useGetDictLogsInfoQuery,
  useGetModelLogsInfoQuery,
  useGetResearchLogsInfoQuery,
  useGetPortraitLogsInfoQuery,
} = loggersApi;
export default loggersApi;
