import { useTokenPermissions } from './useTokenPermissions';

export const useHasPermissions = (
  requiredPermissions: string | string[],
  options?: { skip: boolean }
) => {
  const tokenPermissions = useTokenPermissions();

  if (options?.skip || !requiredPermissions) return true;

  const required = Array.isArray(requiredPermissions)
    ? requiredPermissions
    : [requiredPermissions];

  if (required.length === 0) return true;

  return required.every((req) => tokenPermissions.includes(req));
};
