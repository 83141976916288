import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import { TTableCell, TTableRow, TTableData } from "./types";
import TableContainer from "./TableContainer";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import TablePagination from "./TablePagination";
import DataNotFound from "../../components/DataNotFound";

type TTableProps = {
  data: TTableData;
  expandedId?: string | null;
  sortField?: string;
  sortOrder?: string;
  onSort?: (field: string) => void;
  localSort?: boolean;
  containerHeight?: string;
};

const Table = ({
  data,
  sortField,
  sortOrder,
  onSort,
  expandedId,
  localSort,
  containerHeight,
}: TTableProps) => {
  const sortedRows = [...data.rows];
  sortedRows.sort((a, b) => {
    const valueA = a.data[0]?.value || "";
    const valueB = b.data[0]?.value || "";
    if (valueA > valueB) return 1;
    if (valueA < valueB) return -1;
    return 0;
  });

  return (
    <TableContainer>
      <MuiTable stickyHeader>
        <TableHeader
          row={data.header}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={onSort}
          skipCols={data.optionalColumns || []}
        />
        {data.rows.length ? (
          <TableBody>
            {!localSort &&
              data.rows.map((row, index) => (
                <TableRow
                  key={row?.id || index}
                  data={row}
                  expanded={expandedId != null && expandedId === row?.id}
                  skipCols={data.optionalColumns || []}
                />
              ))}
            {localSort &&
              sortedRows.map((row, index) => (
                <TableRow
                  key={row?.id || index}
                  data={row}
                  expanded={expandedId != null && expandedId === row?.id}
                  skipCols={data.optionalColumns || []}
                />
              ))}
          </TableBody>
        ) : null}
      </MuiTable>
      {!data.rows.length && <DataNotFound height={containerHeight} />}
    </TableContainer>
  );
};

export default Table;
export type { TTableCell, TTableRow, TTableData };
export { TablePagination };
