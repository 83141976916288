import cn from "classnames";
import { useState, PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

const AccordionContainer = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: "100%",
  "&:before": {
    display: "none",
  },

  "&.Mui-expanded": {
    paddingBottom: theme.spacing(2),
  },

  "&.disable-collapse": {
    "& .MuiAccordionSummary-expandIconWrapper": {
      display: "none",
    },
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  minHeight: theme.spacing(5),
  padding: 0,
  flexDirection: "row-reverse",

  "& .MuiAccordionSummary-content": {
    margin: 0,
    justifyContent: "space-between",
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    padding: theme.spacing(1),
    transform: "rotate(-90deg)",

    "&.Mui-expanded": {
      transform: "rotate(0deg)",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: 0,
}));

type DetailsProgressBarProps = {
  title: string;
  message: string;
};
const DetailsProgressBar = ({ title, message }: DetailsProgressBarProps) => {
  return (
    <Tooltip title={message} placement="left" arrow>
      <Chip label={title} sx={{ fontWeight: "bold" }} />
    </Tooltip>
  );
};
type TDetailsBlockProps = {
  title: string;
  progressBar?: DetailsProgressBarProps;
  defaultCollapsed?: boolean;
  disableCollapse?: boolean;
  render?: () => React.ReactNode;
  accordionSummary?: React.ReactElement;
  onExpandedChanged?: (expanded: boolean) => void;
};

const DetailsBlock = ({
  title,
  progressBar,
  defaultCollapsed = false,
  disableCollapse = false,
  children,
  render,
  accordionSummary,
  onExpandedChanged,
}: PropsWithChildren<TDetailsBlockProps>) => {
  const [expanded, setExpanded] = useState<boolean>(!defaultCollapsed);
  const subtitle = title.includes(":")
    ? title.substring(title.indexOf(":") + 1, title.length)
    : "";

  return (
    <AccordionContainer
      className={cn({ "disable-collapse": disableCollapse })}
      expanded={expanded}
      onChange={() => {
        setExpanded(disableCollapse || !expanded);
        if (onExpandedChanged) onExpandedChanged(disableCollapse || !expanded);
      }}>
      <AccordionSummary>
        {accordionSummary && accordionSummary}
        {!accordionSummary && (
          <Stack flexDirection="row">
            <Typography fontWeight={500}>
              {title.replace(`:${subtitle}`, "")}
            </Typography>

            {subtitle && (
              <Typography
                display="inline"
                color="primary.main"
                fontWeight={500}
                ml={1}>
                {subtitle}
              </Typography>
            )}
          </Stack>
        )}
        {progressBar && (
          <DetailsProgressBar
            title={progressBar.title}
            message={progressBar.message}
          />
        )}
        {render && <>{render()}</>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionContainer>
  );
};
export default DetailsBlock;
