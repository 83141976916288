import React, { Suspense } from "react";

const Settings = React.lazy(() => import("./Settings"));

const LazySettings = () => (
  <Suspense fallback={<div />}>
    <Settings />
  </Suspense>
);

export default LazySettings;
