import React, { PropsWithChildren } from "react";
import cn from "classnames";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

export interface IPageProps {
  fullHeight?: boolean;
}

const PageContainer = styled(Stack)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(3),
  flexGrow: 1,
  flexShrink: 1,
  width: 100,
  maxHeight: "100vh",
  overflowY: "scroll",

  "&.fullHeight": {
    height: "100vh",
    overflowX: "scroll",
  },
}));

const Page = ({ fullHeight, children }: PropsWithChildren<IPageProps>) => (
  <PageContainer spacing={1} className={cn({ fullHeight })}>
    {children}
  </PageContainer>
);

export default Page;
