import cn from "classnames";
import { useState, useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import FileDropzone from "./FileDropzone";
import { TFileAttachment, FileInfo } from "../../../../types/file";
import FileAttachments from "./FileAttachments";
import { setAlert } from "../../../../redux/slices/alert";
import { useTranslation } from "react-i18next";

type FileUploadProps = {
  error: boolean;
  multiple: boolean;
  noBorder?: boolean;
  defaultValue: TFileAttachment[];
  onFileChange: (...event: any[]) => void;
};

const FileUploadContainer = styled(Stack)(({ theme }) => ({
  position: "relative",

  "&.noBorder": {
    width: "100%",
  },

  "&:hover": {
    ".fieldBorder": {
      borderColor: theme.palette.text.primary,

      "&.error": {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

const FieldBorder = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  border: "1px solid",
  borderColor: alpha("#000", 0.23),
  borderRadius: theme.shape.borderRadius,
  pointerEvents: "none",

  "&.error": {
    borderColor: theme.palette.error.main,
  },

  "&.noBorder": {
    display: "none",
  },
}));

const FileUpload = ({
  error,
  multiple,
  noBorder = false,
  defaultValue,
  onFileChange,
}: FileUploadProps) => {
  const { t } = useTranslation("components");
  const dispatch = useAppDispatch();
  const [attachments, setAttachments] =
    useState<Array<TFileAttachment>>(defaultValue);
  const lastIndex = Number(defaultValue[defaultValue?.length - 1]?.id) || 0;
  const [lastId, setLastId] = useState<number>(lastIndex);

  const handleFileLoaded = (id: string, loadedFile: FileInfo) => {
    setAttachments((prevVal) => {
      const index = prevVal.findIndex((elem) => elem.id === id);
      if (index < 0) return prevVal;
      return [
        ...prevVal.slice(0, index),
        { ...prevVal[index], loadedFile: loadedFile },
        ...prevVal.slice(index + 1),
      ];
    });
  };

  const handleFileUpload = (files: File[]) => {
    const filteredFiles = files
      .map((elem) => {
        if (elem.size >= 1000000) {
          dispatch(
            setAlert({
              open: true,
              message: t("I18N_UPLOAD_SIZEALERT"),
              variant: "filled",
            })
          );
        }
        return elem;
      })
      .filter((elem) => elem.size < 1000000);

    let maxId = -1;
    const uploadedAttachments = filteredFiles.map(
      (file, idx): TFileAttachment => {
        const id = lastId + idx + 1;
        if (id > maxId) maxId = id;
        return { id: id.toString(), file };
      }
    );
    setAttachments([...attachments, ...uploadedAttachments]);
    setLastId((pv) => Math.max(pv, maxId));
  };

  const handleFileRemove = (id: string) => {
    const filteredAttachments = attachments.filter((elem) => elem.id !== id);
    setAttachments(filteredAttachments);
  };

  useEffect(() => {
    const loadedFiles: FileInfo[] = attachments
      .filter((elem) => !!elem.loadedFile)
      .map((elem) => ({
        ...(elem.loadedFile as FileInfo),
        name: elem.file.name,
      }));
    if (loadedFiles.length > 0) {
      onFileChange(multiple ? loadedFiles : loadedFiles[0]);
    }
  }, [attachments]);

  return (
    <FileUploadContainer className={cn({ noBorder })}>
      <FileAttachments
        attachments={attachments}
        multiple={multiple}
        onFileLoaded={handleFileLoaded}
        onFileRemove={handleFileRemove}
      />
      <FileDropzone
        multiple={multiple}
        hasFiles={attachments.length > 0}
        onFileUpload={handleFileUpload}
      />
      <FieldBorder className={cn("fieldBorder", { error, noBorder })} />
    </FileUploadContainer>
  );
};
export default FileUpload;
