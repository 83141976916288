import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGetDictTypeByIdQuery } from "../../services/dictionaries";
import { useGetDictTypeListQuery } from "../../services/dictType";
import { FieldProps } from "../../types/fields";

interface DropdownProps extends FieldProps {
  parent?: string;
  child?: string;
}
type TProductType = {
  code: string;
  name: string;
};
type TFormattedOrgs = {
  [key: string]: { code: string; productTypes: TProductType[] };
};

const DropdownList: React.FC<DropdownProps> = ({
  label,
  field,
  parent = "",
  child = "",
}) => {
  const { t, i18n } = useTranslation("components");
  const {
    formState: { errors, isValid, submitCount },
    setValue,
    getValues,
  } = useFormContext();
  const [parentOptions, setParentOptions] = useState<string[]>([]);
  const [childOptions, setChildOptions] = useState<string[]>([]);
  const [dataList, setDataList] = useState<TFormattedOrgs>({});
  const [typeId, setTypeId] = useState("");
  const watch = useWatch({ name: parent });
  const orgCode: string = useWatch({ name: "codeOrgName" });
  const ptCode: string = useWatch({ name: "finishedProductTypeCode" });
  const { data: dictType } = useGetDictTypeListQuery();
  const { data, isSuccess } = useGetDictTypeByIdQuery(
    { id: typeId },
    { skip: !typeId }
  );

  const lang = i18n.language.toLocaleUpperCase();

  useEffect(() => {
    if (dictType?.length) {
      const orgId = dictType.find((type) =>
        type.name.toLowerCase().includes("дзо")
      ).id;
      setTypeId(orgId);
    }
  }, [dictType]);

  useEffect(() => {
    if (isSuccess) {
      const formattedData = {};
      data.forEach((dict) => {
        const orgName = dict[`name${lang}`];
        const newOrgs = {
          code: dict.code,
          productTypes: dict.productTypes.map((pt) => ({
            code: pt.code,
            name: pt[`name${lang}`],
          })),
        };
        formattedData[orgName] = newOrgs;
      });
      setDataList(formattedData);
      setParentOptions(
        Object.keys(formattedData).filter((org) => org !== "UNKNOWN_SAMPLE")
      );
    }
  }, [isSuccess, i18n.language]);

  useEffect(() => {
    if (orgCode && ptCode && dataList) {
      const orgName = Object.keys(dataList).find(
        (key) => dataList[key].code === orgCode
      ) as string;

      const productType = dataList[orgName]?.productTypes.find(
        (pt) => pt.code === ptCode
      )?.name as string;

      setValue("orgName", orgName);
      setValue("finishedProductType", productType);
    }
  }, [orgCode, ptCode, dataList]);

  useEffect(() => {
    if (childOptions.includes(field.value)) {
      const orgs = dataList[watch];
      const prodType = orgs.productTypes.find(
        (pt) => pt.name === field.value
      ) as TProductType;

      setValue("finishedProductTypeCode", prodType.code);
      return;
    }

    if (parentOptions.includes(field.value)) {
      let valueToSet: TProductType | null = null;
      const orgs = dataList[field.value];
      setValue("codeOrgName", orgs.code);

      const prodType = orgs.productTypes.find(
        (pt) => pt.name === getValues(child)
      ) as TProductType;
      const index = orgs.productTypes.indexOf(prodType);
      valueToSet = orgs.productTypes[index < 0 ? 0 : index];

      if (!field.value && child) valueToSet = {} as TProductType;

      if (valueToSet != null) {
        setValue(child, valueToSet.name || "", {
          shouldValidate: !isValid && submitCount > 0,
        });

        setValue("finishedProductTypeCode", valueToSet.code);
      }
    }
  }, [field.value]);

  useEffect(() => {
    if (parent) {
      const ptNames = dataList[watch]?.productTypes.map((pt) => pt.name);
      setChildOptions(ptNames || []);
    }
  });

  const handleValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    field.onChange(value);
  };

  return (
    <Autocomplete
      options={parent ? childOptions : parentOptions}
      onChange={handleValueChange}
      value={field.value}
      noOptionsText={t("I18N_DROPDOWN_NO_OPTIONS")}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required
          error={Boolean(errors[field.name])}
          helperText={errors[field.name]?.message?.toString()}
        />
      )}
    />
  );
};

export default DropdownList;
