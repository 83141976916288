import React, { Suspense } from "react";

const PortraitList = React.lazy(() => import("./PortraitList"));

const LazyPortraitList = () => (
  <Suspense fallback={<div />}>
    <PortraitList />
  </Suspense>
);

export default LazyPortraitList;
