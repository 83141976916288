import { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import BusinessIcon from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import { TitleContainer } from './style';

type ToolTipProps = {
  title: string;
  id: string;
  children: ReactElement;
};
const ToolTip = ({ title, id, children }: ToolTipProps) => {
  const theme = useTheme();
  return (
    <TitleContainer color={theme.palette.primary.main}>
      <Stack spacing={1} alignItems="center" direction="row">
        <Tooltip title={title}>{children}</Tooltip>
        <Stack>{id}</Stack>
      </Stack>
    </TitleContainer>
  );
};

type TitleTooltipProps = {
  type: string;
  id: string;
};
const TitleTooltip: React.FC<TitleTooltipProps> = (props) => {
  const { t } = useTranslation('samples');
  switch (props.type) {
    case 'K':
      return (
        <ToolTip title={t('I18N_SAMPLE_KAP_TITLE')} id={props.id}>
          <BusinessIcon color="success" sx={{ opacity: 0.9 }} />
        </ToolTip>
      );
    case 'R':
    case 'E':
      return (
        <ToolTip title={t('I18N_SAMPLE_EXTERNAL_SAMPLE_TITLE')} id={props.id}>
          <NotListedLocationIcon color="warning" sx={{ opacity: 0.9 }} />
        </ToolTip>
      );
    default:
      return <>{props.id}</>;
  }
};

export default TitleTooltip;
