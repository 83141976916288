import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ModelCreator from "../../../../components/ModelCreator";
import { IStateHistory } from "types/states";
import { getTimeDate } from "../../../../utils/dates";
import { ValueRow } from "../../../../components/DetailsView/DetailField";
import { TStatusColor } from "../../../../hooks/statuses/types";

const OppositeContent = styled(TimelineOppositeContent)(({ theme }) => ({
  margin: "auto 0",
  color: theme.palette.text.secondary,
}));
type TStateControllerTimeLine = {
  color: TStatusColor;
  label: string;
  icon: ReactNode;
  history: IStateHistory;
};
const StateControllerTimeLine = ({
  color,
  label,
  icon,
  history,
}: TStateControllerTimeLine) => {
  const { t } = useTranslation("statuses");
  return (
    <TimelineItem>
      <OppositeContent align="right" variant="body2">
        {getTimeDate(history.when)}
      </OppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: `${color}.main` }} />
        <TimelineDot color={color === "default" ? "grey" : color}>
          {icon}
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: `${color}.main` }} />
      </TimelineSeparator>
      <TimelineContent p={2}>
        <Typography color={`${color}.main`} variant="h6" component="span">
          {t(label, { ns: "statuses" }) as string}
        </Typography>
        <ModelCreator creatorId={history.user_id} />
        {history.consumed && <ValueRow>{history.consumed}</ValueRow>}
        {history.quantityAfter && <ValueRow>{history.quantityAfter}</ValueRow>}
        {history.descr && <ValueRow>{history.descr}</ValueRow>}
      </TimelineContent>
    </TimelineItem>
  );
};

export default StateControllerTimeLine;
