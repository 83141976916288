import React, { Suspense } from 'react';

const StateControllers = React.lazy(() => import('./StateControllers'));

const LazyStateControllers = () => (
  <Suspense fallback={<div />}>
    <StateControllers />
  </Suspense>
);

export default LazyStateControllers;
