import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const BreadcrumbTitle = styled(Typography)(({ theme }) => ({
  margin: 0,
  flexGrow: 0,
  flexShrink: 0,
  lineHeight: 2,
  whiteSpace: 'nowrap',
  overflow: 'ellipsis',
  color: theme.palette.text.disabled,

  a: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  }
}));

export default BreadcrumbTitle;

