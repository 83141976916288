import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

const StyledPrompt = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiDialogContentText-root': {
    color: '#000',
  },
  '& .MuiDialogActions-root': {
    padding: 0,
    marginTop: theme.spacing(3),
    justifyContent: 'left',
  },
}));
export default StyledPrompt;
