import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ISample,
  ICreateSample,
  TSampleStatus,
  ISampleHistory,
} from "../types/samples";
import { IPaginatedList, IPaginatedListParams } from "../types/paginated";
import baseAppQuery from "./baseAppQuery";
import transformPaginatedResponse from "./transformPaginatedResponse";
import { IState } from "../types/states";
import { TSampleFilterParams } from "../hooks/useSamplesFilters/types";

const ENDPOINT = "samples";

export const samplesApi: any = createApi({
  reducerPath: "samplesApi",
  tagTypes: ["samples"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getSamplesList: builder.query<
      IPaginatedList<ISample>,
      TSampleFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const queryParams = new URLSearchParams();
        queryParams.set("pageNo", qp.pageNo.toString());
        queryParams.set("pageSize", qp.pageSize.toString());
        if (qp.type) queryParams.set("type", qp.type);
        if (qp.source) queryParams.set("codeOrgName", qp.source);
        if (qp.product) queryParams.set("finishedProductTypeCode", qp.product);
        if (qp.from) queryParams.set("from", qp.from);
        if (qp.to) queryParams.set("to", qp.to);
        if (qp.sort) {
          queryParams.set("sortOrder", qp.sort.order);
          queryParams.set("sortBy", qp.sort.field);
        }
        if (qp.state) queryParams.set("status", qp.state?.join());

        return `${ENDPOINT}?${queryParams.toString()}`;
      },
      transformResponse: transformPaginatedResponse,
    }),
    getSampleById: builder.query<ISample, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
    }),

    addSample: builder.mutation<ISample, ICreateSample>({
      query: (sample: ICreateSample) => ({
        url: ENDPOINT,
        method: "POST",
        body: sample,
      }),
    }),
    updateSample: builder.mutation<ISample, ISample>({
      query: (data: ISample) => ({
        url: ENDPOINT,
        method: "PUT",
        body: data,
      }),
    }),
    deleteSample: builder.mutation<null, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
    }),
    updateSampleState: builder.mutation<
      null,
      { id: string; state: TSampleStatus; consumed: string; desc: string }
    >({
      query: ({ id, state, consumed, desc }) => {
        const utilizedDesc = state === "UTILIZED" ? desc : "";
        return {
          url: `${ENDPOINT}/change/${id}?status=${state}&consumed=${consumed}`,
          method: "PUT",
          body: utilizedDesc,
        };
      },
    }),
    getSampleStateMap: builder.query<IState, IState>({
      query: () => `${ENDPOINT}/state-map`,
    }),
    updateSampleStateMap: builder.mutation<IState, IState>({
      query: () => ({
        url: `${ENDPOINT}/state-map`,
        method: "PUT",
      }),
    }),
    getSampleHistoryById: builder.query<ISampleHistory, string>({
      query: (id) => `${ENDPOINT}/history/${id}`,
    }),
  }),
});

export const {
  useGetSamplesListQuery,
  useGetSampleByIdQuery,
  useLazyGetSampleByIdQuery,
  useAddSampleMutation,
  useUpdateSampleMutation,
  useDeleteSampleMutation,
  useUpdateSampleStateMutation,
  useGetSampleStateMapQuery,
  useUpdateSampleStateMapMutation,
  useGetSampleHistoryByIdQuery,
} = samplesApi;
export default samplesApi;
