enum EModel {
  CONTROL = "control_model",
  APPROVE = "approve_model",
  CREATE = "create_model",
  READ = "read_model",
  UPDATE = "update_model",
}
enum EPGP {
  CONTROL = "control_pgp_state",
  CREATE = "create_pgp",
  READ = "read_pgp",
  UPDATE = "update_pgp",
}
enum EResearch {
  ASSIGN = "assign_research",
  CONTROL = "control_research",
  READ = "read_research",
  UPDATE = "update_research",
}

enum EResearchPlan {
  CONTROL = "control_research_plan",
  READ = "read_research_plan",
  UPDATE = "update_research_plan",
}

enum ESample {
  APPROVE = "approve_sample",
  CREATE = "create_sample",
  READ = "read_sample",
  RESEARCH = "research_sample",
  UPDATE = "update_sample",
  UPDATE_IN_STORAGE = "update_stored_sample",
  UTILIZE = "utilize_sample",
  CONTROL = "control_sample",
}

enum ETool {
  CONTROL = "control_tool",
  READ = "read_tool",
}
enum EUser {
  CONTROL = "control_users",
}
enum ERole {
  CONTROL = "control_roles",
}
enum EPermission {
  CONTROL = "control_permissions",
}
enum EState {
  CONTROL = "control_states",
}
enum EDict {
  CONTROL = "control_dicts",
}
enum EActive {
  CONTROL = "control_actives",
}
enum ESystem {
  CONTROL = "control_system",
}
export type TPermission = {
  Model: typeof EModel;
  PGP: typeof EPGP;
  Research: typeof EResearch;
  ResearchPlan: typeof EResearchPlan;
  Sample: typeof ESample;
  User: typeof EUser;
  Role: typeof ERole;
  Permission: typeof EPermission;
  Tool: typeof ETool;
  State: typeof EState;
  Dict: typeof EDict;
  Active: typeof EActive;
  System: typeof ESystem;
};

export default {
  Model: EModel,
  PGP: EPGP,
  Research: EResearch,
  ResearchPlan: EResearchPlan,
  Sample: ESample,
  Tool: ETool,
  User: EUser,
  Role: ERole,
  Permission: EPermission,
  State: EState,
  Dict: EDict,
  Active: EActive,
  System: ESystem,
} as TPermission;
