import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DropdownList from './DropdownList';
import FileField from './FileField';
import DateField from './DateField';
import SelectField from './SelectField';
import { FieldTypes } from '../../types/fields';
import ToolsList from '../../components/Inputs/ToolsList';
import Autocomplete from './BasicAutocomplete';
import { TSelectOptions, TSelectOption } from '../../types/fields';

type FieldsGeneratorProps = {
  fieldList: FieldTypes[];
  locales: string;
  selectOptions?: TSelectOptions;
  isEdit?: boolean;
  fullWidth?: boolean;
};

const FieldsGenerator = ({
  fieldList,
  locales,
  selectOptions = {},
  isEdit = false,
  fullWidth = false,
}: FieldsGeneratorProps) => {
  const { t } = useTranslation([locales, 'components']);
  const invalidText = t('I18N_NUMBER_NEGATIVE');
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Grid item container xs={12} xl={fullWidth ? 0 : 9} spacing={3}>
      {fieldList.map((elem) => (
        <Controller
          key={elem.name}
          defaultValue={''}
          name={elem.name}
          control={control}
          rules={{
            required: {
              value: elem.required,
              message: t('I18N_REQUIRED_FIELD', { ns: 'components' }),
            },
            validate: (value) => {
              if (elem.type === 'number') {
                return parseFloat(value) > 0 || invalidText;
              }
            },
          }}
          render={({ field }) => (
            <Grid item xs={12} md={elem.fullWidth ? 0 : 6}>
              {elem.type === 'date' ? (
                <DateField label={t(elem.label)} field={field} />
              ) : elem.type === 'dropdown' ? (
                <DropdownList
                  label={t(elem.label)}
                  field={field}
                  parent={elem.parent}
                  child={elem.child}
                />
              ) : elem.type === 'file' ? (
                <FileField
                  label={t(elem.label)}
                  field={field}
                  forEditableForm={isEdit}
                  required={elem.required}
                  multiple={elem.multiple || false}
                  error={Boolean(errors[elem.name])}
                  helperText={errors[elem.name]?.message?.toString()}
                />
              ) : elem.type === 'select' ? (
                <SelectField
                  label={t(elem.label)}
                  locales={locales}
                  field={field}
                  options={selectOptions[elem.name] as TSelectOption[]}
                  error={Boolean(errors[elem.name])}
                  helperText={errors[elem.name]?.message?.toString()}
                />
              ) : elem.type === 'toolsList' ? (
                <ToolsList
                  label={t(elem.label)}
                  field={field}
                  error={Boolean(errors[elem.name])}
                  helperText={errors[elem.name]?.message?.toString()}
                />
              ) : elem.type === 'autocomplete' ? (
                <Autocomplete
                  label={t(elem.label)}
                  field={field}
                  multiple={elem.multiple || false}
                  disableClearable={elem.disableClearable || false}
                  options={selectOptions[field.name]}
                  error={Boolean(errors[elem.name])}
                  helperText={errors[elem.name]?.message?.toString()}
                />
              ) : (
                <TextField
                  {...field}
                  label={t(elem.label)}
                  required={elem.required}
                  type={elem.type}
                  fullWidth
                  multiline={elem.multiline || false}
                  error={Boolean(errors[elem.name])}
                  helperText={errors[elem.name]?.message?.toString()}
                  inputProps={{ min: '0' }}
                />
              )}
            </Grid>
          )}
        />
      ))}
    </Grid>
  );
};
export default FieldsGenerator;
