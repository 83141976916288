import React, { Suspense } from 'react';

const Dictionaries = React.lazy(() => import('./Dictionaries'));

const LazyDictionaries = () => (
  <Suspense fallback={<div />}>
    <Dictionaries />
  </Suspense>
);

export default LazyDictionaries;
