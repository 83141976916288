import { createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
import { DEFAULT, getTokenState } from "./tokenState";
import { IToken } from "../../../types/token";
import tokenApi from "../../../services/token";
import { IAuthState } from "./types";

const clearTokenStateReducer = (state: IAuthState) => {
  Object.keys(DEFAULT).forEach((key) => {
    state[key] = DEFAULT[key];
  });
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};

const writeTokenReducer = (
  state: IAuthState,
  { payload }: { payload: IToken }
) => {
  localStorage.setItem('accessToken', payload.access_token);
  localStorage.setItem('refreshToken', payload.refresh_token);
  const token = getTokenState(payload.access_token);
  Object.keys(token).forEach((key) => {
    state[key] = token[key];
  });
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearTokenState: clearTokenStateReducer,
    writeToken: writeTokenReducer,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(tokenApi.endpoints.login.matchFulfilled, writeTokenReducer)
      .addMatcher(tokenApi.endpoints.refresh.matchFulfilled, writeTokenReducer)
      .addMatcher(
        tokenApi.endpoints.logout.matchFulfilled,
        clearTokenStateReducer
      );
  },
});

const authReducer = slice.reducer;

export { authReducer };
