import { styled } from "@mui/material/styles";
import MuiTableContainer from "@mui/material/TableContainer";

const TableContainer = styled(MuiTableContainer)(({ theme }) => ({
  height: "calc(100% - 110px)",
  [theme.breakpoints.up("sm")]: {
    height: "calc(100% - 72px)",
  },
}));

export default TableContainer;
