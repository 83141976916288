import "dayjs/locale/ru";
import "dayjs/locale/kk";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import i18next from "i18next";
import {
  PickersCalendarHeader,
  PickersCalendarHeaderProps,
} from "@mui/x-date-pickers/PickersCalendarHeader";
import { styled } from "@mui/material/styles";

import { FieldProps } from "../../types/fields";

const PickersCalendarDraft = styled(
  (props: PickersCalendarHeaderProps<Date>) => (
    <PickersCalendarHeader {...props} />
  )
)(() => ({
  textTransform: "capitalize"
}));

const DateField = ({ label, field }: FieldProps) => (
  <LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale={i18next.language}
  >
    <DatePicker
      sx={{
        width: "100%",
      }}
      yearsPerRow={4}
      dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase() + day.slice(1)}
      displayWeekNumber={true}
      label={label}
      value={field.value}
      onChange={field.onChange}
      slots={{
        calendarHeader: PickersCalendarDraft,
      }}
    />
  </LocalizationProvider>
);
export default DateField;
