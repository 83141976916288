import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

type TSelectFieldProps = {
  filterType: string;
  value?: string;
  id: string;
  label: string;
  onUpdate: (type: string, value: string, label: string) => void;
  onDelete: (type: string) => void;
};

const TextStringField = ({
  id,
  filterType,
  value,
  label,
  onUpdate,
  onDelete,
}: TSelectFieldProps) => {
  const [inputVal, setInputVal] = useState<string>(value || "");
  const handleChange = (event) => {
    setInputVal(event.target.value);
  };

  useEffect(() => {
    if (!value) {
      setInputVal("");
    }
  }, [value]);

  return (
    <Stack direction="row" spacing={1}>
      <FormControl fullWidth size="small">
        <TextField
          size="small"
          label={label}
          variant="outlined"
          id={id}
          value={inputVal}
          onChange={handleChange}
        />
      </FormControl>
      <IconButton
        color="info"
        onClick={() => onUpdate(filterType, inputVal, inputVal)}
        disabled={!inputVal}>
        <SearchIcon />
      </IconButton>
      <IconButton
        color="error"
        disabled={!value}
        onClick={() => onDelete(filterType)}>
        <FilterAltOffIcon />
      </IconButton>
    </Stack>
  );
};

export default TextStringField;
