import React, { Suspense } from 'react';
const ResearchEditWrapper = React.lazy(() => import('./ResearchEditWrapper'));

const LazyResearchEdit = () => (
  <Suspense fallback={<div />}>
    <ResearchEditWrapper />
  </Suspense>
);

export default LazyResearchEdit;
