import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AutocompleteField from "../../../../../../../components/FilterFields/AutocompleteField";
import TextStringField from "../../../../../../../components/FilterFields/TextField";
import DateRangeField from "../../../../../../../components/FilterFields/DateRangeField";

import {
  TLoggerFilters,
  TLoggerFilterMethods,
} from "hooks/useLoggerFilter/types";

type TFilterSelectProps = {
  anchorEl: HTMLButtonElement | null;
  filters: TLoggerFilters;
  methods: TLoggerFilterMethods;
  onClose: () => void;
};

const FilterSelectContainer = styled(Stack)(({ theme }) => ({
  width: theme.spacing(80),
  padding: theme.spacing(2),
}));

const FilterSelect = ({
  anchorEl,
  filters,
  methods,
  onClose,
}: TFilterSelectProps) => {
  const { t } = useTranslation("loggers");
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{ mt: 2 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}>
      <FilterSelectContainer spacing={2}>
        <TextStringField
          id="select-filter-type"
          filterType="remote_ip"
          label={t("I18N_LOGGER_REMOTE_IP")}
          value={filters.remote_ip.value?.value}
          onUpdate={methods.updateFilter}
          onDelete={methods.deleteFilter}
        />
        <AutocompleteField
          id="select-filter-source"
          filterType="userid"
          label={t("I18N_LOGGER_USER")}
          value={filters.userid.value?.value}
          options={filters.userid.options}
          onUpdate={methods.updateFilter}
          onDelete={methods.deleteFilter}
        />
        <AutocompleteField
          id="select-filter-source"
          filterType="operation"
          label={t("I18N_LOGGER_OPERATION")}
          value={filters.operation.value?.value}
          options={filters.operation.options}
          onUpdate={methods.updateFilter}
          onDelete={methods.deleteFilter}
        />
        <AutocompleteField
          id="select-filter-product"
          filterType="status"
          label={t("I18N_LOGGER_STATUS_CODE")}
          value={filters.status.value?.value}
          options={filters.status.options}
          onUpdate={methods.updateFilter}
          onDelete={methods.deleteFilter}
        />
        <DateRangeField
          format="YYYY-MM-DD"
          lable="I18N_PORTRAIT_FILTER_DATE_FROM"
          valueFrom={filters.from.value?.value}
          valueTo={filters.to.value?.value}
          onUpdate={methods.updateFilter}
          onDelete={methods.deleteFilter}
        />
      </FilterSelectContainer>
    </Popover>
  );
};

export default FilterSelect;
