import React, { Suspense } from 'react';

const ProductTypes = React.lazy(() => import('./ProductTypes'));

const LazyProductTypes = () => (
  <Suspense fallback={<div />}>
    <ProductTypes />
  </Suspense>
);

export default LazyProductTypes;
