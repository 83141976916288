import React, { PropsWithChildren } from "react";
import { useHasPermissions } from "../hooks/useHasPermissions";

export interface IRestrictedFeatureProps {
  permissions: string | string[];
}

const RestrictedFeature = ({
  permissions,
  children,
}: PropsWithChildren<IRestrictedFeatureProps>) => {
  const hasPermission = useHasPermissions(permissions);
  if (!hasPermission) return null;
  return <>{children}</>;
};

export default RestrictedFeature;
