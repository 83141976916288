import React, { Suspense } from 'react';
const ResearchAssignWrapper = React.lazy(
  () => import('./ResearchAssignWrapper')
);

const LazyResearchAssign = () => (
  <Suspense fallback={<div />}>
    <ResearchAssignWrapper />
  </Suspense>
);

export default LazyResearchAssign;
