export enum ETokenState {
  EXPIRED,
  WILL_EXPIRE,
  VALID,
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface IResetPassword {
  code: string;
  email: string;
  password: string;
  confirm_password: string;
}

export interface IToken {
  access_token: string;
  refresh_token: string;
}

export interface IBaseTokenData {
  exp: number;
  iat: number;
  nbf: number;
  sub: string;
}

export interface IRefreshTokenData extends IBaseTokenData {
  typ: "Refresh";
}

export interface IAccessTokenData extends IBaseTokenData {
  user_name: string;
  typ: "Bearer";
  roles: string[];
  permissions: string[];
  iss: string;
  jti: string;
  email: string;
}

export type TTokenData = IAccessTokenData | IRefreshTokenData;
