import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useGetToolsListQuery } from '../../services/tools';
import { useEffect, useState } from 'react';
import { ITool } from '../../types/markers';
import { FieldProps } from '../../types/fields';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type ToolsFieldProps = FieldProps & {
  error: boolean;
  helperText: React.ReactNode;
};

const ToolsList: React.FC<ToolsFieldProps> = ({
  label,
  field,
  error,
  helperText,
}) => {
  const { data: tools, isSuccess } = useGetToolsListQuery('');
  const [selectedTools, setSelectedTools] = useState<ITool[]>([]);
  const { t } = useTranslation('components');
  const toolsList = isSuccess ? tools : [];
  const handleValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ITool[]
  ) => {
    setSelectedTools(value);
    if (value.length === 0) {
      field.onChange(null);
    } else {
      field.onChange(value);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedTools(Array.isArray(field.value) ? field.value : []);
    }
  }, [isSuccess, field.value]);

  return (
    <Autocomplete
      multiple
      id={field.name}
      options={toolsList}
      value={selectedTools}
      disableCloseOnSelect
      onChange={handleValueChange}
      noOptionsText={t('I18N_DROPDOWN_NO_OPTIONS')}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          error={error}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default ToolsList;
