import ScienceIcon from "@mui/icons-material/Science";
import BiotechIcon from "@mui/icons-material/Biotech";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import InventoryIcon from "@mui/icons-material/Inventory";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import GroupIcon from "@mui/icons-material/Group";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ArticleIcon from "@mui/icons-material/Article";
import DescriptionIcon from "@mui/icons-material/Description";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import HandymanIcon from "@mui/icons-material/Handyman";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Models from "../pages/ModelsBlock/Models";
import ModelsView from "../pages/ModelsBlock/ModelsView";
import ModelsCreate from "../pages/ModelsBlock/ModelsCreate";
import Samples from "../pages/SamplesBlock/Samples";
import SampleCreate from "../pages/SamplesBlock/SampleCreateEdit/SampleCreate";
import SampleEdit from "../pages/SamplesBlock/SampleCreateEdit/SampleEdit";
import SampleView from "../pages/SamplesBlock/SampleView";
import Researches from "../pages/ResearchesBlock/Researches";
import ResearchEdit from "../pages/ResearchesBlock/ResearchAssignEdit/ResearchEdit";
import ResearchAssign from "../pages/ResearchesBlock/ResearchAssignEdit/ResearchAssign";
import ResearchForm from "../pages/ResearchesBlock/ResearchForm";
import ResearchView from "../pages/ResearchesBlock/ResearchView";
import PortraitList from "../pages/PortraitBlock/PortraitList";
import PortraitForm from "../pages/PortraitBlock/PortraitForm";
import Users from "../pages/AdminBlock/UsersBlock/Users";
import UserCreate from "../pages/AdminBlock/UsersBlock/UserCreate/UserCreate";
import Permissions from "../pages/AdminBlock/PermissionsBlock/Permissions";
import PermissionCreate from "../pages/AdminBlock/PermissionsBlock/PermissionCreate";
import Roles from "../pages/AdminBlock/RolesBlock/Roles";
import RoleCreate from "../pages/AdminBlock/RolesBlock/RolesCreate/RoleCreate";
import Dictionaries from "../pages/AdminBlock/DictionariesBlock/Dictionaries";
import DictionaryCreate from "../pages/AdminBlock/DictionariesBlock/DictionaryCreate";
import Types from "../pages/AdminBlock/TypesBlock/Types";
import TypeCreate from "../pages/AdminBlock/TypesBlock/TypeCreate/TypeCreate";
import ProductTypes from "../pages/AdminBlock/ProductTypesBlock/ProductTypes";
import ProductTypeCreate from "../pages/AdminBlock/ProductTypesBlock/ProductTypeCreate";
import StateMaps from "../pages/AdminBlock/StateMapsBlock/StateMaps";
import StateControllers from "../pages/AdminBlock/StateControllersBlock/StateControllers";
import StateControllerView from "../pages/AdminBlock/StateControllersBlock/StateControllerView/StateControllerView";
import ToolsCreate from "../pages/AdminBlock/ToolsBlock/ToolsCreate/ToolsCreate";
import Tools from "../pages/AdminBlock/ToolsBlock/Tools/Tools";
import Permission from "../config/Permission";
import ServiceMode from "../pages/AdminBlock/ServiceBlock/Service/ServiceMode";
import SystemLogs from "../pages/AdminBlock/SystemLogsBlock/SystemLogs/SystemLogs";

export type TPathEntry = {
  allowedPermissions: string[];
  component: React.FunctionComponent<object>;
  icon?: React.FunctionComponent<object>;
  name?: string;
  path: string;
  children?: Array<TPathEntry>;
};

export type TPathBlock = {
  name: string;
  shortName: string;
  children: Array<TPathEntry>;
};

const PATHS: Array<TPathBlock> = [
  {
    name: "I18N_ACCOUNT_MANAGEMENT_NAME",
    shortName: "I18N_ACCOUNT_MANAGEMENT_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.User.CONTROL],
        component: Users,
        icon: GroupIcon,
        name: "I18N_NAV_USERS",
        path: "/users",
        children: [
          {
            allowedPermissions: [Permission.User.CONTROL],
            component: UserCreate,
            path: "/users/create",
          },
          {
            allowedPermissions: [Permission.User.CONTROL],
            component: UserCreate,
            path: "/users/edit/:userId",
          },
        ],
      },
      {
        allowedPermissions: [Permission.Role.CONTROL],
        component: Roles,
        icon: LockPersonIcon,
        name: "I18N_NAV_ROLES",
        path: "/roles",
        children: [
          {
            allowedPermissions: [Permission.Role.CONTROL],
            component: RoleCreate,
            path: "/roles/create",
          },
          {
            allowedPermissions: [Permission.Role.CONTROL],
            component: RoleCreate,
            path: "/roles/edit/:roleId",
          },
        ],
      },
      {
        allowedPermissions: [Permission.Permission.CONTROL],
        component: Permissions,
        icon: VpnKeyIcon,
        name: "I18N_NAV_PERMISSIONS",
        path: "/permissions",
        children: [
          {
            allowedPermissions: [Permission.Permission.CONTROL],
            component: PermissionCreate,
            path: "/permissions/create",
          },
          {
            allowedPermissions: [Permission.Permission.CONTROL],
            component: PermissionCreate,
            path: "/permissions/edit/:permissionId",
          },
        ],
      },
    ],
  },
  {
    name: "I18N_DICTIONARIES_BLOCK_NAME",
    shortName: "I18N_DICTIONARIES_BLOCK_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.Dict.CONTROL],
        component: Dictionaries,
        icon: AutoStoriesIcon,
        name: "I18N_NAV_DICTS",
        path: "/dictionaries",
        children: [
          {
            allowedPermissions: [Permission.Dict.CONTROL],
            component: DictionaryCreate,
            path: "/dictionaries/create",
          },
          {
            allowedPermissions: [Permission.Dict.CONTROL],
            component: DictionaryCreate,
            path: "/dictionaries/edit/:dictId",
          },
        ],
      },
      {
        allowedPermissions: [Permission.Dict.CONTROL],
        component: Types,
        icon: DescriptionIcon,
        name: "I18N_NAV_TYPES",
        path: "/types",
        children: [
          {
            allowedPermissions: [Permission.Dict.CONTROL],
            component: TypeCreate,
            path: "/types/create",
          },
          {
            allowedPermissions: [Permission.Dict.CONTROL],
            component: TypeCreate,
            path: "/types/edit/:typeId",
          },
        ],
      },
      {
        allowedPermissions: [Permission.Dict.CONTROL],
        component: ProductTypes,
        icon: ArticleIcon,
        name: "I18N_NAV_PRODUCT_TYPES",
        path: "/product-types",
        children: [
          {
            allowedPermissions: [Permission.Dict.CONTROL],
            component: ProductTypeCreate,
            path: "/product-types/create",
          },
          {
            allowedPermissions: [Permission.Dict.CONTROL],
            component: ProductTypeCreate,
            path: "/product-types/edit/:productId",
          },
        ],
      },
    ],
  },
  {
    name: "I18N_STATES_BLOCK_NAME",
    shortName: "I18N_STATES_BLOCK_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.State.CONTROL],
        component: StateMaps,
        icon: MoveToInboxIcon,
        name: "I18N_NAV_STATE_MAPS",
        path: "/state-map",
        children: [],
      },
      {
        allowedPermissions: [Permission.State.CONTROL],
        component: StateControllers,
        icon: ScheduleIcon,
        name: "I18N_NAV_STATE_CONTROLS",
        path: "/state-control",
        children: [
          {
            allowedPermissions: [Permission.State.CONTROL],
            component: StateControllerView,
            path: "/state-control/view/:active/:activeId1?/:activeId2",
          },
        ],
      },
    ],
  },
  {
    name: "I18N_PERSON_MANEGE_BLOCK_NAME",
    shortName: "I18N_PERSON_MANEGE_BLOCK_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.Tool.CONTROL],
        component: Tools,
        icon: HandymanIcon,
        name: "I18N_NAV_TOOLS",
        path: "/tools",
        children: [
          {
            allowedPermissions: [],
            component: ToolsCreate,
            path: "/tools/create",
          },
          {
            allowedPermissions: [],
            component: ToolsCreate,
            path: "/tools/edit/:toolId",
          },
        ],
      },
    ],
  },
  {
    name: "I18N_SYSTEM_CONTROL_BLOCK_NAME",
    shortName: "I18N_SYSTEM_CONTROL_BLOCK_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.System.CONTROL],
        component: SystemLogs,
        icon: SummarizeIcon,
        name: "I18N_NAV_SYSTEM_LOGS",
        path: "/system-logs",
        children: [],
      },
      {
        allowedPermissions: [Permission.System.CONTROL],
        component: ServiceMode,
        icon: HomeRepairServiceIcon,
        name: "I18N_NAV_SERVICE_MODE",
        path: "/service-mode",
        children: [],
      },
    ],
  },
  {
    name: "I18N_SAMPLES_BLOCK_NAME",
    shortName: "I18N_SAMPLES_BLOCK_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.Active.CONTROL],
        component: Samples,
        icon: ScienceIcon,
        name: "I18N_NAV_SAMPLES",
        path: "/samples",
        children: [
          {
            allowedPermissions: [Permission.Sample.CREATE],
            component: SampleCreate,
            path: "/samples/create",
          },
          {
            allowedPermissions: [Permission.Sample.READ],
            component: SampleView,
            path: "/samples/view/:sampleId",
            children: [
              {
                allowedPermissions: [Permission.Sample.UPDATE],
                component: SampleEdit,
                path: "/samples/edit/:sampleId",
              },
            ],
          },
        ],
      },
      {
        allowedPermissions: [Permission.Active.CONTROL],
        component: Researches,
        icon: BiotechIcon,
        name: "I18N_NAV_RESEARCHES",
        path: "/research-plan",
        children: [
          {
            allowedPermissions: [
              Permission.Sample.READ,
              Permission.ResearchPlan.CONTROL,
            ],
            component: ResearchAssign,
            path: "/research-plan/assign/:sampleId",
          },
          {
            allowedPermissions: [
              Permission.Sample.READ,
              Permission.ResearchPlan.CONTROL,
            ],
            component: ResearchEdit,
            path: "/research-plan/assign-edit/:sampleId/:researchPlanId",
          },
          {
            allowedPermissions: [Permission.Sample.READ],
            component: ResearchForm,
            path: "/research-plan/fill/:planId/:markerId",
          },
          {
            allowedPermissions: [Permission.Sample.READ],
            component: ResearchView,
            path: "/research-plan/fill-view/:planId/:markerId",
          },
        ],
      },
    ],
  },
  {
    name: "I18N_PORTRAIT_BLOCK_NAME",
    shortName: "I18N_PORTRAIT_BLOCK_NAME_SHORT",
    children: [
      {
        allowedPermissions: [Permission.Active.CONTROL],
        component: PortraitList,
        icon: InventoryIcon,
        name: "I18N_NAV_PORTRAIT",
        path: "/portraits",
        children: [
          {
            allowedPermissions: [Permission.Active.CONTROL],
            component: PortraitForm,
            path: "/portraits/analysis/:portraitIds",
          },
        ],
      },
      {
        allowedPermissions: [Permission.Active.CONTROL],
        component: Models,
        icon: DesignServicesIcon,
        name: "I18N_NAV_MODELS",
        path: "/models",
        children: [
          {
            allowedPermissions: [Permission.Model.READ],
            component: ModelsView,
            path: "/models/view/:modelId",
          },
          {
            allowedPermissions: [Permission.Sample.READ],
            component: ModelsCreate,
            path: "/models/create",
          },
          {
            allowedPermissions: [Permission.Sample.READ],
            component: ModelsCreate,
            path: "/models/clone/:modelId",
          },
          {
            allowedPermissions: [Permission.Sample.READ],
            component: ModelsCreate,
            path: "/models/edit/:modelId",
          },
        ],
      },
    ],
  },
];

export default PATHS;
