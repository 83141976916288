import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FormControl from "@mui/material/FormControl";

import { TPortraitFilterOptions } from "../../types/portraits";

type TSelectFieldProps = {
  filterType: string;
  value?: string;
  id: string;
  label: string;
  options?: TPortraitFilterOptions;
  noEmptyValue?: boolean;
  onUpdate: (type: string, value: string, label: string) => void;
  onDelete: (type: string) => void;
}

const SelectField = ({
  id,
  filterType,
  value,
  label,
  options,
  noEmptyValue,
  onUpdate,
  onDelete,
}: TSelectFieldProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const { value: newVal } = event.target;
    if (newVal === '') onDelete(filterType);
    else {
      onUpdate(
        filterType,
        newVal,
        options!.tree[newVal].label,
      );
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <FormControl fullWidth size="small">
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value || ''}
          disabled={!options}
          label={label}
          onChange={handleChange}
        >
          {options && options.list
            .filter(o => !noEmptyValue || Boolean(o.value.trim()))
            .map((option) => (
              <MenuItem
                key={`select-option-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton
        color="error"
        disabled={!value}
        onClick={() => onDelete(filterType)}
      >
        <FilterAltOffIcon />
      </IconButton>
    </Stack>
  )
};

export default SelectField;
