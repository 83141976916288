import { fetchBaseQuery } from '@reduxjs/toolkit/query'

import { BASE_APP_URL } from './baseUrls';
import { languageSwitch, injectToken } from "./injectHeaders";

const baseAppQuery = fetchBaseQuery({
  baseUrl: BASE_APP_URL,
  prepareHeaders: (headers, api) => {
    headers = injectToken(headers, api);
    headers = languageSwitch(headers);
    return headers;
  },
});

export default baseAppQuery;
