import React from "react";
import { styled } from "@mui/material/styles";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

type TLargeTooltipProps = TooltipProps & {
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'info';
}

const LargeTooltip = styled(({ className, ...props }: TLargeTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color = 'primary' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette[color].main,
    fontSize: "0.875rem",
    boxShadow: theme.shadows[5],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette[color].main,
  },
}));

export default LargeTooltip;
