import React, { Suspense } from 'react';

const Types = React.lazy(() => import('./Types'));

const LazyTypes = () => (
  <Suspense fallback={<div />}>
    <Types />
  </Suspense>
);

export default LazyTypes;
