import { useMemo, useState, useEffect } from "react";
import Table, {
  TablePagination,
  TTableData,
} from "../../../../../components/Table";
import Preloader from "../../../../../components/Preloader";
import Stack from "@mui/material/Stack";
import { LabelDisplayedRowsArgs } from "@mui/material/TablePagination";
import SystemLogTableHeader from "./SystemLogTableHeader";
import SystemLogAccordion from "./SystemLogAccordion";
import usePagination from "../../../../../hooks/usePagination";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import {
  useGetSampleLogsInfoQuery,
  useGetModelLogsInfoQuery,
  useGetResearchLogsInfoQuery,
  useGetAuthLogsInfoQuery,
  useGetDictLogsInfoQuery,
  useGetPortraitLogsInfoQuery,
} from "../../../../../services/loggers";
import { TLoggerFilterParams } from "../../../../../hooks/useLoggerFilter/types";
import { IEventLogger } from "types/loggers";

interface SampleLoggerProps {
  activeName: string;
  query: TLoggerFilterParams;
  hasFilters: boolean;
}

const LoggerTable = ({ activeName, query, hasFilters }: SampleLoggerProps) => {
  const { t } = useTranslation(["loggers", "components"]);
  const theme = useTheme();
  const isTiny = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [triggerReset, setTriggerReset] = useState(hasFilters);
  const [logsData, setLogsData] = useState<IEventLogger>({
    total_pages: 0,
    events: [],
  });

  const { data: authLogs } = useGetAuthLogsInfoQuery(
    { pageNo, pageSize, ...query },
    { skip: activeName !== "auth" }
  );
  const { data: sampleLogs } = useGetSampleLogsInfoQuery(
    { pageNo, pageSize, ...query, controllerName: activeName },
    {
      skip: activeName !== "samples" && activeName !== "research-plan",
    }
  );
  const { data: researchLogs } = useGetResearchLogsInfoQuery(
    { pageNo, pageSize, ...query },
    { skip: activeName !== "researches" }
  );
  const { data: modelLogs } = useGetModelLogsInfoQuery(
    { pageNo, pageSize, ...query },
    { skip: activeName !== "models" }
  );
  const { data: dictLogs } = useGetDictLogsInfoQuery(
    { pageNo, pageSize, ...query },
    { skip: activeName !== "dict" }
  );
  const { data: portraitLogs } = useGetPortraitLogsInfoQuery(
    { pageNo, pageSize, ...query },
    { skip: activeName !== "portrait" }
  );
  const {
    page,
    size,
    pages,
    handleChangePage,
    handleChangeRowsPerPage,
    resetPagination,
  } = usePagination(
    { pages: logsData?.total_pages, data: logsData?.events },
    activeName + "Logger"
  );
  const logData = {
    auth: authLogs,
    samples: sampleLogs,
    "research-plan": sampleLogs,
    researches: researchLogs,
    models: modelLogs,
    dict: dictLogs,
    portrait: portraitLogs,
  };

  useEffect(() => {
    if (activeName) setLogsData(logData[activeName]);
  }, [logData]);

  const preparedData = useMemo(() => {
    if (logsData) {
      const result = logsData?.events?.map((logs, index) => {
        const tableBody: TTableData = {
          header: {
            data: [
              { value: t("I18N_LOGGER_ERROR_MSG").toString(), width: "33%" },
              { value: t("I18N_LOGGER_REQ_BODY").toString(), width: "33%" },
              { value: t("I18N_LOGGER_RES_BODY").toString(), width: "33%" },
            ],
          },
          rows: [
            {
              data: [
                { value: logs.error_msg },
                { value: logs.reqbody },
                { value: logs.resbody },
              ],
            },
          ],
        };

        return { id: index, accordion: logs, tableBody };
      });

      return result;
    }
  }, [logsData]);

  useEffect(() => {
    setPageNo(page);
    setPageSize(size);
  }, [page, size]);

  if (hasFilters !== triggerReset) {
    setTriggerReset(hasFilters);
    resetPagination();
    setPageNo(1);
    setPageSize(10);
  }

  if (!logsData) return <Preloader />;

  return (
    <Stack>
      <SystemLogTableHeader />
      <Stack sx={{ overflow: "auto", height: "65vh" }}>
        {preparedData?.map((elem) => (
          <SystemLogAccordion accordionData={elem.accordion} key={elem.id}>
            <Table data={elem.tableBody as TTableData} />
          </SystemLogAccordion>
        ))}
      </Stack>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        count={pages * pageSize}
        rowsPerPage={pageSize}
        page={pageNo - 1}
        labelDisplayedRows={({ page: dPage }: LabelDisplayedRowsArgs) =>
          t("I18N_PAGE_COUNTER", {
            ns: "components",
            page: dPage + 1,
            total: pages,
          })
        }
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage=""
        showFirstButton={!isTiny}
        showLastButton={!isTiny}
      />
    </Stack>
  );
};
export default LoggerTable;
