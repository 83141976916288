import { useState, useEffect, useCallback } from "react";
import { TUseLoggerFiltersReturnValue, TLoggerFilters } from "./types";
import { v4 } from "uuid";
import { toQueryParams } from "./utils/toQueryParams";
import useUserNameOptions from "./useUserNameOptions";
import useStatusCodeOptions from "./useStatusCodeOptions";
import useOperationOptions from "./useOperationOptions";

const useLoggerFilter = (sort: {
  field: string;
  order: "asc" | "desc" | string;
}): TUseLoggerFiltersReturnValue => {
  const [filters, setFilters] = useState<TLoggerFilters>({
    from: {},
    to: {},
    name: {},
    userid: {},
    status: {},
    operation: {},
    remote_ip: {},
  });

  const userIdOptions = useUserNameOptions();
  const statusOptions = useStatusCodeOptions();
  const operationOptions = useOperationOptions();

  useEffect(() => {
    if (sort.field && sort.order) {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        newFilters.sort = {
          field: sort.field,
          order: sort.order,
        };
        return newFilters;
      });
    }
  }, [sort.field, sort.order]);

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      newFilters.userid.options = userIdOptions;
      return newFilters;
    });
  }, [userIdOptions.slug]);

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      newFilters.status.options = statusOptions;
      return newFilters;
    });
  }, [statusOptions.slug]);

  useEffect(() => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      newFilters.operation.options = operationOptions;
      return newFilters;
    });
  }, [operationOptions.slug]);

  const deleteFilter = useCallback((type: string) => {
    if (type === "all") {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        delete newFilters.from.value;
        delete newFilters.to.value;
        delete newFilters.name.value;
        delete newFilters.userid.value;
        delete newFilters.operation.value;
        delete newFilters.status.value;
        delete newFilters.remote_ip.value;
        return newFilters;
      });
    } else {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        if (type === "date") {
          delete newFilters.from.value;
          delete newFilters.to.value;
        } else {
          delete newFilters[type].value;
        }

        return newFilters;
      });
    }
  }, []);

  const updateFilter = useCallback(
    (tp: string, value: string, label: string) => {
      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters };
        newFilters[tp].value = { value, label };
        return newFilters;
      });
    },
    [userIdOptions]
  );
  const hasFilters =
    Boolean(filters.to.value) ||
    Boolean(filters.from.value) ||
    Boolean(filters.status.value) ||
    Boolean(filters.userid.value) ||
    Boolean(filters.remote_ip.value) ||
    Boolean(filters.operation.value);

  const methods = {
    deleteFilter,
    updateFilter,
  };
  return {
    filters,
    hasFilters,
    methods,
    query: toQueryParams(filters),
    slug: v4(),
  };
};
export default useLoggerFilter;
