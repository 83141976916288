export const getErrorMessage = (code: number) => {
  switch (code) {
    case -1:
      return "I18N_RESET_TIME_EXPIRED";
    case -2:
    case 412:
      return "I18N_WEAK_PASSWORD";
    case -3:
      return "I18N_SAME_WITH_LAST_PASSWORD";
    case 403:
      return "I18N_USER_IS_BANNED";
    case 419:
      return "I18N_EXPIRED_PASSWORD";
    case 451:
      return "I18N_SERVICE_WORK";
    default:
      return "I18N_LOGIN_FORM_WRONG_CREDENTIALS";
  }
};
