import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useStopResetPasswordMutation } from "../../../services/token";
import { IResetPassword } from "../../../types/token";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AuthFormWrapper from "../../../components/AuthFormWarpper";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import PasswordField from "../../../components/Inputs/PasswordField";

import {
  emailValidator,
  passwordLengthValidator,
  passwordSymbolsValidator,
} from "../../../utils/validation";
import LanguageSelector from "../../../components/ResponsiveDrawer/DrawerContent/LanguageSelector";

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  textAlign: "center",
  lineHeight: "1.5rem",
  color: theme.palette.text.secondary,
  margin: 0,
  fontWeight: 500,
  minWidth: theme.spacing(50),
  maxWidth: theme.spacing(50),
}));

const ResetPassword = () => {
  const { t } = useTranslation(["login", "components"]);
  const navigate = useNavigate();
  const [resetPassword, { isLoading, isSuccess, error }] =
    useStopResetPasswordMutation();
  const method = useForm<IResetPassword>({
    defaultValues: {
      code: "",
      email: "",
      password: "",
      confirm_password: "",
    },
  });
  const {
    handleSubmit,
    register,
    clearErrors,
    getValues,
    formState: { errors },
  } = method;

  const onSubmit: SubmitHandler<IResetPassword> = (data) => {
    resetPassword({
      code: data.code,
      email: data.email,
      password: data.password,
    });
    clearErrors();
  };

  const repeatPasswordValidator = (v: string) => {
    const passwordValue = getValues("password");
    if (v !== passwordValue) return t("I18N_LOGIN_FORM_MATCH_PASSWORD");
    return undefined;
  };

  return (
    <FormProvider {...method}>
      <AuthFormWrapper error={error}>
        {isSuccess ? (
          <Stack alignItems="center">
            <Title variant="h6" sx={{ mb: 2 }}>
              {t("I18N_RESET_PASSWORD_SUCCESS")}
            </Title>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/")}>
              {t("I18N_RETURN_TO_MAIN_LINK")}
            </Button>
          </Stack>
        ) : (
          <Stack
            component="form"
            justifyContent="center"
            alignItems="center"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            spacing={3}>
            <TextField
              {...register("code", {
                required: {
                  value: true,
                  message: t("I18N_REQUIRED_FIELD", { ns: "components" }),
                },
              })}
              label={t("I18N_LOGIN_FORM_CODE")}
              type="text"
              fullWidth
              required
              error={!!errors.code}
              helperText={errors.code?.message}
            />
            <TextField
              {...register("email", {
                required: {
                  value: true,
                  message: t("I18N_REQUIRED_FIELD", { ns: "components" }),
                },
                validate: emailValidator,
              })}
              label={t("I18N_LOGIN_FORM_EMAIL")}
              type="email"
              fullWidth
              required
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <Stack width="100%" spacing={0}>
              <PasswordField
                label={t("I18N_LOGIN_FORM_PASSWORD")}
                validate={{ passwordLengthValidator, passwordSymbolsValidator }}
              />
            </Stack>

            <PasswordField
              label={t("I18N_LOGIN_FORM_CONFIRM_PASSWORD")}
              fieldName="confirm_password"
              validate={repeatPasswordValidator}
            />
            <Stack direction="column" alignItems="center" spacing={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ px: 5 }}
                loading={isLoading}
                disableElevation
                size="large">
                {t("I18N_SEND_CONFIRMATION")}
              </LoadingButton>
            </Stack>
          </Stack>
        )}
        <Box textAlign="center" mt={1}>
          <LanguageSelector iconInstead />
        </Box>
      </AuthFormWrapper>
    </FormProvider>
  );
};
export default ResetPassword;
