import React, { Suspense } from 'react';
const SampleEdit = React.lazy(() => import('./SampleEdit'));

const LazySampleEdit = () => (
  <Suspense fallback={<div />}>
    <SampleEdit />
  </Suspense>
);

export default LazySampleEdit;
