import React, { Suspense } from "react";

const RestorePassword = React.lazy(() => import("./RestorePassword"));

const LazyRestorePassword = () => (
  <Suspense fallback={<div />}>
    <RestorePassword />
  </Suspense>
);

export default LazyRestorePassword;
