import { ReactElement } from 'react';
import cn from 'classnames';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import {
  TChipData,
  TDataType,
  TPermissions,
  TStatusColor,
  TStatusType,
} from './types';
import { useHasPermissions } from '../useHasPermissions';
import { useTranslation } from 'react-i18next';
import { HighlightMap, Icons, Labels, StatusColors } from './enums';

export const useGetStatusData = (
  statusType: TStatusType,
  status: string,
  permissions: TPermissions
): TChipData => {
  let hasPerms: { [key: string]: boolean } = {};
  Object.keys(permissions)
    .map((perm) => ({ [perm]: useHasPermissions(permissions[perm]) }))
    .forEach((perm) => {
      hasPerms = {
        ...hasPerms,
        ...perm,
      };
    });
  const { t } = useTranslation('statuses');

  const dataType: TDataType = cn({
    lab_head: hasPerms.lab_head,
    expert: !hasPerms.lab_head && hasPerms.expert,
    specialist: !hasPerms.lab_head && !hasPerms.expert && hasPerms.specialist,
    default: !hasPerms.lab_head && !hasPerms.expert && !hasPerms.specialist,
  }) as TDataType;

  const getColor = (): TStatusColor => {
    const colorsForStatus = StatusColors[statusType][dataType];
    const selectedColor = colorsForStatus && colorsForStatus[status];
    const rv = selectedColor ?? StatusColors[statusType].default[status];
    return (rv || 'default') as TStatusColor;
  };

  const getIsHighlighted = (): boolean => {
    const highlightsForStatus = HighlightMap[statusType][dataType];
    const selectedHighlight =
      highlightsForStatus && highlightsForStatus[status];
    const rv = selectedHighlight ?? HighlightMap[statusType].default[status];
    return rv || false;
  };

  const getLabel = (): string => {
    const labelsForStatus = Labels[statusType][dataType];
    const selectedLabel = labelsForStatus && labelsForStatus[status];
    const rv = selectedLabel ?? Labels[statusType].default[status];
    const delft = Labels[statusType].default[status];
    return t(rv || delft || 'UNKNOWN');
  };

  const getIcon = (): ReactElement => {
    const iconsForStatus = Icons[statusType][dataType];
    const selectedIcon = iconsForStatus && iconsForStatus[status];
    const icon = selectedIcon ?? Icons[statusType].default[status];
    const Icon = icon || HelpOutlineIcon;
    const color = getColor();
    return <Icon sx={{ color }} />;
  };

  return {
    color: getColor(),
    isHighlighted: getIsHighlighted(),
    label: getLabel(),
    icon: getIcon(),
  };
};

export default useGetStatusData;
