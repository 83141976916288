import { useState, useEffect } from 'react';
import { v4 } from 'uuid';

import { useGetDictListForTypeQuery } from '../../services/dictionaries';
import type {
  TPortraitFilterValue,
  TPortraitFilterOptions,
} from '../../types/portraits';
import {TDirectoryData} from '../../types/dict';

import { getOptionsTree } from '../../utils/selectOptions';

function getOptions(products: TDirectoryData[]): TPortraitFilterValue[] {
  const options: TPortraitFilterValue[] = [];

  products.forEach((source) => {
    options.push({
      value: String(source.code).padStart(2, '0'),
      label: source.name,
    });
  });

  return options;
}

function getProducts(productTypes: TDirectoryData[]): TDirectoryData[] {
  const allSorted = [...productTypes];
  allSorted.sort((a, b) => (
    a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase()))
  )
  return allSorted;
}

const useProductOptions = () => {
  const [options, setOptions] = useState<TPortraitFilterOptions>({
    list: [],
    tree: {},
    slug: v4(),
  });
  const { data: productTypes } = useGetDictListForTypeQuery('productType');

  useEffect(() => {
    if (productTypes) {
      const products = getProducts(productTypes);
      const newOptions = getOptions(products);
      newOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions({
        list: newOptions,
        tree: getOptionsTree(newOptions),
        slug: v4(),
      });
    }
  }, [productTypes]);

  return options;
};

export default useProductOptions;
