import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TPathError = {
  hasError: boolean;
};

const initialState: TPathError = {
  hasError: false,
};

const pathErrorSlice = createSlice({
  name: "pathError",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<TPathError>) => {
      state.hasError = action.payload.hasError;
    },
  },
});

export const { setError } = pathErrorSlice.actions;
export const pathErrorReducer = pathErrorSlice.reducer;
