import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, Location, Params, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import PATHS, { TPathEntry } from "../../router/paths";
import ExpansionContext from "../../contexts/ExpansionContext";
import BreadcrumbTitle from "./BreadcrumbTitle";

const getPageUrl = (location: Location, params: Readonly<Params<string>>) => {
  let path = location.pathname;
  Object.keys(params).forEach(paramKey => {
    const paramValue = params[paramKey];
    if (paramValue) {
      path = path.replace(paramValue, `:${paramKey}`);
    }
  });
  return path;
}

type TRV = {
  name?: string;
  path?: string;
}

const getPageEntriesPath = (entry: TPathEntry, pageUrl: string) => {
  const rv: TRV = {
    name: entry.name,
    path: entry.path,
  };
  if (entry.path) rv.path = entry.path;
  const entries: TRV[] = [];
  if (entry.path !== pageUrl) {
    entry.children?.forEach((childEntry) => {
      const childPath = getPageEntriesPath(childEntry, pageUrl);
      if (childPath.length > 0) {
        entries.push(rv, ...childPath);
      }
    })
  } else {
    return [rv];
  }
  return entries;
}

const getPagePath = (location: Location, params: Readonly<Params<string>>) => {
  const pageUrl = getPageUrl(location, params);
  const path: TRV[] = [];
  PATHS.forEach(block => {
    const rv: TRV = { name: block.name };
    block.children.forEach(entry => {
      const childPath = getPageEntriesPath(entry, pageUrl);
      if (childPath.length > 0) {
        path.push(rv, ...childPath);
      }
    });
  });
  return path.filter(item => !!item.name && item.path !== pageUrl);
}

const ArrowIcon = styled(ArrowRightIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  opacity: .2,
}));

const Breadcrumbs = () => {
  const isExpanded = useContext(ExpansionContext);
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation('nav');

  const breadcrumbPath = useMemo(
    () => getPagePath(location, params),
    [location, params],
  );

  if (isExpanded || breadcrumbPath.length === 0) return null;

  const renderName = (path?: string, name?: string) => (
    name && (path ? <Link to={path}>{t(name)}</Link> : t(name))
  );

  return (
    <Stack direction="row" spacing={1} sx={{ mr: 1 }}>
      {breadcrumbPath.map(item => (
        <Stack
          key={item.name}
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <BreadcrumbTitle variant="h6">
            {renderName(item.path, item.name)}
          </BreadcrumbTitle>
          <ArrowIcon />
        </Stack>
      ))}
    </Stack>
  );
}

export default Breadcrumbs;
