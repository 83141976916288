import cn from "classnames";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import useGetStatusData from "../hooks/statuses/useGetStatusData";

type StatusProps = {
  type: "sample" | "research" | "model" | "portrait";
  status: string;
  permissions?: { [key: string]: string };
  filled?: boolean;
  withIcon?: boolean;
};

const getChipVariant = (filled: boolean, withIcon: boolean) => {
  if (!withIcon && filled) return "filled";
  return "outlined";
};

export const StyledStatusChip = styled(Chip)(({ theme }) => ({
  fontSize: "0.75rem",
  lineHeight: "0.875rem",
  height: "auto",
  padding: "3px 6px",
  border: "0 none",
  borderRadius: theme.shape.borderRadius,
  justifyContent: "flex-start",

  span: {
    padding: 0,
  },

  "&.MuiChip-outlined": {
    border: "1px solid",
    padding: "2px 5px",
  },

  "&.withIcon": {
    padding: 0,
    border: "0 none",
    fontSize: "0.875rem",
    lineHeight: "1.5rem",

    ".MuiChip-iconColorDefault": {
      color: theme.palette.grey[500],
    },

    ".MuiSvgIcon-root": {
      margin: 0,
      marginRight: theme.spacing(1),
    },
  },

  "&.MuiChip-colorDefault&not(.MuiChip-outlinedDefault)": {
    backgroundColor: theme.palette.grey[500],
  },

  "&.MuiChip-outlinedDefault": {
    borderColor: theme.palette.grey[500],
  },

  "&.MuiChip-colorDefault": {
    color: theme.palette.grey[500],
  },
}));

const StatusChip = ({
  type,
  status,
  permissions = {},
  withIcon = false,
}: StatusProps) => {
  const { color, icon, isHighlighted, label } = useGetStatusData(
    type,
    status,
    permissions
  );
  return (
    <StyledStatusChip
      icon={withIcon ? icon : undefined}
      label={label}
      color={color}
      variant={getChipVariant(isHighlighted, withIcon)}
      className={cn({ withIcon })}
    />
  );
};

export default StatusChip;
