import FlagSvg from "./FlagSvg";

const FlagKZ = () => (
  <FlagSvg
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
  >
    <path
      fill="#04afc9"
      d="M226.5-.5h46c89.192 11.16 155.692 55.826 199.5 134 14.933 29.417 24.099 60.417 27.5 93v46c-11.16 89.192-55.826 155.692-134 199.5-29.417 14.933-60.417 24.099-93 27.5h-46c-89.192-11.16-155.692-55.826-199.5-134-14.932-29.417-24.1-60.417-27.5-93v-46C10.66 137.308 55.326 70.808 133.5 27c29.417-14.932 60.417-24.1 93-27.5Z"
      style={{
        opacity: 0.996,
      }}
    />
    <path
      fill="#f0c421"
      d="M249.5 104.5c2.598 7.047 4.598 14.38 6 22-.921 7.084-4.421 8.751-10.5 5-.442-9.349 1.058-18.349 4.5-27Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f5c418"
      d="M269.5 107.5c1.879 7.77 2.379 15.77 1.5 24-3.048 3.51-6.381 3.843-10 1a12.494 12.494 0 0 1 0-7 222.495 222.495 0 0 1 8.5-18Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f5c419"
      d="M228.5 106.5c5.716 7.429 9.216 15.763 10.5 25-3.004 3.5-6.337 3.833-10 1-.5-8.66-.666-17.327-.5-26Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f7c416"
      d="M209.5 112.5a121.314 121.314 0 0 1 13.5 18c2.06 6.938-.44 9.771-7.5 8.5a6.978 6.978 0 0 1-2.5-3.5 218.358 218.358 0 0 1-3.5-23ZM288.5 113.5c.69 7.631-.143 15.298-2.5 23-3.333 4-6.667 4-10 0-.667-1.667-.667-3.333 0-5a350.83 350.83 0 0 1 12.5-18Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c417"
      d="M191.5 122.5a131.409 131.409 0 0 1 17.5 16c1.677 5.155-.156 8.155-5.5 9-1.529-.029-2.696-.696-3.5-2a136.124 136.124 0 0 1-8.5-23Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f5c418"
      d="M306.5 122.5c-.751 7.451-2.917 14.784-6.5 22-2.332 3.291-5.165 3.791-8.5 1.5-1.782-2.243-2.282-4.743-1.5-7.5a369.115 369.115 0 0 1 16.5-16ZM175.5 136.5c.397-1.025 1.063-1.192 2-.5l18 11c2.386 2.707 2.552 5.54.5 8.5-1.913 1.687-4.08 2.187-6.5 1.5a99.297 99.297 0 0 1-14-20.5ZM322.5 135.5c1.025.397 1.192 1.063.5 2l-11 18c-4.003 3.001-7.336 2.335-10-2-.667-1.333-.667-2.667 0-4a99.297 99.297 0 0 1 20.5-14Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#fdc40d"
      d="M236.5 142.5c37.258-3.52 63.091 11.813 77.5 46 8.297 33.797-1.869 60.297-30.5 79.5-36.062 16.767-66.228 9.601-90.5-21.5-18.527-35.017-13.027-65.517 16.5-91.5 8.426-5.631 17.426-9.798 27-12.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c417"
      d="M162.5 152.5c.235-.597.735-.93 1.5-1l20.5 7.5c4 3.333 4 6.667 0 10-2 .667-4 .667-6 0a369.115 369.115 0 0 1-16-16.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f7c416"
      d="M333.5 151.5a4.934 4.934 0 0 1 3 .5 192.01 192.01 0 0 1-16 17c-5.252 1.856-7.919.022-8-5.5-.11-1.874.556-3.374 2-4.5a211.755 211.755 0 0 0 19-7.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c418"
      d="M153.5 170.5c7.606-.545 15.272.289 23 2.5 3.592 2.971 3.925 6.304 1 10a12.494 12.494 0 0 1-7 0 7555.417 7555.417 0 0 1-17-12.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c417"
      d="M338.5 169.5a32.462 32.462 0 0 1 8 .5 172.26 172.26 0 0 1-18 13c-5.155 1.677-8.155-.156-9-5.5.376-1.883 1.376-3.383 3-4.5a130.693 130.693 0 0 0 16-3.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f0c421"
      d="M153.5 187.5c6.009-.166 12.009.001 18 .5 2.79 2.21 3.623 5.044 2.5 8.5-2.593 3.433-5.76 3.933-9.5 1.5l-18-9c2.536-.186 4.869-.686 7-1.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f1c41f"
      d="M327.5 187.5c8.359.026 16.693.526 25 1.5-7.301 3.9-14.801 7.4-22.5 10.5-5.023-1.042-6.69-4.042-5-9a10.515 10.515 0 0 0 2.5-3Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f0c421"
      d="M160.5 204.5a60.931 60.931 0 0 1 11 .5c3.641 7.371 1.307 10.704-7 10a90.53 90.53 0 0 1-18-5.5 118.147 118.147 0 0 1 14-5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f8c415"
      d="M327.5 204.5c8.713-.206 17.046 1.46 25 5-7.95 3.708-16.284 5.208-25 4.5-1.292-3.165-1.292-6.332 0-9.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c418"
      d="M187.5 344.5c-.709.904-1.709 1.237-3 1a167.375 167.375 0 0 1-42-15.5 20.64 20.64 0 0 1 6-2c-5.17-.941-10.17-2.608-15-5 2.411-1.583 5.078-2.583 8-3a40.23 40.23 0 0 0-11-2c-1.41-.368-2.41-1.201-3-2.5l10-3a156.825 156.825 0 0 0-15-3.5c-1.803-.637-3.136-1.803-4-3.5a67.381 67.381 0 0 1 15-.5c-9.33-3.414-17.83-8.247-25.5-14.5-2.071-1.955-2.738-4.288-2-7a52.526 52.526 0 0 1 10.5 5.5c1.667.667 3.333.667 5 0-10.167-7.5-19-16.333-26.5-26.5-1.009-1.525-1.509-3.192-1.5-5a18.452 18.452 0 0 1 6 .5 228.489 228.489 0 0 0 18.5 17.5c.667-.667.667-1.333 0-2-9.176-9.508-16.342-20.342-21.5-32.5 2.11-3.013 4.776-3.346 8-1a286.944 286.944 0 0 0 16.5 22.5 225.886 225.886 0 0 1-10-26 34.611 34.611 0 0 1-1-14c1.224-2.973 3.224-3.64 6-2a694.593 694.593 0 0 1 7 25 34.099 34.099 0 0 0 3 6 85.803 85.803 0 0 1-1-20c1.555-2.015 3.389-2.348 5.5-1 12.614 51.114 44.614 82.614 96 94.5a971.035 971.035 0 0 1 21 2.5 34.696 34.696 0 0 1-5.5 9 24.017 24.017 0 0 0-8.5-4.5 292.241 292.241 0 0 1-31-4c-7.212-4.206-13.545-9.539-19-16a75.817 75.817 0 0 1-23-12 228.729 228.729 0 0 1-15.5-19.5 101.553 101.553 0 0 0-12.5-11.5c-1.891-.309-3.225.525-4 2.5-1.698-1.181-3.365-1.181-5 0 3.361 3.722 5.361 8.055 6 13a9.86 9.86 0 0 0-6 1c2.142 3.975 5.142 7.142 9 9.5a14.72 14.72 0 0 0-5 1.5c3.078 3.874 7.078 6.207 12 7-.901 3.741.432 5.741 4 6a17.65 17.65 0 0 0-.5 6 82.45 82.45 0 0 1 6.5 3 14.972 14.972 0 0 1-3 3.5c3.572 2.637 7.572 4.303 12 5a22.755 22.755 0 0 0-5 4 141.374 141.374 0 0 0 34 13.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c417"
      d="M370.5 305.5c-.543.06-.876.393-1 1a16.873 16.873 0 0 0 8 1c1.274.689 1.274 1.523 0 2.5a189.517 189.517 0 0 1-18 6.5l10 2c-5.664 2.752-11.664 4.586-18 5.5a53.73 53.73 0 0 0 9 2.5c-5.661 1.333-11.327 2.667-17 4-1.333.667-1.333 1.333 0 2a17.54 17.54 0 0 1 6 3c-12.195 9.099-25.862 12.932-41 11.5 10.547-1.289 19.88-5.289 28-12 1.267-1.796 1.267-3.629 0-5.5a20.2 20.2 0 0 1 6-1.5c.712-1.425.879-2.925.5-4.5a14.444 14.444 0 0 1 5.5-2.5 53.585 53.585 0 0 1 3-6 17.335 17.335 0 0 0 7-3.5 6.802 6.802 0 0 0-3-1.5 153.047 153.047 0 0 0 14-9.5l-4-2a46.607 46.607 0 0 1 5.5-6c1.009-1.525 1.509-3.192 1.5-5a85.93 85.93 0 0 1-8 1.5c3.769-3.706 5.769-8.206 6-13.5-5.438-.021-10.438 1.479-15 4.5-8.475 6.138-15.141 13.805-20 23a597.358 597.358 0 0 0-29 15 77.742 77.742 0 0 0-11 10 1706.913 1706.913 0 0 0-40 8 245.024 245.024 0 0 1-31 21 75.901 75.901 0 0 1 12-1l-3 3 2 1c-1.829 1.113-2.663 2.613-2.5 4.5 4.126.039 8.293.373 12.5 1l-10 2a92.101 92.101 0 0 0-22.5 2c-2.331-1.555-4.331-1.221-6 1-.667-2-.667-4 0-6a60.227 60.227 0 0 0 8.5-9.5 163.818 163.818 0 0 0 22-10 311.832 311.832 0 0 1 18-18c49.905-4.514 85.405-29.347 106.5-74.5a134.202 134.202 0 0 0 6-22c2.33-1.532 4.33-1.198 6 1a85.803 85.803 0 0 1-1 20 280.955 280.955 0 0 0 10.5-31.5c1.612-.72 3.112-.554 4.5.5 1.789 5.28 1.789 10.614 0 16a494.802 494.802 0 0 1-9 24 256.63 256.63 0 0 0 16.5-21.5c2-.667 4-.667 6 0a4.933 4.933 0 0 1 1 2.5c-5.875 12.378-13.541 23.545-23 33.5 1.779-.044 3.445-.711 5-2l16-16c2.425-.986 4.091-.319 5 2-7.231 11.9-16.564 21.9-28 30 1.333.667 2.667.667 4 0a94.197 94.197 0 0 1 12-5.5c-.028 4.219-1.695 7.719-5 10.5a257.499 257.499 0 0 1-18 11.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f2c41d"
      d="M167.5 219.5c6.549.417 8.549 3.75 6 10-3.132 1.513-6.466 2.18-10 2a366.402 366.402 0 0 1-17-1.5l21-10.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f2c41e"
      d="M327.5 219.5a102.7 102.7 0 0 1 25 10.5 117.843 117.843 0 0 1-25 1c-3.945-3.831-3.945-7.664 0-11.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c417"
      d="M170.5 235.5c5.206-1.38 8.206.453 9 5.5-.143 2.151-1.143 3.818-3 5a87.509 87.509 0 0 1-23 3 352.115 352.115 0 0 0 17-13.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c418"
      d="M322.5 235.5a18.453 18.453 0 0 1 6 .5 252.895 252.895 0 0 1 17 13 113.776 113.776 0 0 1-23-3c-3.935-3.496-3.935-6.996 0-10.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f5c418"
      d="M178.5 249.5c6.33-.834 9.163 1.833 8.5 8a11.532 11.532 0 0 1-4.5 3.5 169.76 169.76 0 0 1-20 6 399.457 399.457 0 0 1 16-17.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c418"
      d="M314.5 249.5a18.453 18.453 0 0 1 6 .5 192.01 192.01 0 0 1 16 17c-7.574-1.301-14.907-3.635-22-7-2.62-3.498-2.62-6.998 0-10.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f5c419"
      d="M189.5 261.5c6.963.265 9.13 3.598 6.5 10-6.611 4.547-13.444 8.38-20.5 11.5a238.96 238.96 0 0 1 14-21.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f4c41a"
      d="M305.5 261.5c2.436-.183 4.603.484 6.5 2 4.764 6.205 8.431 12.872 11 20a88.38 88.38 0 0 1-21-14c-.667-1.333-.667-2.667 0-4a19.552 19.552 0 0 0 3.5-4Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f5c41a"
      d="M202.5 271.5c5.856.354 8.023 3.354 6.5 9a192.01 192.01 0 0 1-17 16c.828-5.827 2.495-11.493 5-17 1.129-3.294 2.962-5.96 5.5-8Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f2c41d"
      d="M293.5 271.5c2.825-.419 4.991.581 6.5 3a371.145 371.145 0 0 1 8.5 21.5c-6.579-3.546-12.412-8.38-17.5-14.5-2.511-4.3-1.678-7.633 2.5-10Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f3c41c"
      d="M215.5 279.5c7.06-.775 9.56 2.225 7.5 9a172.26 172.26 0 0 1-13 18c.003-7.031.67-14.031 2-21a26.523 26.523 0 0 1 3.5-6Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f7c416"
      d="M278.5 279.5c3.152-.541 5.652.459 7.5 3 2.313 7.521 3.313 15.187 3 23a172.26 172.26 0 0 1-13-18c-.982-3.275-.149-5.941 2.5-8Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f6c419"
      d="M230.5 284.5c4.696-1.086 7.696.581 9 5a91.664 91.664 0 0 1-10.5 23 169.166 169.166 0 0 1 0-26c.717-.544 1.217-1.211 1.5-2Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f7c416"
      d="M262.5 284.5c3.47-.625 6.304.375 8.5 3 .384 8.35.05 16.683-1 25a246.886 246.886 0 0 1-9-19c-.932-3.323-.432-6.323 1.5-9Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f2c420"
      d="M247.5 285.5c4.934-.563 7.6 1.604 8 6.5a93.595 93.595 0 0 1-6 21.5c-3.11-8.428-4.61-17.095-4.5-26 .995-.566 1.828-1.233 2.5-2Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#61b787"
      d="m370.5 305.5 7 2a16.873 16.873 0 0 1-8-1c.124-.607.457-.94 1-1Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#a9bd55"
      d="M187.5 344.5c1.266.59 2.599 1.09 4 1.5-2.539.796-4.872.629-7-.5 1.291.237 2.291-.096 3-1Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#f7c417"
      d="M294.5 355.5a339.643 339.643 0 0 1 40 3c-.607 6.649-4.274 9.483-11 8.5l-5-2.5a34.018 34.018 0 0 1-3 8.5c-1.333.667-2.667.667-4 0a20.023 20.023 0 0 0-5-2.5c-2.105 1.762-3.772 3.928-5 6.5-2.976.757-4.809-.409-5.5-3.5-1.929 4.304-5.096 6.47-9.5 6.5a10.756 10.756 0 0 0-3-2.5c-3.861 6.542-7.361 6.542-10.5 0-1.491 4.658-4.658 6.658-9.5 6a9.86 9.86 0 0 0-1-6c-2.246 3.917-5.579 6.25-10 7a24.935 24.935 0 0 0-.5-7 28.54 28.54 0 0 1-8.5 7c-1.488-1.649-2.488-3.649-3-6-3.83 3.438-8.164 4.772-13 4 2.69-5.522 7.023-9.022 13-10.5a467.73 467.73 0 0 0 38-2 76.537 76.537 0 0 0 9-4 15.256 15.256 0 0 0 1.5-4.5c2.521-1.381 4.355-3.381 5.5-6Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#54b3a5"
      d="M218.5 358.5h4c.415 2.448-.585 3.781-3 4-1.669-1.077-3.335-1.244-5-.5 1.819-.74 3.152-1.907 4-3.5Z"
      style={{
        opacity: 1,
      }}
    />
    <path
      fill="#b3be4d"
      d="M245.5 365.5c5.852-.449 11.519.051 17 1.5-8.994.5-17.994.666-27 .5l10-2Z"
      style={{
        opacity: 1,
      }}
    />
  </FlagSvg>
);

export default FlagKZ;
