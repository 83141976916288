import { BaseQueryApi } from "@reduxjs/toolkit/query";
import i18n from "i18next";

export function injectToken(
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
): Headers {
  const token = localStorage.getItem("accessToken");
  if (!token) return headers;
  headers.set("Authorization", `Bearer ${token}`);
  return headers;
}

export function languageSwitch(headers: Headers): Headers {
  headers.set("Accept-Language", i18n.language);
  return headers;
}
