import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { languageSwitch } from './injectHeaders';

export const BASE_APP_URL =
  location.hostname === process.env.REACT_APP_URL_HOSTNAME
    ? '/api/auth/'
    : `${process.env.REACT_APP_AUTH_URL}/api/auth/`;

const baseAuthQuery = fetchBaseQuery({
  baseUrl: BASE_APP_URL,
  prepareHeaders: languageSwitch,
});

export default baseAuthQuery;