import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ListItem from "../ListItem";
import { TLangs } from "./types";
import FlagKZ from "./FlagKZ";
import FlagRU from "./FlagRU";
import LanguageMenu from "./LanguageMenu";

import dictApi from "../../../../services/dictionaries";
import researchesApi from "../../../../services/researchPlans";
import samplesApi from "../../../../services/samples";
import modelsApi from "../../../../services/models";
import FlagEN from "./FlagEN";
import LanguageIcon from "@mui/icons-material/Language";
import { IconButton } from "@mui/material";

const LanguageSelector = ({ iconInstead }: { iconInstead?: boolean }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as "kk" | "ru" | "en";

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const resetApi = () => {
    dispatch(samplesApi.util.resetApiState());
    dispatch(researchesApi.util.resetApiState());
    dispatch(dictApi.util.resetApiState());
    dispatch(modelsApi.util.resetApiState());
  };

  const handleSelectLanguage = (lng?: "kk" | "ru" | "en") => {
    if (lng) i18n.changeLanguage(lng).then(() => i18n.reloadResources());
    setAnchorEl(null);
    i18n.on("languageChanged", () => resetApi());
  };

  const langs: TLangs = {
    kk: {
      icon: <FlagKZ />,
      name: "I18N_LANGUAGE_KZ",
    },
    ru: {
      icon: <FlagRU />,
      name: "I18N_LANGUAGE_RU",
    },
    en: {
      icon: <FlagEN />,
      name: "I18N_LANGUAGE_EN",
    },
  };

  return (
    <>
      {iconInstead ? (
        <IconButton
          onClick={handleMenuOpen}
          color="primary"
        >
          <LanguageIcon />
        </IconButton>
      ) : (
        <ListItem
          icon={langs[currentLanguage].icon}
          name={langs[currentLanguage].name}
          path=""
          selectable={false}
          onClick={handleMenuOpen}
        />
      )}
      <LanguageMenu
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onSelectLanguage={handleSelectLanguage}
      />
    </>
  );
};

export default LanguageSelector;
