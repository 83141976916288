import * as React from "react";
import { PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: "initial",

  [theme.breakpoints.only("xs")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.only("md")]: {
    fontSize: "0.875rem",
  },
  lineHeight: 1.28,
  fontWeight: 400,
  letterSpacing: 0.46,
}));

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

type BasicTabsProps = {
  labels: string[];
  locales: string;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};
const BasicTabs = ({
  children,
  labels,
  locales,
  value,
  onChange,
}: PropsWithChildren<BasicTabsProps>) => {
  const { t } = useTranslation(locales);
  return (
    <Stack spacing={3} height="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}>
          {labels.map((elem, index) => (
            <CustomTab key={index} label={t(elem)} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {children}
    </Stack>
  );
};
export default BasicTabs;
