import { createApi } from "@reduxjs/toolkit/query/react";
import { IChangePassword, IPeople } from "../types/people";
import baseAppQuery from "./baseAppQuery";

const ENDPOINT = "auth/api/v1/people";
export const peopleApi: any = createApi({
  reducerPath: "peopleApi",
  tagTypes: ["people"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getPeopleList: builder.query<IPeople, { search: string; searchBy: string }>(
      {
        query: ({ search, searchBy }) => {
          const querySearch = `?${searchBy}=${search}`;
          return `${ENDPOINT}${search ? querySearch : ""}`;
        },
        providesTags: ["people"],
      }
    ),
    getPeopleById: builder.query<IPeople, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
      providesTags: ["people"],
    }),
    addPeople: builder.mutation<IPeople, IPeople>({
      query: (data: IPeople) => ({
        url: ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["people"],
    }),
    updatePeople: builder.mutation<IPeople, IPeople>({
      query: (data: IPeople) => ({
        url: `${ENDPOINT}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["people"],
    }),
    bunPeople: builder.mutation<IPeople, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}/bun`,
        method: "PATCH",
      }),
      invalidatesTags: ["people"],
    }),
    unBunPeople: builder.mutation<IPeople, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}/unbun`,
        method: "PATCH",
      }),
      invalidatesTags: ["people"],
    }),
    bindPeopleToRole: builder.mutation<
      IPeople,
      { personId: string; roleId: string; unbind: boolean }
    >({
      query: ({
        personId,
        roleId,
        unbind,
      }: {
        personId: string;
        roleId: string;
        unbind: boolean;
      }) => ({
        url: `${ENDPOINT}/${personId}/roles/${roleId}?unbind=${unbind}`,
        method: "PATCH",
      }),
    }),
    deletePeople: builder.mutation<IPeople, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["people"],
    }),
    changePassword: builder.mutation<string, IChangePassword>({
      query: ({ id, newPass, oldPass }) => ({
        url: `${ENDPOINT}/${id}/password`,
        body: new URLSearchParams({
          newPass: newPass,
          oldPass: oldPass,
        }),
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      }),
      invalidatesTags: ["people"],
    }),
  }),
});

export const {
  useGetPeopleListQuery,
  useGetPeopleByIdQuery,
  useAddPeopleMutation,
  useBindPeopleToRoleMutation,
  useUpdatePeopleMutation,
  useUnBunPeopleMutation,
  useBunPeopleMutation,
  useDeletePeopleMutation,
  useChangePasswordMutation,
} = peopleApi;
export default peopleApi;
