import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table, { TTableData } from "../../components/Table";
import ConfirmPrompt from "../Prompts/ConfirmPrompt";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "./CustomButtons";

type AdminPanelTableProps = {
  data: any;
  prepareData: (data: any, onNavigate: (path: string) => void) => TTableData;
  onDelete?: (id: string) => void;
};
const AdminPanelTable = ({
  data,
  prepareData,
  onDelete,
}: AdminPanelTableProps) => {
  const { t, i18n } = useTranslation("components");
  const navigate = useNavigate();
  const [openPrompt, setOpenPrompt] = useState(false);
  const [deletableItem, setDeletableItem] = useState("");
  const [dataId, setDataId] = useState("");

  const handleDelete = (id: string) => {
    const getName = data?.find((elem) => elem.id === id).name;
    const getTranslatedName = data?.find((elem) => elem.id === id)[
      `name${i18n.language.toUpperCase()}`
    ];
    setDeletableItem(getName || getTranslatedName);
    setDataId(id);
    setOpenPrompt(true);
  };
  const preparedData: TTableData = useMemo(() => {
    const formattedData = prepareData(data, navigate);
    formattedData.header.data.forEach((header) => {
      header.value = t(header.value as string) as string;
    });
    if (onDelete) {
      formattedData.rows.forEach((row) => {        
        const deleteAction = {
          value: row.id as string,
          render: (value: string) => (
            <DeleteButton isDeletable={row.isDeletable} onDelete={() => handleDelete(value)} />
          ),
        };
        row.data.push(deleteAction);
      });
    }
    return formattedData;
  }, [data, i18n.language]);

  return (
    <>
      <Table data={preparedData} />
      {onDelete && (
        <ConfirmPrompt
          open={openPrompt}
          title={t("I18N_DIALOG_CONFIRM_DELETE_TITLE", { elem: deletableItem })}
          message={t("I18N_DIALOG_CONFIRM_DELETE_MESSAGE", {
            elem: deletableItem,
          })}
          onCancel={() => setOpenPrompt(!openPrompt)}
          onConfirm={() => {
            setOpenPrompt(!openPrompt);
            onDelete(dataId);
          }}
        />
      )}
    </>
  );
};
export default AdminPanelTable;
