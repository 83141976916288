import React, { Suspense } from 'react';

const PermissionCreate = React.lazy(() => import('./PermissionCreate'));

const LazyPermissionsCreate = () => (
  <Suspense fallback={<div />}>
    <PermissionCreate />
  </Suspense>
);

export default LazyPermissionsCreate;
