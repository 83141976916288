import { PropsWithChildren, ReactNode } from "react";
import { TDetails, TDetailsBlock } from "./types";
import DetailsBlock from "./DetailsBlock";
import DetailsContent from "./DetailsContent";
import DetailField from "./DetailField";
import DownloadButton from "./DownloadButton";
import { Stack } from "@mui/material";

type TDetailsViewProps = {
  title: string;
  data: TDetails;
  defaultCollapsed?: boolean;
  disableCollapse?: boolean;
  slot?: ReactNode;
  onExpandedChanged?: (expanded: boolean) => void;
};

const DetailsView = ({
  data,
  title,
  defaultCollapsed = false,
  disableCollapse = false,
  slot,
  children,
  onExpandedChanged,
}: PropsWithChildren<TDetailsViewProps>) => (
  <DetailsBlock
    title={title}
    defaultCollapsed={defaultCollapsed}
    disableCollapse={disableCollapse}
    onExpandedChanged={onExpandedChanged}>
    <Stack flexDirection="row">
      <DetailsContent flex={1}>
        {data.map((row, index) => (
          <DetailField key={`field-${row.value}-${index}`} data={row} />
        ))}
      </DetailsContent>
      {slot && <DetailsContent flex={1}>{slot}</DetailsContent>}
    </Stack>
    {children && <DetailsContent>{children}</DetailsContent>}
  </DetailsBlock>
);

export default DetailsView;
export type { TDetails, TDetailsBlock };
export { DownloadButton };
