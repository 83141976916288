import { createApi } from "@reduxjs/toolkit/query/react";
import {
  IResearchPlan,
  IResearchItem,
  ICreateResearchPlan,
  IMarkerResearchItem,
  IRequestResearchPlanDto,
} from "../types/researches";
import { IPaginatedList, IPaginatedListParams } from "../types/paginated";
import baseAppQuery from "./baseAppQuery";
import transformPaginatedResponse from "./transformPaginatedResponse";
import { TResearchFilterParams } from "../hooks/useResearchesFilters/types";

const ENDPOINT = "research-plan";

export const researchPlanApi: any = createApi({
  reducerPath: "researchPlanApi",
  tagTypes: ["researchPlan"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getResearchPlanList: builder.query<
      IPaginatedList<IResearchPlan>,
      TResearchFilterParams & IPaginatedListParams
    >({
      query: (qp) => {
        const queryParams = new URLSearchParams();
        queryParams.set("pageNo", qp.pageNo.toString());
        queryParams.set("pageSize", qp.pageSize.toString());

        if (qp.type) queryParams.set("type", qp.type);
        if (qp.source) queryParams.set("codeOrgName", qp.source);
        if (qp.product) queryParams.set("finishedProductTypeCode", qp.product);
        if (qp.from) queryParams.set("from", qp.from);
        if (qp.to) queryParams.set("to", qp.to);
        if (qp.complete) queryParams.set("complete", qp.complete);

        if (qp.specialistName)
          queryParams.set("specialistName", qp.specialistName);
        if (qp.markerName) queryParams.set("markerName", qp.markerName);
        if (qp.modelName) queryParams.set("modelName", qp.modelName);
        if (qp.modelVersion) queryParams.set("modelVersion", qp.modelVersion);
        if (qp.researchStatus)
          queryParams.set("status", qp.researchStatus?.join());
        if (qp.sort) {
          queryParams.set("sortOrder", qp.sort.order);
          queryParams.set("sortBy", qp.sort.field);
        }
        return `${ENDPOINT}/list?${queryParams.toString()}`;
      },
      transformResponse: transformPaginatedResponse,
    }),
    getResearchPlanListBySample: builder.query<IRequestResearchPlanDto, string>(
      {
        query: (sampleId: string) => `${ENDPOINT}/sample/${sampleId}`,
      }
    ),
    getResearchPlanMarkersList: builder.query<
      IResearchItem[],
      IPaginatedListParams
    >({
      query: ({ pageNo, pageSize }) =>
        `${ENDPOINT}/markers?pageNo=${pageNo}&pageSize=${pageSize}`,
    }),
    getResearchPlanById: builder.query<IResearchItem, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
    }),
    getResearchPlanMarkersById: builder.query<IMarkerResearchItem[], string>({
      query: (id: string) => `${ENDPOINT}/${id}/markers`,
    }),
    postResearchPlan: builder.mutation<IResearchPlan, ICreateResearchPlan>({
      query: (payload: ICreateResearchPlan) => ({
        url: `${ENDPOINT}/`,
        method: "POST",
        body: payload,
      }),
    }),
    putResearchPlan: builder.mutation<IResearchPlan, IResearchPlan>({
      query: (data: IResearchPlan) => ({
        url: `${ENDPOINT}/`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteResearchPlan: builder.mutation<null, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: "DELETE",
      }),
    }),
    getResearchPlanHistoryByMarkerId: builder.query<IResearchItem, string>({
      query: (id) => `${ENDPOINT}/history/marker/${id}`,
    }),
    putResearchPlanMarkerState: builder.mutation<
      IResearchPlan,
      { rplanId: string; rmarkerId: string; newState: string; descr: string }
    >({
      query: ({ rplanId, rmarkerId, newState, descr }) => ({
        url: `${ENDPOINT}/${rplanId}/rmarkers/${rmarkerId}/state/${newState}`,
        method: "PUT",
        body: descr,
      }),
    }),
  }),
});

export const {
  useDeleteResearchPlanMutation,
  useGetResearchPlanListQuery,
  useGetResearchPlanListBySampleQuery,
  useLazyGetResearchPlanListBySampleQuery,
  useGetResearchPlanMarkersListQuery,
  useGetResearchPlanByIdQuery,
  useLazyGetResearchPlanByIdQuery,
  useGetResearchPlanMarkersByIdQuery,
  usePostResearchPlanMutation,
  usePutResearchPlanMutation,
  useGetResearchPlanHistoryByMarkerIdQuery,
  usePutResearchPlanMarkerStateMutation,
} = researchPlanApi;
export default researchPlanApi;
