import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface IPreloaderProps {
  height?: string | number;
}

const Preloader = ({ height }: IPreloaderProps) => (
  <Box sx={{ height: height ?? "calc(100vh - 128px)", width: "100%" }}>
    <Stack alignItems="center" justifyContent="center" sx={{ height: "100%" }}>
      <CircularProgress />
    </Stack>
  </Box>
);

export default Preloader;
