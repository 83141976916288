import { useState, useCallback } from "react";

const useTableSort = (defaultField: string, reversedFields?: string[]) => {
  const [field, setField] = useState<string>(defaultField);
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  const handleSort = useCallback(
    (newField: string) => {
      if (newField === field) {
        setOrder(order === "asc" ? "desc" : "asc");
      } else {
        setField(newField);
        setOrder(reversedFields?.includes(newField) ? "asc" : "desc");
      }
    },
    [field, order]
  );

  return {
    sort: { field, order },
    handleSort,
  };
};

export default useTableSort;
