import React, { Suspense } from "react";

const PortraitForm = React.lazy(() => import("./PortraitForm"));

const LazyPortraitForm = () => (
  <Suspense fallback={<div />}>
    <PortraitForm />
  </Suspense>
);

export default LazyPortraitForm;
