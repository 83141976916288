import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterSelect from "./FilterSelect";
import {
  TLoggerFilters,
  TLoggerFilterMethods,
} from "hooks/useLoggerFilter/types";

type TAddFilterProps = {
  filters: TLoggerFilters;
  methods: TLoggerFilterMethods;
};

const AddFilter = ({ filters, methods }: TAddFilterProps) => {
  const { t } = useTranslation("portrait");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        startIcon={<FilterAltIcon />}
        variant="outlined"
        onClick={handleButtonClick}>
        {t("I18N_PORTRAIT_FILTERS_ADD")}
      </Button>
      <FilterSelect
        anchorEl={anchorEl}
        filters={filters}
        methods={methods}
        onClose={() => setAnchorEl(null)}
      />
    </>
  );
};

export default AddFilter;
