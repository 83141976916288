import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import {
  TPortraitFilterOptions,
} from "../../../../../types/portraits";
import AutocompleteField from "../../../../FilterFields/AutocompleteField";
import SelectField from "../../../../FilterFields/SelectField";
import TextStringField from "../../../../../components/FilterFields/TextField";
import ToggleField from "../../../../../components/FilterFields/ToggleField";

type TEditViewProps = {
  anchorEl: HTMLDivElement | null;
  filterType: string;
  value: string;
  title: string;
  inputType: "select" | "autocomplete" | "date" | "text" | "toggle" | "multy";
  options?: TPortraitFilterOptions;
  onChange: (type: string, value: string, label: string) => void;
  onRemove: (type: string) => void;
  onClose: () => void;
};

const EditViewContainer = styled(Stack)(({ theme }) => ({
  width: theme.spacing(80),
  padding: theme.spacing(2),
}));

const EditView = ({
  anchorEl,
  filterType,
  value,
  title,
  inputType,
  options,
  onChange,
  onRemove,
  onClose,
}: TEditViewProps) => (
  <Popover
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    onClose={onClose}
    sx={{ mt: 2 }}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
  >
    <EditViewContainer>
      {inputType === "select" && (
        <SelectField
          id={`select-filter-${filterType}`}
          filterType={filterType}
          label={title}
          value={value}
          noEmptyValue
          options={options}
          onUpdate={onChange}
          onDelete={onRemove}
        />
      )}
      {inputType === "autocomplete" && (
        <AutocompleteField
          id={`autocomplete-filter-${filterType}`}
          filterType={filterType}
          label={title}
          value={value}
          noEmptyValue
          options={options}
          onUpdate={onChange}
          onDelete={onRemove}
        />
      )}
      {inputType === "text" && (
        <TextStringField
          id={`text-filter-${filterType}`}
          filterType={filterType}
          label={title}
          value={value}
          onUpdate={onChange}
          onDelete={onRemove}
        />
      )}
      {inputType === "toggle" && (
        <ToggleField
          id={`text-filter-${filterType}`}
          filterType={filterType}
          label={title}
          value={Boolean(value)}
          onUpdate={onChange}
          onDelete={onRemove}
        />
      )}
    </EditViewContainer>
  </Popover>
);

export default EditView;
