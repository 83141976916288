import { ReactNode } from "react";
import cn from "classnames";
import { styled } from "@mui/material/styles";
import MuiTableCell from "@mui/material/TableCell";

import ClickableRow from "./ClickableRow";
import { TTableRow, TTableCell, TOptionalColumn } from "./types";
import { useHasPermissions } from "../../hooks/useHasPermissions";

type TableContentProps = {
  data: TTableRow;
  skipCols?: TOptionalColumn[];
  expanded?: boolean;
};

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: "1px solid",
  borderColor: "#D1E4F6", // @TODO сделать стилизуемым в теме
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
}));

const getValue = ({ value, transformValue, render }: TTableCell) => {
  let valueToRender: ReactNode = value;
  if (transformValue) valueToRender = transformValue(value);
  if (render) valueToRender = render(valueToRender);
  return valueToRender;
};

type TTableColumnProps = {
  index: number;
  cell: TTableCell;
  skipCols: TOptionalColumn[];
};

const colors: { [key: string]: string } = {
  "theme.palette.warning.main": "#ED6C02",
};

const TableColumn = ({ index, cell, skipCols }: TTableColumnProps) => {
  const col = skipCols.filter((sk) => sk.columnIndex === index)[0];
  const perms: string | string[] = col ? col.permission : [];
  const hasPermission = useHasPermissions(perms);
  if (!hasPermission) return null;
  const styles: { [key: string]: any } = {
    minWidth: cell.minWidth || "auto",
    width: cell.width || "auto",
    color: cell.color ? colors[cell.color] : "#000000",
  };
  if (cell.noPadding) {
    styles.py = 0;
  }
  return (
    <TableCell
      component="th"
      scope="row"
      align={cell.align}
      sx={styles}
      colSpan={cell.colSpan}
    >
      {getValue(cell)}
    </TableCell>
  );
};

const TableRow = ({ data: { data, onClick }, skipCols, expanded = false }: TableContentProps) => (
  <ClickableRow onClick={onClick} className={cn({'KAP-row-with-arrow': expanded})}>
    {data.map((cell, index) => (
      <TableColumn
        key={`cell-${cell.value}-${index}`}
        index={index}
        cell={cell}
        skipCols={skipCols || []}
      />
    ))}
  </ClickableRow>
);
export default TableRow;
