import React, { Suspense } from 'react';

const Users = React.lazy(() => import('./Users'));

const LazyUsers = () => (
  <Suspense fallback={<div />}>
    <Users />
  </Suspense>
);

export default LazyUsers;
