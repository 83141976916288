import React, { ReactNode } from "react";
import { alpha, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FileInfo } from "../../types/file";
import { TDetailRow } from "./types";

type TDetailFieldProps = {
  data: TDetailRow;
};

const FieldContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(0.75)} 0`,

  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.04)
  }
}));

const TitleRow = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  margin: 0,
  color: theme.palette.text.secondary,
  fontWeight: 500,
  minWidth: theme.spacing(40),
  maxWidth: theme.spacing(40)
}));

export const ValueRow = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  margin: 0,
  color: theme.palette.text.primary,
  fontWeight: 500,
  flexGrow: 1,
  flexShrink: 1
}));

const getValue = ({ value, transformValue, render }: TDetailRow) => {
  let valueToRender:
    | ReactNode
    | FileInfo
    | FileInfo[]
    | { [key: string]: string } = value;
  if (transformValue) valueToRender = transformValue(value);
  if (render) valueToRender = render(valueToRender);
  return valueToRender as ReactNode;
};

const DetailField = ({ data }: TDetailFieldProps) => {
  const theme = useTheme();
  const isTiny = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <FieldContainer direction={isTiny ? "column" : "row"}>
      {data.title !== "single" && <TitleRow>{data.title}</TitleRow>}
      {data.render ? getValue(data) : <ValueRow>{getValue(data)}</ValueRow>}
    </FieldContainer>
  );
};
export default DetailField;
