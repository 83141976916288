import { ReactNode, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { alpha, styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import { TSelectOption } from '../../types/fields';

type TSearchFieldProps = {
  searchBy?: {
    value: string;
    options: TSelectOption[];
    onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  };
  onSearch: (value: string) => void;
};

type TStyledTextFieldProps = {
  withFilter?: boolean;
};

const StyledTextField = styled(
  ({ withFilter, ...props }: TextFieldProps & TStyledTextFieldProps) => (
    <TextField {...props} />
  )
)<TStyledTextFieldProps>(({ theme, withFilter }) => ({
  padding: `${theme.spacing(0.5)} 0`,
  height: theme.spacing(6),

  '.MuiInputBase-root': {
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: withFilter ? 0 : theme.shape.borderRadius,
    borderBottomLeftRadius: withFilter ? 0 : theme.shape.borderRadius,

    fieldset: {
      borderColor: alpha(theme.palette.primary.main, 0.2),
    },

    '&:hover, &:focus': {
      fieldset: {
        borderColor: alpha(theme.palette.primary.main, 0.5),
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    '.MuiInputBase-root': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  margin: `${theme.spacing(0.5)} 0`,
  marginRight: `-1px`,
  height: theme.spacing(5),
  backgroundColor: theme.palette.background.default,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,

  fieldset: {
    borderColor: alpha(theme.palette.primary.main, 0.2),
  },

  '&:hover, &:focus': {
    zIndex: 1,
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.primary.main, 0.5),
    },
  },

  '&.Mui-focused': {
    zIndex: 1,
  },
}));

const SearchField = ({ searchBy, onSearch }: TSearchFieldProps) => {
  const { t } = useTranslation('components');
  const [search, setSearch] = useState('');

  const handleClear = () => {
    setSearch('');
    onSearch('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(search.trim());
    }
  };

  return (
    <Stack direction="row" sx={{ flexGrow: 10, flexShrink: 1 }}>
      {searchBy && (
        <StyledSelect
          value={searchBy.value}
          onChange={searchBy.onChange}
          size="medium">
          {searchBy.options.map(({ value, title }) => (
            <MenuItem key={value} value={value}>
              {t(title)}
            </MenuItem>
          ))}
        </StyledSelect>
      )}
      <StyledTextField
        sx={{ flexGrow: 1, flexShrink: 1 }}
        size="small"
        value={search}
        placeholder={t('I18N_SEARCH') || ''}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={handleKeyDown}
        withFilter={Boolean(searchBy)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction="row" spacing={1}>
                {search.trim().length > 0 && (
                  <IconButton
                    onClick={handleClear}
                    edge="end"
                    size="small"
                    color="error">
                    <BackspaceIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => onSearch(search.trim())}
                  edge="end"
                  size="small"
                  color="primary">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </Stack>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default SearchField;
