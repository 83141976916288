import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

type TDateRangeFieldProps = {
  lable?: string;
  format?: string;
  valueFrom?: string;
  valueTo?: string;
  onUpdate: (type: string, value: string, label: string) => void;
  onDelete: (type: string) => void;
};

const FORMAT = "DDMMYYYY";
const DISPLAY_FORMAT = "LL";

const DateRangeField = ({
  format,
  valueFrom,
  valueTo,
  lable,
  onUpdate,
  onDelete,
}: TDateRangeFieldProps) => {
  const { t, i18n } = useTranslation("portrait");
  const [dateFrom, setDateFrom] = useState({
    type: "",
    value: "",
    label: "",
  });
  const [dateTo, setDateTo] = useState({
    type: "",
    value: "",
    label: "",
  });
  const formatedDate = format ? format : FORMAT;

  const handleChange = (dateType: "from" | "to") => (value: Dayjs | null) => {
    if (value == null) onDelete(dateType);
    else {
      const date = {
        type: dateType,
        value: value.format(formatedDate),
        label: value.format(DISPLAY_FORMAT),
      };
      if (dateType === "from") setDateFrom(date);
      if (dateType === "to") setDateTo(date);
    }
  };
  const handleSetDates = () => {
    if (dateFrom.value) onUpdate(dateFrom.type, dateFrom.value, dateFrom.label);
    if (dateTo.value) onUpdate(dateTo.type, dateTo.value, dateTo.label);
  };
  return (
    <Stack direction="row" spacing={1}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}>
        <Stack direction="row" flexGrow={1} spacing={2} alignItems="center">
          <DatePicker
            maxDate={valueTo ? dayjs(valueTo, formatedDate) : undefined}
            label={
              lable ? t(lable) : t("I18N_PORTRAIT_FILTER_DATE_FROM_BY_SAMPLE")
            }
            slotProps={{
              textField: { size: "small", fullWidth: true },
              field: { readOnly: true },
            }}
            value={valueFrom ? dayjs(valueFrom, formatedDate) : null}
            onChange={handleChange("from")}
            format={DISPLAY_FORMAT}
          />
          <Typography
            variant="body2"
            color="text.secondary"
            whiteSpace="nowrap">
            —
          </Typography>
          <DatePicker
            minDate={valueFrom ? dayjs(valueFrom, formatedDate) : undefined}
            label={t("I18N_PORTRAIT_FILTER_DATE_TO")}
            slotProps={{
              textField: { size: "small", fullWidth: true },
              field: { readOnly: true },
            }}
            value={valueTo ? dayjs(valueTo, formatedDate) : null}
            onChange={handleChange("to")}
            format={DISPLAY_FORMAT}
          />
        </Stack>
      </LocalizationProvider>
      <IconButton
        color="success"
        disabled={!dateFrom.value && !dateTo.value}
        onClick={handleSetDates}>
        <SearchIcon />
      </IconButton>
      <IconButton
        color="error"
        disabled={!valueFrom && !valueTo}
        onClick={() => onDelete("date")}>
        <FilterAltOffIcon />
      </IconButton>
    </Stack>
  );
};

export default DateRangeField;
