import { createApi } from "@reduxjs/toolkit/query/react";
import baseAppQuery from "./baseAppQuery";
import {
  IPortrait,
  IPortraitStateHistory,
  TPortraitFilterParams,
  ICreatePortrait,
} from "../types/portraits";
import { IState } from "../types/states";
import { TSearchParamsData } from "utils/PortraitsResearchData/types";
import { IPaginatedList } from "types/paginated";

const ENDPOINT = "portrait/api/v1";

export const portraitApi: any = createApi({
  reducerPath: "portraitApi",
  tagTypes: ["portraits"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getPortraitList: builder.query<
      IPaginatedList<IPortrait>,
      TPortraitFilterParams
    >({
      query: (queryFilters: TPortraitFilterParams) => {
        const queryKeys = Object.keys(queryFilters);
        if (queryKeys.length === 0) return `${ENDPOINT}/portraits`;
        const queryParams = new URLSearchParams();
        queryKeys.forEach((queryKey) => {
          const value: string | string[] = queryFilters[queryKey];
          if (Array.isArray(value))
            queryParams.set(queryKey, `${value.join(",")}`);
          else queryParams.set(queryKey, value);
        });
        return `${ENDPOINT}/portraits?${queryParams.toString()}`;
      },
      transformResponse(
        baseQueryReturnValue: {
          portraits: IPortrait[];
          portraits_count: number;
        },
        meta: { request: Request; response: Response },
        arg: TPortraitFilterParams
      ): IPaginatedList<IPortrait> {
        return {
          data: baseQueryReturnValue.portraits,
          pages: Math.ceil(
            baseQueryReturnValue.portraits_count / arg.page_size
          ),
        };
      },
    }),
    searchPortraits: builder.mutation<IPortrait[], TSearchParamsData>({
      query: (predicates: TSearchParamsData) => ({
        url: `${ENDPOINT}/portraits/search`,
        method: "POST",
        body: predicates,
      }),
      transformResponse(baseQueryReturnValue: {
        portraits: IPortrait[];
      }): IPortrait[] {
        return baseQueryReturnValue.portraits;
      },
    }),
    getPortraitById: builder.query<IPortrait, string>({
      query: (id: string) => `${ENDPOINT}/portraits/${id}`,
    }),

    postPortrait: builder.mutation<IPortrait, ICreatePortrait>({
      query: (payload: ICreatePortrait) => ({
        url: `${ENDPOINT}/portraits`,
        method: "POST",
        body: payload,
      }),
    }),
    putPortraitState: builder.mutation<
      IPortrait,
      { id: string; newState: string }
    >({
      query: ({ id, newState }) => ({
        url: `${ENDPOINT}/portraits/${id}/state/${newState}`,
        method: "PUT",
      }),
    }),
    getPortraitStateMap: builder.query<IState, IState>({
      query: () => `${ENDPOINT}/states/statemap`,
    }),
    putPortraitStateMap: builder.mutation<IState, IState>({
      query: () => ({
        url: `${ENDPOINT}/states/statemap`,
        method: "PUT",
      }),
    }),
    getPortraitsHistoryById: builder.query<IPortraitStateHistory, string>({
      query: (id) => `${ENDPOINT}/portraits/${id}/state_history`,
    }),
  }),
});

export const {
  useGetPortraitListQuery,
  useLazyGetPortraitListQuery,
  useSearchPortraitsMutation,
  useGetPortraitByIdQuery,
  useLazyGetPortraitByIdQuery,
  usePostPortraitMutation,
  usePutPortraitStateMutation,
  useGetPortraitStateMapQuery,
  usePutPortraitStateMapMutation,
  useGetPortraitsHistoryByIdQuery,
} = portraitApi;
export default portraitApi;
