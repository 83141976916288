import { createApi } from '@reduxjs/toolkit/query/react';
import baseAppQuery from './baseAppQuery';
import { IPermissions } from '../types/people';

const ENDPOINT = 'auth/api/v1/permissions';
export const permissionsApi: any = createApi({
  reducerPath: 'permissionsApi',
  tagTypes: ['permissions'],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getPermissionsList: builder.query<
      IPermissions,
      { searchBy: string; search: string }
    >({
      query: ({ searchBy, search }) => `${ENDPOINT}?${searchBy}=${search}`,
      providesTags: ['permissions'],
    }),
    getPermissionsById: builder.query<IPermissions, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
      providesTags: ['permissions'],
    }),
    addPermissions: builder.mutation<IPermissions, IPermissions>({
      query: (data: IPermissions) => ({
        url: ENDPOINT,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['permissions'],
    }),
    updatePermissions: builder.mutation<IPermissions, IPermissions>({
      query: (data: IPermissions) => ({
        url: `${ENDPOINT}/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['permissions'],
    }),
    deletePermissions: builder.mutation<IPermissions, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['permissions'],
    }),
  }),
});

export const {
  useGetPermissionsListQuery,
  useGetPermissionsByIdQuery,
  useAddPermissionsMutation,
  useUpdatePermissionsMutation,
  useDeletePermissionsMutation,
} = permissionsApi;
export default permissionsApi;
