import React, { Suspense } from 'react';
const SampleCreate = React.lazy(() => import('./SampleCreate'));

const LazySampleCreate = () => (
  <Suspense fallback={<div />}>
    <SampleCreate />
  </Suspense>
);

export default LazySampleCreate;
