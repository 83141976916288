import {
  TLoggerFilters,
  TLoggerFilterMethods,
} from "hooks/useLoggerFilter/types";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import AppliedFilter from "../../../../../../components/PortraitFormDataGrid/PortraitDataGrid/PortraitFilters/AppliedFilter";
import DateFilter from "../../../../../../components/PortraitFormDataGrid/PortraitDataGrid/PortraitFilters/DateFilter";

const FiltersContainer = styled(Stack)(({ theme }) => ({
  border: "1px solid rgb(239,246,252)",
  backgroundColor: "rgba(239,246,252,0.5)",
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  marginBottom: "-8px !important",
}));

type TLoggerFiltersProps = {
  filters: TLoggerFilters;
  methods: TLoggerFilterMethods;
};

const LoggerFilter = ({ filters, methods }: TLoggerFiltersProps) => {
  const { t } = useTranslation("loggers");

  return (
    <FiltersContainer direction="row" spacing={0} flexWrap="wrap">
      {filters.userid.value && (
        <AppliedFilter
          filterType="userid"
          label={t("I18N_LOGGER_USER")}
          value={filters.userid.value}
          options={filters.userid.options}
          inputType="select"
          onChange={methods.updateFilter}
          onRemove={methods.deleteFilter}
        />
      )}
      {filters.operation.value && (
        <AppliedFilter
          filterType="operation"
          label={t("I18N_LOGGER_OPERATION")}
          value={filters.operation.value}
          options={filters.operation.options}
          inputType="select"
          onChange={methods.updateFilter}
          onRemove={methods.deleteFilter}
        />
      )}
      {filters.status.value && (
        <AppliedFilter
          filterType="status"
          label={t("I18N_LOGGER_STATUS_CODE")}
          value={filters.status.value}
          options={filters.status.options}
          inputType="select"
          onChange={methods.updateFilter}
          onRemove={methods.deleteFilter}
        />
      )}
      {filters.remote_ip.value && (
        <AppliedFilter
          inputType="text"
          filterType="remote_ip"
          label={t("I18N_LOGGER_REMOTE_IP")}
          value={filters.remote_ip.value}
          onChange={methods.updateFilter}
          onRemove={methods.deleteFilter}
        />
      )}
      {(filters.from.value || filters.to.value) && (
        <DateFilter
          format="YYYY-MM-DD"
          valueFrom={filters.from.value}
          valueTo={filters.to.value}
          onChange={methods.updateFilter}
          onRemove={methods.deleteFilter}
        />
      )}
      <Button
        startIcon={<CancelIcon />}
        size="small"
        sx={{ mt: -0.6 }}
        onClick={() => methods.deleteFilter("all")}>
        {t("I18N_LOGGER_DELETE_FILTERS")}
      </Button>
    </FiltersContainer>
  );
};

export default LoggerFilter;
