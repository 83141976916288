import React, { Suspense } from 'react';

const DictionaryCreate = React.lazy(() => import('./DictionaryCreate'));

const LazyDictionaryCreate = () => (
  <Suspense fallback={<div />}>
    <DictionaryCreate />
  </Suspense>
);

export default LazyDictionaryCreate;
