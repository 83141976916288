import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FieldProps } from '../../types/fields';

type BasicAutocompleteProps = FieldProps & {
  multiple: boolean;
  disableClearable: boolean;
  options: { [key: string]: string }[];
  error: boolean;
  helperText: React.ReactNode;
};

const BasicAutocomplete = ({
  options,
  multiple,
  disableClearable,
  label,
  field,
  error = false,
  helperText,
}: BasicAutocompleteProps) => {
  return (
    <Autocomplete
      multiple={multiple}
      fullWidth
      options={options}
      getOptionLabel={(option) => option.name || ''}
      disableClearable={disableClearable}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={field.value}
      onChange={(event, value) => field.onChange(value)}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default BasicAutocomplete;
