import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import dictApi from "../services/dictionaries";
import dictTypeApi from "../services/dictType";
import productTypeApi from "../services/productTypes";
import filesApi from "../services/fileInfo";
import researchApi from "../services/researches";
import researchPlanApi from "../services/researchPlans";
import peopleApi from "../services/people";
import rolesApi from "../services/roles";
import permissionsApi from "../services/permissions";
import samplesApi from "../services/samples";
import modelsApi from "../services/models";
import markersApi from "../services/markers";
import toolsApi from "../services/tools";
import portraitApi from "../services/portrait";
import stateMapApi from "../services/stateMap";
import tokenApi from "../services/token";
import serviceApi from "../services/service";
import loggersApi from "../services/loggers";
import { authReducer } from "./slices/auth";
import { alertReducer } from "./slices/alert";
import { portraitReducer } from "./slices/portrait";
import { setAlert } from "./slices/alert";
import i18next from "i18next";
import { pathErrorReducer, setError } from "./slices/pathError";

const globalErrorLogger = (api) => (next) => (action) => {
  const isLoginEndpoint = action?.meta?.arg?.endpointName === "login";
  const isServiceEndpoint = action?.meta?.arg?.endpointName === "getServiceModeState";
  const isSettingsEndpoint = window.location.pathname === "/settings";

  if (action?.meta?.baseQueryMeta?.response?.status === 404) {
    api.dispatch(
      setError({
        hasError: action?.meta?.baseQueryMeta?.response?.status === 404,
      })
    );
  }

  if (isRejectedWithValue(action) && !isLoginEndpoint && !isServiceEndpoint && !isSettingsEndpoint) {
    const rejected = Boolean(isRejectedWithValue(action));
    api.dispatch(
      setAlert({
        open: rejected,
        message: i18next.t("I18N_DATA_ERROR_ALERT", {
          ns: "components",
          code: action.payload?.originalStatus || action.payload?.status,
        }),
      })
    );
  }
  return next(action);
};

export const rootReducer = {
  auth: authReducer,
  alert: alertReducer,
  pathError: pathErrorReducer,
  portrait: portraitReducer,
  [dictTypeApi.reducerPath]: dictTypeApi.reducer,
  [productTypeApi.reducerPath]: productTypeApi.reducer,
  [dictApi.reducerPath]: dictApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [peopleApi.reducerPath]: peopleApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [samplesApi.reducerPath]: samplesApi.reducer,
  [researchApi.reducerPath]: researchApi.reducer,
  [researchPlanApi.reducerPath]: researchPlanApi.reducer,
  [modelsApi.reducerPath]: modelsApi.reducer,
  [markersApi.reducerPath]: markersApi.reducer,
  [toolsApi.reducerPath]: toolsApi.reducer,
  [portraitApi.reducerPath]: portraitApi.reducer,
  [stateMapApi.reducerPath]: stateMapApi.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [loggersApi.reducerPath]: loggersApi.reducer,
  [tokenApi.reducerPath]: tokenApi.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dictTypeApi.middleware,
      productTypeApi.middleware,
      dictApi.middleware,
      filesApi.middleware,
      researchApi.middleware,
      researchPlanApi.middleware,
      peopleApi.middleware,
      rolesApi.middleware,
      permissionsApi.middleware,
      samplesApi.middleware,
      modelsApi.middleware,
      markersApi.middleware,
      toolsApi.middleware,
      portraitApi.middleware,
      stateMapApi.middleware,
      serviceApi.middleware,
      loggersApi.middleware,
      tokenApi.middleware,
      globalErrorLogger
    ),
});

setupListeners(store.dispatch);
