import { IDownloadFileInfo } from "../types/file";
import { useState, useEffect } from "react";
import mime from "mime-types";
import JSZip from "jszip";
import filesApi from "./fileInfo";
import useAppDispatch from "../hooks/useAppDispatch";
import {
  FileDownloadInfo,
  FileDownloadMultiInfo,
  FileInfo,
  FileUploadInfo,
} from "../types/file";
import { useGetDownloadFileInfoQuery } from "./fileInfo";
import { BASE_APP_URL } from "./baseUrls";

const executeFileDownload = (
  token: string,
  fileId: string,
  onFinished: (file: Blob) => void,
  onAbort: (error?: string) => void,
  onProgress: (progress: number) => void
) => {
  const xhr = new XMLHttpRequest();

  xhr.onload = () => {
    if (!xhr) return;
    onFinished(xhr.response);
  };

  xhr.onerror = () => {
    onAbort("An error occurred during file download");
  };

  xhr.onabort = () => {
    onAbort();
  };

  xhr.onprogress = (event) => {
    // event.loaded returns how many bytes are downloaded
    // event.total returns the total number of bytes
    // event.total is only available if server sends `Content-Length` header
    const currentProgress = Math.floor(100 * (event.loaded / event.total));
    onProgress(Math.min(currentProgress, 99));
  };

  xhr.open("GET", `${BASE_APP_URL}files/get/${fileId}`);
  xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  xhr.responseType = "blob";

  xhr.send();

  return xhr;
};

export const useFileUpload = (): FileUploadInfo => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<FileInfo | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [xhr, setXhr] = useState<XMLHttpRequest | undefined>();
  const [fileToUpload, setFileToUpload] = useState<File | undefined>();

  useEffect(() => {
    if (xhr && fileToUpload) {
      xhr.onload = () => {
        if (!xhr) return;
        setUploadedFile(xhr.response as FileInfo);
        setProgress(100);
        setError(undefined);
        setLoading(false);
        setFileToUpload(undefined);
        setXhr(undefined);
      };

      xhr.upload.onerror = () => {
        setUploadedFile(undefined);
        setProgress(0);
        setError("An error occurred during file upload");
        setLoading(false);
      };

      xhr.upload.onabort = () => {
        setUploadedFile(undefined);
        setProgress(0);
        setError(undefined);
        setLoading(false);
      };

      xhr.upload.onprogress = (event) => {
        // event.loaded returns how many bytes are downloaded
        // event.total returns the total number of bytes
        // event.total is only available if server sends `Content-Length` header
        const currentProgress = Math.floor(100 * (event.loaded / event.total));
        setProgress(Math.min(currentProgress, 99));
      };

      xhr.open("POST", `${BASE_APP_URL}files/upload`);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("accessToken") || ""}`
      );
      xhr.responseType = "json";

      const formData = new FormData();
      formData.append("file", fileToUpload);
      xhr.send(formData);
    }
  }, [xhr, fileToUpload]);

  const uploadFile = (file: File) => {
    const newXhr = new XMLHttpRequest();
    setXhr(newXhr);
    setFileToUpload(file);
    setUploadedFile(undefined);
    setProgress(0);
    setError(undefined);
    setLoading(true);
  };

  const cancelLoading = () => {
    if (xhr) {
      xhr.abort();

      setXhr(undefined);
      setFileToUpload(undefined);
    }
  };

  return {
    file: uploadedFile,
    progress,
    uploadFile,
    cancelLoading,
    error,
    isLoading: loading,
  } as FileUploadInfo;
};

export const useFileDownload = (): FileDownloadInfo => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [fileId, setFileId] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const [storedFileName, setStoredFileName] = useState<string | undefined>();
  const [storedFileData, setStoredFileData] = useState<
    IDownloadFileInfo | undefined
  >();

  const { data: fileData } = useGetDownloadFileInfoQuery(fileId, {
    skip: !fileId,
  });

  let xhr: XMLHttpRequest | undefined;

  const onFinished = (file: Blob) => {
    const a = document.createElement("a");
    a.download = `${storedFileName}.${mime.extension(fileData.type)}`;
    a.href = window.URL.createObjectURL(file);
    a.click();
    setProgress(100);
    setError(undefined);
    setLoading(false);
    xhr = undefined;

    setFileId(undefined);
    setStoredFileData(undefined);
    dispatch(filesApi.util.resetApiState());
  };

  const onAbort = (err?: string) => {
    setProgress(0);
    setError(err);
    setLoading(false);
  };

  const onProgress = (val: number) => {
    setProgress(val);
  };

  const triggerDownload = () => {
    if (!fileData) return;
    xhr = executeFileDownload(
      localStorage.getItem("accessToken") || "",
      fileId || "",
      onFinished,
      onAbort,
      onProgress
    );
  };

  useEffect(() => {
    if (fileData && storedFileData?.id !== fileData.id) {
      setStoredFileData(fileData);
      triggerDownload();
    }
  }, [fileData]);

  const downloadFile = async (fileName: string, id: string) => {
    setFileId(id);
    setStoredFileName(fileName);
    setProgress(0);
    setError(undefined);
    setLoading(true);
  };

  const cancelLoading = () => {
    if (xhr) xhr.abort();
  };

  return {
    progress,
    downloadFile,
    cancelLoading,
    error,
    isLoading: loading,
  } as FileDownloadInfo;
};

export const useFileDownloadMulti = (): FileDownloadMultiInfo => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [fileIds, setFileIds] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [storedFileName, setStoredFileName] = useState<string | undefined>();
  const [storedFileData, setStoredFileData] = useState<
    IDownloadFileInfo | undefined
  >();
  const [loadedFiles, setLoadedFiles] = useState<
    Array<{ name: string; file: Blob }>
  >([]);
  const [zipping, setZipping] = useState(false);

  const { data: fileData } = useGetDownloadFileInfoQuery(
    fileIds[currentIndex],
    { skip: currentIndex < 0 || !fileIds[currentIndex] }
  );

  let xhr: XMLHttpRequest | undefined;

  useEffect(() => {
    setCurrentIndex(0);
  }, [fileIds]);

  const getProgress = (idx: number, curProgress: number) => {
    const adjustedProgress = Math.floor(curProgress / fileIds.length);
    const previousProgress = idx * Math.floor(100 / fileIds.length);
    return previousProgress + adjustedProgress;
  };

  const onFinished = (file: Blob) => {
    setLoadedFiles([
      ...loadedFiles,
      {
        name: `${storedFileName}.${currentIndex + 1}.${mime.extension(
          fileData.type
        )}`,
        file,
      },
    ]);
    setProgress(getProgress(currentIndex, 100));
    setCurrentIndex(currentIndex + 1);
  };

  const onAbort = (err?: string) => {
    setProgress(0);
    setError(err);
    setLoading(false);
  };

  const onProgress = (val: number) => {
    setProgress(getProgress(currentIndex, val));
  };

  const executeZip = async () => {
    setZipping(true);

    const zip = new JSZip();
    const fname = storedFileName || "files";
    const folder = zip.folder(fname);

    loadedFiles.forEach(({ name, file }) => {
      folder?.file(name, file);
    });

    const zippedContent = await zip.generateAsync({ type: "blob" });

    const a = document.createElement("a");
    a.download = `${fname}.zip`;
    a.href = window.URL.createObjectURL(zippedContent);
    a.click();

    setLoading(false);
  };

  const triggerDownload = () => {
    if (!fileData) return;
    if (currentIndex < fileIds.length) {
      xhr = executeFileDownload(
        localStorage.getItem("accessToken") || "",
        fileIds[currentIndex] || "",
        onFinished,
        onAbort,
        onProgress
      );
    }
  };

  const startDownloads = () => {
    setLoading(true);
    setStoredFileData(undefined);
    setLoadedFiles([]);
    setCurrentIndex(0);
    setZipping(false);
  };

  useEffect(() => {
    if (fileData && storedFileData?.id !== fileData.id) {
      setStoredFileData(fileData);
      triggerDownload();
    }
  }, [
    fileData,
    loadedFiles,
    triggerDownload,
    onFinished,
    onAbort,
    onProgress,
    executeZip,
  ]);

  useEffect(() => {
    if (
      !zipping &&
      fileIds.length > 0 &&
      currentIndex >= fileIds.length &&
      loadedFiles.length === fileIds.length
    ) {
      executeZip();
    }
  }, [loadedFiles, fileIds, currentIndex, zipping, executeZip]);

  const downloadFiles = async (fileName: string, ids: string[]) => {
    setStoredFileName(fileName);
    setFileIds(ids);
    startDownloads();
  };

  const cancelLoading = () => {
    if (xhr) xhr.abort();
  };

  return {
    progress,
    downloadFiles,
    cancelLoading,
    error,
    isLoading: loading,
  } as FileDownloadMultiInfo;
};
