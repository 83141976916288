import { TLoggerFilters, TLoggerFilterParams } from "../types";

export function toQueryParams(filters: TLoggerFilters): TLoggerFilterParams {
  const rv: TLoggerFilterParams = {};

  if (filters.from.value) rv.from = filters.from.value.value;
  if (filters.to.value) rv.to = filters.to.value.value;
  if (filters.name.value) rv.name = filters.name.value.value;
  if (filters.remote_ip.value) rv.remote_ip = filters.remote_ip.value.value;
  if (filters.operation.value) rv.operation = filters.operation.value.value;
  if (filters.userid.value) rv.userid = filters.userid.value.value;
  if (filters.status.value) rv.status = filters.status.value.value;
  if (filters.sort?.field && filters.sort?.order)
    rv.sort = {
      order: filters.sort.order,
      field: filters.sort.field,
    };

  return rv;
}
