import { alpha, styled } from "@mui/material/styles";
import MuiTableRow from "@mui/material/TableRow";

const ClickableRow = styled(MuiTableRow)(({ theme }) => ({
  ".hidden-buttons > button": {
    opacity: 0,
  },

  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    cursor: "pointer",

    ".hidden-buttons > button": {
      opacity: 1,
    },
  },

  "&.KAP-row-with-arrow>th:last-of-type": {
    position: "relative",
  },

  "&.KAP-row-with-arrow>th:last-of-type:after": {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1.25),

    width: 0,
    height: 0,
    borderTop: `${theme.spacing(2)} solid transparent`,
    borderBottom: `${theme.spacing(2)} solid transparent`,
    borderRight: `${theme.spacing(2)} solid ${alpha(
      theme.palette.primary.main,
      0.4
    )}`,
    content: '" "',
  },
}));

export default ClickableRow;
