import { useMemo } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "../pages/App";
import RestorePassword from "../pages/App/RestorePassword";
import ResetPassword from "../pages/App/ResetPassword/ResetPassword";
import NotFound from "../pages/NotFound";
import useTokenData from "../hooks/useTokenData";
import { prepareAllRouteObjects } from "../router";

const RouteProviderWrapper = () => {
  const { sub } = useTokenData();
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/",
          element: <App />,
          errorElement: <NotFound />,
          children: prepareAllRouteObjects(),
        },
        {
          path: "/restore-password",
          element: <RestorePassword />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
      ]),
    [sub]
  );

  return <RouterProvider router={router} />;
};
export default RouteProviderWrapper;
