import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

export const EditButton = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      color="success"
      size="medium"
      sx={{ padding: 0 }}
      onClick={() => navigate(`edit/${id}`)}>
      <EditIcon color="success" fontSize="medium" />
    </IconButton>
  );
};

interface DeleteButtonProps {
  onDelete: () => void;
  isDeletable?: boolean;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onDelete, isDeletable }) => {
  return (
    <IconButton
      color="error"
      size="medium"
      sx={{ padding: 0 }}
      onClick={onDelete}
      disabled={!isDeletable}
    >
      <DeleteIcon color={isDeletable ? "error" : "disabled"} fontSize="medium" />
    </IconButton>
  );
};
