import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ShowPasswordButtonProps = {
  showPassword: boolean;
  onClick: () => void;
  onMouseDown: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const ShowPasswordButton = ({
  showPassword,
  onClick,
  onMouseDown,
}: ShowPasswordButtonProps) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onClick}
        onMouseDown={onMouseDown}
        edge="end">
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

type PasswordFieldProps = {
  label: string;
  fieldName?: string;
  validate?: any;
};

const PasswordField = ({
  label,
  fieldName = "password",
  validate,
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation(["login", "components"]);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...register(fieldName, {
        required: {
          value: true,
          message: t("I18N_REQUIRED_FIELD", { ns: "components" }),
        },
        validate: validate,
      })}
      label={label}
      type={showPassword ? "text" : "password"}
      fullWidth
      required
      error={!!errors[fieldName]}
      helperText={errors[fieldName]?.message?.toString()}
      InputProps={{
        endAdornment: (
          <ShowPasswordButton
            showPassword={showPassword}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          />
        ),
      }}
    />
  );
};
export default PasswordField;
