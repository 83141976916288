import { PropsWithChildren, ReactNode } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import ButtonBox from "../components/ButtonBox";

type FormSectionProps = {
  onSubmit: SubmitHandler<any>;
  isLoading: boolean;
  formId: string;
  btnText?: string;
  btnDisabled?: boolean;
  isEditPage?: boolean;
  isCreatePage?: boolean;
  actionSlot?: ReactNode;
};

const FormSection = ({
  isLoading,
  formId,
  onSubmit,
  children,
  btnText = "I18N_ADD_CREATE",
  btnDisabled = false,
  isEditPage,
  actionSlot,
}: PropsWithChildren<FormSectionProps>) => {
  const { t } = useTranslation("components");
  const { handleSubmit } = useFormContext();
  const navigate = useNavigate();
  return (
    <Box id={formId} component="form" onSubmit={handleSubmit(onSubmit)}>
      {children}
      <ButtonBox direction="row">
        {isEditPage && (
          <Button
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}>
            {t("I18N_ADD_CANCEL")}
          </Button>
        )}
        {actionSlot && actionSlot}
        <LoadingButton
          type="submit"
          form={formId}
          variant="contained"
          disabled={btnDisabled}
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}>
          {t(btnText)}
        </LoadingButton>
      </ButtonBox>
    </Box>
  );
};

export default FormSection;
