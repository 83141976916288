import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MenuProps } from "@mui/material/Menu";
import FlagKZ from "./FlagKZ";
import FlagRU from "./FlagRU";
import FlagEN from "./FlagEN";

type TLanguageMenuProps = MenuProps & {
  onSelectLanguage: (lang: "kk" | "ru" | "en") => void;
};

const LanguageMenu = ({
  onSelectLanguage,
  ...menuProps
}: TLanguageMenuProps) => {
  const { t } = useTranslation("nav");

  return (
    <Menu id="language-selection-menu" {...menuProps}>
      <MenuItem onClick={() => onSelectLanguage("kk")}>
        <ListItemIcon>
          <FlagKZ />
        </ListItemIcon>
        <ListItemText>{t("I18N_LANGUAGE_KZ")}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => onSelectLanguage("ru")}>
        <ListItemIcon>
          <FlagRU />
        </ListItemIcon>
        <ListItemText>{t("I18N_LANGUAGE_RU")}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => onSelectLanguage("en")}>
        <ListItemIcon>
          <FlagEN />
        </ListItemIcon>
        <ListItemText>{t("I18N_LANGUAGE_EN")}</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default LanguageMenu;
