import React, { useEffect } from "react";
import { IPaginatedList } from "../types/paginated";


const usePagination = (data: IPaginatedList<any>, storageName: string) => {
  const storedData = JSON.parse(sessionStorage.getItem(storageName) as string);
  const [page, setPage] = React.useState(storedData?.page || 1);
  const [size, setPageSize] = React.useState(storedData?.size || 10);
  const [prevSize, setPrevSize] = React.useState(10);
  const [pages, setPages] = React.useState(1);

  useEffect(() => {
    setPages(data?.pages || 1);
  }, [data?.pages]);

  useEffect(() => {
    if (size !== prevSize) {
      setPrevSize(size);
      setPages(1);
    }
  }, [size, prevSize]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);

    sessionStorage.setItem(
      storageName,
      JSON.stringify({ page: newPage + 1, size: size })
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPages(1);
    setPage(1);
    sessionStorage.setItem(
      storageName,
      JSON.stringify({ page: 1, size: +event.target.value })
    );
  };

  const resetPagination = () => {
    setPageSize(10);
    setPage(1);
    setPages(data?.pages || 1);
    sessionStorage.removeItem(storageName);
  };

  return {
    page,
    size,
    pages,
    resetPagination,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

export default usePagination;
