import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TPortraitFilterOptions,
  TPortraitFilterValue,
} from "../../types/portraits";
import { SyntheticEvent } from "react";

type TAutocompleteFieldProps = {
  filterType: string;
  value?: string;
  id: string;
  label: string;
  options?: TPortraitFilterOptions;
  noEmptyValue?: boolean;
  onUpdate: (type: string, value: string, label: string) => void;
  onDelete: (type: string) => void;
};

const AutocompleteField = ({
  id,
  filterType,
  value,
  label,
  options,
  noEmptyValue,
  onUpdate,
  onDelete,
}: TAutocompleteFieldProps) => {
  const handleChange = (
    event: SyntheticEvent,
    val: TPortraitFilterValue | null
  ) => {
    if (val == null) onDelete(filterType);
    else {
      onUpdate(filterType, val.value, val.label);
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <Autocomplete
        fullWidth
        size="small"
        id={id}
        disabled={!options}
        options={options?.list || []}
        disableClearable={noEmptyValue}
        renderInput={(params) => <TextField {...params} label={label} />}
        value={options?.tree[value || ""] || null}
        onChange={handleChange}
      />
      <IconButton
        color="error"
        disabled={!value}
        onClick={() => onDelete(filterType)}>
        <FilterAltOffIcon />
      </IconButton>
    </Stack>
  );
};

export default AutocompleteField;
