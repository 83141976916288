import { useTranslation } from "react-i18next";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import { Button, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {
  useGetServiceModeStateQuery,
  useSetServiceModeStateMutation,
} from "../../../../services/service";
import cn from "classnames";
import ConfirmPrompt from "../../../../components/Prompts/ConfirmPrompt";
import { useState } from "react";

const ContentContainer = styled(Stack)(() => ({
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
}));

const ServiceStatus = styled(Typography)(() => ({}));

const SatusRing = styled(Stack)(({ theme }) => ({
  width: "15px",
  height: "15px",
  borderRadius: "50%",
  "&.stop": {
    backgroundColor: theme.palette.error.light,
  },
  "&.run": {
    backgroundColor: theme.palette.success.light,
    animation: "blink 0.8s infinite alternate",
    "@keyframes blink": {
      "0%": {
        opacity: 0.1,
      },
      "100%": {
        opacity: 1,
      },
    },
  },
}));

const BoxBack = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(5),
}));

const ServiceMode = () => {
  const { t } = useTranslation("components");
  const [open, setOpen] = useState(false);
  const { error } = useGetServiceModeStateQuery();
  const [setMode] = useSetServiceModeStateMutation();

  const responstStatus: boolean = error?.data === "service mode is true";
  const buttonText: string = responstStatus
    ? "I18N_SERVICE_MODE_END"
    : "I18N_SERVICE_MODE_START";
  const titleText: string = responstStatus
    ? "I18N_SERVICE_MODE_END_TEXT"
    : "I18N_SERVICE_MODE_START_TEXT";

  const handleClick = () => setMode(!responstStatus);

  return (
    <Page>
      <Header label={t("I18N_SERVICE_MODE_HEADER")} hideCloseBtn={true} />
      <ContentContainer>
        <BoxBack spacing={3} alignItems={"center"}>
          <Stack spacing={1} direction="row" alignItems={"center"}>
            <ServiceStatus>{t(titleText)}</ServiceStatus>
            <SatusRing
              className={cn({ stop: responstStatus, run: !responstStatus })}
            />
          </Stack>
          <Button
            variant={responstStatus ? "contained" : "outlined"}
            color={responstStatus ? "success" : "error"}
            onClick={() => setOpen(true)}>
            {t(buttonText)}
          </Button>
        </BoxBack>
      </ContentContainer>
      <ConfirmPrompt
        open={open}
        title={t("I18N_SERVICE_MODE_PROMPT_TITLE")}
        message={t("I18N_SERVICE_MODE_PROMPT_MESSAGE")}
        onConfirm={() => {handleClick()
          setOpen(false)
        }}
        onCancel={() => setOpen(false)}
      />
    </Page>
  );
};
export default ServiceMode;
