import { PropsWithChildren } from "react";
import {
  Accordion,
  AccordionBody,
} from "../../../../../components/DetailsView/AccordionItem";
import { CustomAccordionHeader } from "../../../../../components/ResearchPlanAccordion";
import { AccordionTitle } from "../../../../../components/ResearchPlanAccordion/Titles/style";
import { ILogger } from "../../../../../types/loggers";
import { useGetPeopleByIdQuery } from "../../../../../services/people";
import { getTimeDate } from "../../../../../utils/dates";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const getUserName = (id: string) => {
  const { data: user } = useGetPeopleByIdQuery(id, {
    skip: !id || id === "unknown",
  });
  return <>{!id || id === "unknown" ? "unknown user" : user?.name}</>;
};

const SystemLogAccordion = ({
  accordionData,
  children,
}: PropsWithChildren<{ accordionData: ILogger }>) => {
  return (
    <>
      <Accordion>
        <CustomAccordionHeader>
          <AccordionTitle sx={{ width: "14%" }}>
            {getTimeDate(accordionData.created)}
          </AccordionTitle>
          <Tooltip
            title={accordionData.userid || "unkown id"}
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -25],
                    },
                  },
                ],
              },
            }}>
            <AccordionTitle sx={{ width: "14%" }}>
              {accordionData.name || getUserName(accordionData.userid)}
            </AccordionTitle>
          </Tooltip>
          <AccordionTitle sx={{ width: "14%" }}>
            {accordionData.operation}
          </AccordionTitle>
          <AccordionTitle sx={{ width: "14%" }}>
            {accordionData.status}
          </AccordionTitle>
          <AccordionTitle sx={{ width: "14%" }}>
            {accordionData.remote_ip}
          </AccordionTitle>
          <AccordionTitle sx={{ width: "30%", wordWrap: "break-word" }}>
            <Typography variant="body2">{accordionData.uri}</Typography>
          </AccordionTitle>
        </CustomAccordionHeader>
        <AccordionBody sx={{ padding: "0" }}>{children}</AccordionBody>
      </Accordion>
    </>
  );
};
export default SystemLogAccordion;
