import React, { Suspense } from 'react';

const ProductTypeCreate = React.lazy(() => import('./ProductTypeCreate'));

const LazyProductTypeCreate = () => (
  <Suspense fallback={<div />}>
    <ProductTypeCreate />
  </Suspense>
);

export default LazyProductTypeCreate;
