import { createApi } from '@reduxjs/toolkit/query/react';
import { IRoles } from '../types/people';
import baseAppQuery from './baseAppQuery';

const ENDPOINT = 'auth/api/v1/roles';
export const rolesApi: any = createApi({
  reducerPath: 'rolesApi',
  tagTypes: ['roles'],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getRolesList: builder.query<IRoles, { search: string }>({
      query: ({ search }) => `${ENDPOINT}?name=${search}`,
      providesTags: ['roles'],
    }),
    getRolesById: builder.query<IRoles, string>({
      query: (id: string) => `${ENDPOINT}/${id}`,
      providesTags: ['roles'],
    }),
    addRoles: builder.mutation<IRoles, IRoles>({
      query: (data: IRoles) => ({
        url: ENDPOINT,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['roles'],
    }),
    updateRoles: builder.mutation<IRoles, IRoles>({
      query: (data: IRoles) => ({
        url: `${ENDPOINT}/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['roles'],
    }),
    bindPermissionToRole: builder.mutation<
      IRoles,
      { roleId: string; permissionId: string; unbind: boolean }
    >({
      query: ({
        roleId,
        permissionId,
        unbind,
      }: {
        roleId: string;
        permissionId: string;
        unbind: boolean;
      }) => ({
        url: `${ENDPOINT}/${roleId}/permissions/${permissionId}?unbind=${unbind}`,
        method: 'PATCH',
      }),
    }),
    deleteRoles: builder.mutation<IRoles, string>({
      query: (id: string) => ({
        url: `${ENDPOINT}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['roles'],
    }),
  }),
});

export const {
  useGetRolesListQuery,
  useGetRolesByIdQuery,
  useAddRolesMutation,
  useUpdateRolesMutation,
  useBindPermissionToRoleMutation,
  useDeleteRolesMutation,
} = rolesApi;
export default rolesApi;
