import React, { Suspense } from 'react';
const ResearchForm = React.lazy(() => import('../ResearchView/ResearchView'));

const LazyResearchForm = () => (
  <Suspense fallback={<div />}>
    <ResearchForm />
  </Suspense>
);

export default LazyResearchForm;
