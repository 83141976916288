import React, { Suspense } from "react";

const Samples = React.lazy(() => import("./Samples"));

const LazySamples = () => (
  <Suspense fallback={<div />}>
    <Samples />
  </Suspense>
);

export default LazySamples;
