import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import StyledPrompt from "./Prompt";
import CloseIcon from "@mui/icons-material/Close";
import { Stack, Typography, IconButton } from "@mui/material";

export interface IConfirmPromptProps {
  open: boolean;
  title: string;
  message: string;
  type?: string;
  loading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmPrompt = ({
  open,
  title,
  message,
  loading = false,
  onConfirm,
  onCancel,
}: IConfirmPromptProps) => {
  const { t } = useTranslation("components");

  return (
    <StyledPrompt open={open} fullWidth>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{title}</Typography>
        <IconButton color="error" onClick={onCancel}>
          <CloseIcon color="error" />
        </IconButton>
      </Stack>
      <DialogContent sx={{ my: 2 }}>
        <DialogContentText fontSize={18}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={onConfirm}
          loading={loading}>
          {t("I18N_ADD_CONFIRM")}
        </LoadingButton>
        <Button variant="outlined" color="warning" onClick={onCancel}>
          {t("I18N_ADD_CANCEL")}
        </Button>
      </DialogActions>
    </StyledPrompt>
  );
};

export default ConfirmPrompt;
