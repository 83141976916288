import type { TFunction } from "i18next";

import type { TPortraitFilterOptions } from "../types/portraits";
import type { IModel } from "../types/models";

export function getDzo(
  sourceMap: { [key: string]: string },
  sourceCode: string | number,
  t: TFunction
) {
  const strCode = (String(sourceCode) || "00").padStart(2, "0");
  if (strCode === "00") return t("I18N_FILTER_VALUE_EXTERNAL");
  return sourceMap[strCode] || "—";
}

export function getProductType(
  productsMap: TPortraitFilterOptions,
  sourceCode: string | number,
  t: TFunction
) {
  const strCode = (String(sourceCode) || "00").padStart(2, "0");
  return productsMap.tree[strCode]?.label || "—";
}

export function getModelName(
  modelsMap: { [key: string]: IModel },
  modelId: string
) {
  const model = modelsMap[modelId];
  if (!model) return "";
  return `${model.name} — ${model.version}`;
}

export function getShortName(name: string): string {
  const [lastName, ...rest] = name.split(" ");
  const initials = rest
    .map((part, index) =>
      index === rest.length - 1 && part.length === 1
        ? ` ${part}.`
        : part
        ? ` ${part[0]}.`
        : ""
    )
    .join("");
  return `${lastName}${initials}`.trim();
}
