import { KJUR, b64utoutf8 } from 'jsrsasign';

import { TTokenData, IAccessTokenData, IRefreshTokenData } from '../../../types/token';

export const DEFAULT: IAccessTokenData = {
  exp: 0,
  iat: 0,
  nbf: 0,
  sub: '',
  user_name: '',
  typ: 'Bearer',
  roles: [],
  permissions: [],
  iss: '',
  jti: '',
  email: '',
};

export const getTokenData = (token: string): TTokenData | null => {
  const data = KJUR.jws.JWS.readSafeJSONString(
    b64utoutf8(token.split('.')[1]),
  ) as TTokenData | null;
  if (data == null) return null;
  return data;
};

export const getAccessTokenData = (token: string): IAccessTokenData | null => {
  const data = getTokenData(token);
  if (data == null || data.typ !== 'Bearer') return null;
  return data;
};

export const getRefreshTokenData = (token: string): IRefreshTokenData | null => {
  const data = getTokenData(token);
  if (data == null || data.typ !== 'Refresh') return null;
  return data;
};

export const getTokenState = (accessToken: string): IAccessTokenData => {
  const data = getAccessTokenData(accessToken);

  if (data) return data;
  return DEFAULT;
};
