import cn from 'classnames';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TaskIcon from '@mui/icons-material/Task';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

const ProgressContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: theme.spacing(5),
  height: theme.spacing(5),
  padding: theme.spacing(1),

  '.MuiSvgIcon-root': {
    color: theme.palette.text.disabled,
  },

  '&.finished': {
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  '&.error': {
    '.MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
}));

const Progress = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
}));

const ProgressValue = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: theme.spacing(3),
  width: theme.spacing(3),
  textAlign: 'center',
}));

const FileProgressLoader = ({
  progress,
  isError,
}: {
  progress: number;
  isError: boolean;
}) => (
  <ProgressContainer
    className={cn({ finished: progress >= 100 && !isError, error: isError })}>
    {isError && <SyncProblemIcon />}
    {!isError && (
      <>
        {progress === 0 && <UploadFileIcon />}
        {progress > 0 && progress < 100 && (
          <ProgressValue>
            {Math.round(progress)}
            <Progress variant="determinate" value={progress} color="primary" />
          </ProgressValue>
        )}
        {progress >= 100 && <TaskIcon />}
      </>
    )}
  </ProgressContainer>
);

export default FileProgressLoader;
