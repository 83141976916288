import dayjs from 'dayjs';
import i18next from 'i18next';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';

export function getShortDate(date?: string) {
  if (!date) return String.fromCharCode(8211);
  const formattedDate = dayjs(date)
    .locale(i18next.language)
    .format('DD MMM YYYY [г.]')
    .split(' ')
    .map((v, i) => (i === 1 ? `${v.substring(0, 3)}.` : v))
    .join(' ');
  return dayjs(date).isValid()
    ? formattedDate.replace(/\.{2,}/g, '.')
    : String.fromCharCode(8211);
}

export function getTimeDate(date: string) {
  const formattedDate = dayjs(date)
    .locale(i18next.language)
    .format('D MMMM YYYY [г.] HH:mm:ss');

  return dayjs(date).isValid() ? formattedDate : String.fromCharCode(8211);
}
export function getLongDate(date: string) {
  const formattedDate = dayjs(date)
    .locale(i18next.language)
    .format('D MMMM YYYY [г.]');
  return dayjs(date).isValid() ? formattedDate : String.fromCharCode(8211);
}

export function getGetTime(date: string) {
  const formattedDate = dayjs(date).format('HH:mm');
  return dayjs(date).isValid() ? formattedDate : String.fromCharCode(8211);
}