import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { injectToken, languageSwitch } from './injectHeaders';

export const BASE_APP_URL =
  location.hostname === process.env.REACT_APP_URL_HOSTNAME
    ? `/api/`
    : `${process.env.REACT_APP_KAP_URL}/api/`;

export const baseAppQuery = fetchBaseQuery({
  baseUrl: BASE_APP_URL,
  prepareHeaders: (headers, api) => {
    headers = injectToken(headers, api);
    headers = languageSwitch(headers);
    return headers;
  },
});