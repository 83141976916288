import Box from "@mui/material/Box";
import DataNotFound from "../../components/DataNotFound";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  hasData?: boolean;
}

const CustomTabPanel = ({
  children,
  value,
  index,
  hasData = true,
  ...other
}: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && hasData ? children : <DataNotFound />}
    </Box>
  );
};
export default CustomTabPanel;
