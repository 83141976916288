import { createSlice } from '@reduxjs/toolkit';

const portraitSlice = createSlice({
  name: 'portrait',
  initialState: {
    samples: {},
    models: [] as string[],
    modelOptions: {},
    portraits: [] as any,
    selectedData: [] as any,
  },
  reducers: {
    setFilteredSamples: (state, action) => {
      return { ...state, samples: { ...action.payload } };
    },
    setFilteredModels: (state, action) => {
      return { ...state, models: [...action.payload] };
    },
    setModelOptions: (state, action) => {
      return { ...state, modelOptions: { ...action.payload } };
    },
    setPortraitList: (state, action) => {
      return { ...state, portraits: [...action.payload] };
    },
    setSelectedDataList: (state, action) => {
      return { ...state, selectedData: [...action.payload] };
    },
  },
});

export const {
  setFilteredSamples,
  setFilteredModels,
  setModelOptions,
  setPortraitList,
  setSelectedDataList,
} = portraitSlice.actions;
export const portraitReducer = portraitSlice.reducer;
