import { createApi } from "@reduxjs/toolkit/query/react";
import baseAppQuery from "./baseAppQuery";

const ENDPOINT = "auth/api/v1/service_mode";
export const serviceApi: any = createApi({
  reducerPath: "serviceApi",
  tagTypes: ["service"],
  baseQuery: baseAppQuery,
  endpoints: (builder) => ({
    getServiceModeState: builder.query<string, null>({
      query: () => `${ENDPOINT}`,
      providesTags: ["service"],
    }),
    setServiceModeState: builder.mutation<null, boolean>({
      query: (data: boolean) => ({
        url: `${ENDPOINT}?enable=${data}`,
        method: "PATCH",
      }),
      invalidatesTags: ["service"],
    }),
  }),
});

export const { useGetServiceModeStateQuery, useSetServiceModeStateMutation } =
  serviceApi;
export default serviceApi;
