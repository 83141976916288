import { useTranslation } from "react-i18next";
import "dayjs/locale/ru";
import "dayjs/locale/kk";
import Page from "../../../../components/Page";
import Header from "../../../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import FormSection from "../../../../components/FormSection";
import { useEffect } from "react";
import FieldsGenerator from "../../../../components/Inputs/FieldsGenerator";
import { useGetPeopleListQuery } from "../../../../services/people";
import { ITools } from "../../../../types/tools";
import toolsApi, {
  useAddToolMutation,
  useBindUserToolMutation,
  useGetToolByIdQuery,
  useGetToolUsersByIdQuery,
  useUpdateToolMutation,
} from "../../../../services/tools";
import { compareTools, transformAutocomplete } from "./utils";
import { useDispatch } from "react-redux";

const formFields = [
  {
    name: "name",
    label: "I18N_TOOL_NAME",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "I18N_TOOL_DESCRIPTION",
    type: "text",
    multiline: true,
    required: true,
  },
  {
    name: "people",
    label: "I18N_TOOL_PEOPLE",
    type: "autocomplete",
    multiple: true,
    required: false,
    fullWidth: true,
  },
];

const ToolsCreate = () => {
  const { toolId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("tools");

  const { data: peopleList } = useGetPeopleListQuery({
    search: "",
    searchBy: "name",
  });
  const { data: tool } = useGetToolByIdQuery(toolId, { skip: !toolId });
  const { data: toolPeople } = useGetToolUsersByIdQuery(toolId, {
    skip: !toolId,
  });

  const [postTool] = useAddToolMutation();
  const [bindUser] = useBindUserToolMutation();
  const [updateTool] = useUpdateToolMutation();

  const method = useForm<ITools>({
    defaultValues: {
      name: tool?.name || "",
      description: tool?.descr || "",
      people: [],
    },
  });
  const { setValue } = method;

  useEffect(() => {
    if (tool) {
      setValue("name", tool.name);
      setValue("description", tool.descr);
    }
    if (toolPeople) {
      setValue("people", transformAutocomplete(toolPeople));
    }
  }, [tool, toolPeople]);

  const onSubmit: SubmitHandler<ITools> = async (data) => {
    try {
      if (tool) {
        const prepSubmit = compareTools(toolPeople, data.people);
        if (data.name !== tool.name || data.description !== tool.descr) {
          updateTool({
            id: tool.id,
            name: data.name,
            descr: data.description,
          });
        }
        prepSubmit.add.map(async (row) => {
          await bindUser({
            toolId: tool.id,
            userId: row,
            unBind: false,
          }).unwrap();
        });
        prepSubmit.delete.map(async (row) => {
          await bindUser({
            toolId: tool.id,
            userId: row,
            unBind: true,
          }).unwrap();
        });
      } else {
        const response = await postTool({
          name: data.name,
          descr: data.description,
        }).unwrap();

        data.people.map(async (row) => {
          await bindUser({
            toolId: response.id,
            userId: row.id,
            bind: false,
          }).unwrap();
        });
      }
      dispatch(toolsApi.util.resetApiState());
      navigate("/tools");
    } catch (error) {
      console.log(error);
    }
  };
  const headerLabel = toolId
    ? t("I18N_TOOLS_EDIT_TOOL")
    : t("I18N_TOOLS_ADD_TOOL");
  return (
    <Page>
      <Header label={headerLabel} permissions={"create_sample"} />
      <FormProvider {...method}>
        <FormSection
          isLoading={false}
          onSubmit={onSubmit}
          formId="create-tool-form"
          btnText={toolId ? "I18N_EDIT" : "I18N_ADD_CREATE"}
          isEditPage={Boolean(toolId)}
        >
          <FieldsGenerator
            fieldList={formFields}
            fullWidth
            locales="tools"
            selectOptions={{
              people: peopleList || [],
            }}
          />
        </FormSection>
      </FormProvider>
    </Page>
  );
};
export default ToolsCreate;
