import cn from "classnames";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack/";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

type FileDropzoneProps = {
  multiple: boolean;
  hasFiles?: boolean;
  onFileUpload: (files: File[]) => void;
};

const chooseLabels = {
  true: "I18N_UPLOAD_CHOOSE_FILE_MULTI",
  false: "I18N_UPLOAD_CHOOSE_FILE_SINGLE",
};

const dropLabels = {
  true: "I18N_UPLOAD_DRAGFILE_MULTI",
  false: "I18N_UPLOAD_DRAGFILE_SINGLE",
};

const DropzoneContent = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5),

  "&.withFiles": {
    border: "1px dashed",
    borderColor: theme.palette.text.disabled,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1),
    marginTop: 0,
  },
}));

const DropzoneButton = styled(Button)(({ theme }) => ({
  fontSize: "0.8125rem",
  lineHeight: "1.25rem",
  whiteSpace: "break-spaces",
  padding: `${theme.spacing(0.625)} ${theme.spacing(1)}`,
}));

const DropzoneLabel = styled(Typography)(() => ({
  fontSize: "0.875rem",
  lineHeight: "1rem",
  whiteSpace: "break-spaces",
  overflow: "ellipsis",
  flexGrow: 1,
  flexShrink: 1,
}));

const FileDropzone = ({
  multiple,
  hasFiles,
  onFileUpload,
}: FileDropzoneProps) => {
  const { t } = useTranslation("components");

  const multStr = multiple.toString() as "true" | "false";

  if (!multiple && hasFiles) return null;

  return (
    <Dropzone noClick onDropAccepted={onFileUpload} multiple={multiple}>
      {({ getRootProps, getInputProps, open }) => (
        <DropzoneContent
          {...getRootProps()}
          position="relative"
          direction="row"
          spacing={1}
          alignItems="center"
          className={cn({ withFiles: multiple && hasFiles })}>
          <DropzoneButton variant="outlined" onClick={open}>
            {t(chooseLabels[multStr])}
          </DropzoneButton>
          <DropzoneLabel color="text.secondary">
            {t(dropLabels[multStr])}
          </DropzoneLabel>
          <input {...getInputProps()} />
        </DropzoneContent>
      )}
    </Dropzone>
  );
};
export default FileDropzone;
