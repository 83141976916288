import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";

const FlagSvg = styled(SvgIcon)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 50,
  ".MuiSvgIcon-fontSizeLarge": {
    height: 40,
    width: 54,
  },
  ".MuiSvgIcon-fontSizeMedium": {
    height: 32,
    width: 42,
  },
  ".MuiSvgIcon-fontSizeInherit": {
    height: 32,
    width: 42,
  },
  ".MuiSvgIcon-fontSizeSmall": {
    height: 24,
    width: 32,
  },
}));

export default FlagSvg;
