import "dayjs/locale/ru";
import "dayjs/locale/kk";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ISample, TSampleStatus } from "../../../../types/samples";
import { IModel } from "../../../../types/models";
import { IResearchItem } from "../../../../types/researches";
import { IPortrait, TPortraitFilterOptions } from "../../../../types/portraits";
import { TDetailsBlock } from "../../../../components/DetailsView";
import ModelCreator from "../../../../components/ModelCreator";
import StatusChip from "../../../../components/StatusChip";
import Permission from "../../../../config/Permission";
import { getLongDate } from "../../../../utils/dates";
import { getDzo, getProductType } from "../../../../utils/sample";
import { TFunction } from "i18next";

dayjs.extend(customParseFormat);

export const prepareSampleData = (sample: ISample) => {
  const internalSampleData = [
    { title: "I18N_SAMPLE_SAMPLE_ID", value: sample?.code || "" },
    {
      title: "I18N_SAMPLE_TYPE_TITLE",
      value: "I18N_SAMPLE_KAP_TITLE",
    },
    {
      title: "I18N_SAMPLE_SAMPLE_STATUS",
      value: sample?.status || "",
      render: (value: TSampleStatus) => (
        <StatusChip
          type="sample"
          status={value}
          permissions={{
            lab_head: Permission.Sample.APPROVE,
            expert: Permission.Sample.RESEARCH,
            specialist: Permission.Sample.CREATE,
          }}
          withIcon
        />
      ),
    },
    {
      title: "I18N_SAMPLE_SAMPLE_CREATE",
      value: sample?.createdDate,
      transformValue: (value: string) => getLongDate(value),
    },
    {
      title: "I18N_SAMPLE_SAMPLE_UPDATE",
      value: sample?.updateDate,
      transformValue: (value: string) => getLongDate(value),
    },
    {
      title: "I18N_SAMPLE_MAKER_TITLE",
      value: sample?.name || "",
    },
    {
      title: "I18N_SAMPLE_ORGNAME",
      value: sample?.orgName || "",
    },
    {
      title: "I18N_SAMPLE_FINISHED_PRODUCT_TYPE",
      value: sample?.finishedProductType || "",
    },
    {
      title: "I18N_SAMPLE_SAMPLE_SELECTION_DATE",
      value: sample?.selectionDate,
      transformValue: (value: string) => getLongDate(value),
    },
    {
      title: "I18N_SAMPLE_SAMPLE_QUANTITY",
      value: sample?.quantity || 0,
    },
    {
      title: "I18N_SAMPLE_LABORATORY_NAME",
      value: sample?.laboratoryName || "",
    },
  ];
  const externalSampleData = [
    { title: "I18N_SAMPLE_SAMPLE_ID", value: sample?.code || "" },
    {
      title: "I18N_SAMPLE_TYPE_TITLE",
      value: "I18N_SAMPLE_EXTERNAL_SAMPLE_TITLE",
    },

    {
      title: "I18N_SAMPLE_SAMPLESTATUS",
      value: sample?.status || "",
      render: (value: TSampleStatus) => (
        <StatusChip
          type="sample"
          status={value}
          permissions={{
            lab_head: Permission.Sample.APPROVE,
            expert: Permission.Sample.RESEARCH,
            specialist: Permission.Sample.CREATE,
          }}
          withIcon
        />
      ),
    },
    {
      title: "I18N_SAMPLE_SAMPLE_CREATE",
      value: sample?.createdDate,
      transformValue: (value: string) => getLongDate(value),
    },
    {
      title: "I18N_SAMPLE_SAMPLE_UPDATE",
      value: sample?.updateDate,
      transformValue: (value: string) => getLongDate(value),
    },
    {
      title: "I18N_SAMPLE_MAKER_TITLE",
      value: sample?.name || "",
    },
    {
      title: "I18N_SAMPLES_EXPERTISE_CUSTOMER",
      value: sample?.externalSampleName || "",
    },
    {
      title: "I18N_SAMPLE_RESEARCH_TYPE",
      value:
        (sample?.type === "R"
          ? "I18N_SAMPLE_RESEARCH"
          : "I18N_SAMPLE_EXPERTISE") || "",
    },
    {
      title: "I18N_SAMPLE_SAMPLE_QUANTITY",
      value: sample?.quantity || 0,
    },
    {
      title: "I18N_SAMPLES_PHYSICAL_STATE",
      value: sample?.state || "",
    },
    {
      title: "I18N_SAMPLE_LABORATORY_NAME",
      value: sample?.laboratoryName || "",
    },
  ];

  const data: TDetailsBlock[] = [
    {
      title: "I18N_SAMPLES_DETAILS_TITLE",
      details: sample?.type === "K" ? internalSampleData : externalSampleData,
    },
  ];

  return data;
};
export const prepareModelData = (model: IModel) => {
  const data: TDetailsBlock[] = [
    {
      title: "I18N_MODELS_DESCR",

      details: [
        {
          title: "I18N_MODEL_NAME",
          value: model?.name || "",
        },
        {
          title: "I18N_MODEL_VERSION",
          value: model?.version || "",
        },
        {
          title: "I18N_MODEL_STATUS",
          value: model?.state || "",
          render: (value: string) => (
            <StatusChip type="model" status={value} withIcon />
          ),
        },
        {
          title: "I18N_TABLE_CREATE_DAY",
          value: model?.creation_date,
          transformValue: (value: string) => getLongDate(value),
        },
        {
          title: "I18N_MODELS_CREATOR",
          value: model?.creator_id || "",
          render: (value: string) => <ModelCreator creatorId={value} />,
        },
      ],
    },
  ];
  return data;
};
export const prepareResearchData = (research: IResearchItem) => {
  const data: TDetailsBlock[] = [
    {
      title: "I18N_RESEARCH_RESULT_TITLE",
      details: [
        {
          title: "I18N_RESEARCH_MODEL_STATE",
          value: research?.researchState || "",
          render: (value: string) => (
            <StatusChip
              type="research"
              status={value}
              withIcon
              permissions={{
                lab_head: Permission.Sample.APPROVE,
                expert: Permission.Sample.RESEARCH,
                specialist: Permission.Sample.CREATE,
              }}
            />
          ),
        },
        {
          title: "I18N_RESEARCH_STARTED",
          value: research?.startDate || "",
          transformValue: (value: string) => getLongDate(value),
        },
        {
          title: "I18N_RESEARCH_FINISHED",
          value: research?.finishDate || "",
          transformValue: (value: string) => getLongDate(value),
        },
        {
          title: "I18N_RESEARCH_RESPONSIBLE_SPEC",
          value: research?.specialistName || "I18N_RESEARCH_DATA_NOT_DEFINED",
        },
        {
          title: "I18N_RESEARCH_USED_TEST_METH",
          value: research?.meth_descr || "I18N_RESEARCH_DATA_NOT_DEFINED",
        },
        {
          title: "I18N_RESEARCH_USED_TEST_TOOL",
          value: research?.tool.descr || "I18N_RESEARCH_DATA_NOT_DEFINED",
        },
      ],
    },
  ];

  return data;
};

export const preparePortraitData = (
  portrait: IPortrait,
  sourceMap: { [key: string]: string },
  // modelsMap: { [key: string]: IModel },
  productsMap: TPortraitFilterOptions,

  t: TFunction
) => {
  const data: TDetailsBlock[] = [
    {
      title: "I18N_PORTRAIT_DESCRIPTION",
      details: [
        {
          title: "I18N_PORTRAIT_DZO",
          value: portrait?.sample_reference.source_code,
          transformValue: (value: string) => getDzo(sourceMap, value, t),
        },
        {
          title: "I18N_PORTRAIT_PRODUCT_TYPE",
          value: portrait?.sample_reference.product_code,
          transformValue: (value: string) =>
            getProductType(productsMap, value, t),
        },
        {
          title: "I18N_PORTRAIT_STATUS",
          value: portrait?.state || "",
          render: (value: string) => (
            <StatusChip
              type="portrait"
              status={value}
              withIcon
              permissions={{
                lab_head: Permission.Sample.APPROVE,
                expert: Permission.Sample.RESEARCH,
                specialist: Permission.Sample.CREATE,
              }}
            />
          ),
        },
        {
          title: "I18N_PORTRAIT_CREATION_DATE",
          value: portrait?.created_at || "",
          transformValue: (value: string) => getLongDate(value),
        },

        // {
        //   title: "",
        //   value: portrait?.model_id,
        //   transformValue: (value: string) => getModelName(modelsMap, value),
        // },

        {
          title: "I18N_PORTRAIT_CREATED_BY",
          value: portrait?.creator_id || "",
          render: (value: string) => <ModelCreator creatorId={value} />,
        },
      ],
    },
  ];
  return data;
};
