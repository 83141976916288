import React, { Suspense } from 'react';

const StateMaps = React.lazy(() => import('./StateMaps'));

const LazyStateMaps = () => (
  <Suspense fallback={<div />}>
    <StateMaps />
  </Suspense>
);

export default LazyStateMaps;
