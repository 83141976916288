import { ReactNode } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FieldProps } from '../../../types/fields';
import FormHelperText from '@mui/material/FormHelperText';
import { TSelectOption } from '../../../types/fields';
type TOptionTitle = {
  value: string;
};

type SelectFieldProps = FieldProps & {
  options: TSelectOption[];
  locales: string;
  error?: boolean;
  helperText?: ReactNode;
  disabledValue?: string;
  optionTitle?: (props: TOptionTitle) => JSX.Element;
};

const SelectField = ({
  options,
  label,
  field,
  error,
  helperText,
  disabledValue,
  optionTitle,
}: SelectFieldProps) => {
  // const optionForValue = options.filter((op) => op.value === field.value)[0];
  const optionsToRender = [...options];
  // if (!optionForValue) {
  //   optionsToRender.unshift({ value: field.value, title: field.value });
  // }

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={field.name}>{label + ' *'}</InputLabel>
      <Select
        labelId={field.name}
        id={field.name}
        defaultValue={field.value}
        value={field.value}
        label={label}
        onChange={field.onChange}>
        {optionsToRender.map(({ value, title }) => (
          <MenuItem value={value} key={value}>
            {disabledValue && value === disabledValue && (
              <Typography color="grey.500">{title}</Typography>
            )}
            {(!disabledValue || value !== disabledValue) && title}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default SelectField;
