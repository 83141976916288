import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { alpha, styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LargeTooltip from '../../LargeTooltip';
import ExpansionContext from '../../../contexts/ExpansionContext';

const ListItemContainer = styled(MuiListItem)(({ theme }) => ({
  '&:not(last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    borderRadius: theme.shape.borderRadius,

    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },

  '.MuiListItemText-root': {
    margin: 0,
    marginTop: theme.spacing(0.25),

    span: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '1.375rem',
    },
  },

  '&.collapsed': {
    padding: `${theme.spacing(1)} ${theme.spacing(1.25)}`,

    '.MuiListItemIcon-root': {
      minWidth: theme.spacing(3),
      margin: "auto",
    },
  },
}));

const StyledListIcon = styled(ListItemIcon)(({ theme }) => ({
  '&.MuiListItemIcon-root': {
    color: theme.palette.primary.main,
  },
}));

const StyledListText = styled(ListItemText)(({ theme }) => ({
  '&.MuiListItemText-root': {
    color: theme.palette.primary.main,
  },
}));

type TListItemProps = {
  name: string;
  path: string;
  icon: React.ReactNode;
  disabled?: boolean;
  selectable?: boolean;
  collapse?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const ListItem = ({
  icon,
  disabled,
  name,
  path,
  selectable = false,
  onClick,
}: TListItemProps) => {
  const { t } = useTranslation('nav');
  const location = useLocation();
  const navigate = useNavigate();
  const isExpanded = useContext(ExpansionContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) onClick(event);
    else navigate(path);
  };

  return (
    <LargeTooltip
      title={t(name)}
      disableFocusListener={isExpanded}
      disableHoverListener={isExpanded}
      disableTouchListener={isExpanded}
      placement="right"
      color="primary"
      arrow>
      <ListItemContainer disablePadding>
        <StyledListButton
          onClick={handleClick}
          selected={selectable ? location.pathname.startsWith(path) : false}
          disabled={disabled}
          className={cn({ collapsed: !isExpanded })}>
          <StyledListIcon>{icon}</StyledListIcon>
          {isExpanded && <StyledListText primary={t(name)} />}
        </StyledListButton>
      </ListItemContainer>
    </LargeTooltip>
  );
};

export default ListItem;
