import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';
import Page from '../../../../components/Page';
import Header from '../../../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import FormSection from '../../../../components/FormSection';
import FieldsGenerator from '../../../../components/Inputs/FieldsGenerator';
import {
  useAddDictTypeMutation,
  useUpdateDictTypeMutation,
  useGetDictTypeByIdQuery,
} from '../../../../services/dictType';
import { IDictType } from '../../../../types/dictionaries';

const formFields = [
  {
    name: 'name',
    label: 'I18N_DICT_TYPES_NAME',
    type: 'text',
    required: true,
  },
  {
    name: 'description',
    label: 'I18N_DICT_TYPES_NAME',
    type: 'text',
    required: true,
  },
];

const TypeCreate = () => {
  const { t } = useTranslation('types');
  const navigate = useNavigate();
  const { typeId } = useParams();
  const { data: type } = useGetDictTypeByIdQuery(typeId, { skip: !typeId });
  const [postType, { isSuccess: isPostSuccess }] = useAddDictTypeMutation();
  const [updateType, { isSuccess: isUpdateSuccess }] =
    useUpdateDictTypeMutation();

  const method = useForm<IDictType>({
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const { setValue } = method;
  useEffect(() => {
    if (type) {
      setValue('name', type.name);
      setValue('description', type.description);
    }
  }, [type]);

  useEffect(() => {
    if (isPostSuccess || isUpdateSuccess) navigate('/types');
  }, [isPostSuccess, isUpdateSuccess]);

  const onSubmit: SubmitHandler<IDictType> = async (data) => {
    if (type) {
      updateType({
        ...type,
        ...data,
      });
    } else {
      postType(data);
    }
  };
  const headerLabel = typeId
    ? t('I18N_DICT_TYPES_EDIT_TYPE')
    : t('I18N_DICT_TYPES_ADD_TYPE');

  return (
    <Page>
      <Header label={headerLabel} permissions={'control_dicts'} />
      <FormProvider {...method}>
        <FormSection
          isLoading={false}
          onSubmit={onSubmit}
          formId="create-user-form"
          btnText={typeId ? 'I18N_EDIT' : 'I18N_ADD_CREATE'}
          isEditPage={Boolean(typeId)}>
          <FieldsGenerator fullWidth fieldList={formFields} locales="types" />
        </FormSection>
      </FormProvider>
    </Page>
  );
};
export default TypeCreate;
